import chroma from 'chroma-js'
import { de, enUS, es, fr, pl, zhCN } from 'date-fns/locale'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

const LOCALES: {
  [key: string]: Locale
} = {
  de,
  en: enUS,
  pl,
  fr,
  es,
  zh: zhCN,
}

// https://usehooks.com/usePrevious/
export const usePrevious = <T = undefined>(value: T) => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

export const useLocale = () => {
  const [, i18n] = useTranslation()
  return LOCALES[i18n.language] ?? enUS
}

export const colorRamp = (color: (t: number) => string, n: number = 512) => {
  const colors: string[] = []
  for (let i = 0; i < n; ++i) {
    colors.push(color(i / (n - 1)))
  }
  return colors
}

export const generateColor = () => {
  return chroma.random().hex()
}

export const connectorTypeDisplayName = (connectorType: string): string => {
  switch (connectorType) {
    case 'com.seiotec.datalogger.connector.databus.DatabusConnector':
      return 'Databus Connector'
    case 'com.seiotec.datalogger.connector.plc4x.Plc4JConnector':
      return 'Generic Connector'
    case 'com.seiotec.datalogger.connector.simulator.SimulatorConnector':
      return 'Simulator Connector'
    case 'com.seiotec.datalogger.connector.dataservice.DataserviceConnector':
      return 'Dataservice Connector'
    default:
      return connectorType
  }
}

export const isBoolean = (dataType: string | undefined) => {
  switch (dataType) {
    case 'Bool':
      return true
    case 'boolean':
      return true
    default:
      return false
  }
}
