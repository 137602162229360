import { useStyletron } from 'baseui'
import { Block } from 'baseui/block'
import { Button } from 'baseui/button'
import { DatePicker } from 'baseui/datepicker'
import { FormControl } from 'baseui/form-control'
import { ArrowRight, ChevronLeft, ChevronRight } from 'baseui/icon'
import { TimePicker } from 'baseui/timepicker'
import { isBefore, set, subDays } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StepWizardChildProps } from 'react-step-wizard'

import { useLocale } from '../../utils'

const Step2: React.FC<
  Partial<StepWizardChildProps> & {
    machineId: number | undefined
    onDateChange: (selection: [Date, Date] | undefined) => void
  }
> = ({ previousStep, nextStep, goToStep, onDateChange, machineId }) => {
  const [css, theme] = useStyletron()
  const [dates, setDates] = useState<[Date, Date]>([
    set(subDays(new Date(), 1), {
      seconds: 0,
      milliseconds: 0,
    }),
    set(new Date(), { seconds: 0, milliseconds: 0 }),
  ])
  const [t, i18n] = useTranslation()
  const locale = useLocale()

  useEffect(() => {
    onDateChange(dates)
  }, [dates, onDateChange])

  return (
    <Block flex={'auto'} display={'flex'} flexDirection={'column'}>
      <Block
        flex={'auto'}
        display={'flex'}
        overrides={{
          Block: {
            style: ({ $theme }) => ({
              borderBottomStyle: $theme.borders.border400.borderStyle,
              borderBottomWidth: $theme.borders.border400.borderWidth,
              borderBottomColor: $theme.colors.borderOpaque,
            }),
          },
        }}
      >
        <div
          className={css({
            display: 'flex',
            flex: 'auto',
            alignItems: 'center',
            justifyContent: 'center',
          })}
        >
          <div
            className={css({
              display: 'flex',
              alignItems: 'center',
              paddingBottom: theme.sizing.scale600,
            })}
          >
            <div
              className={css({
                width: '120px',
                marginRight: theme.sizing.scale600,
              })}
            >
              <FormControl label={t('export.start_date')}>
                <DatePicker
                  value={dates[0]}
                  onChange={({ date }) => {
                    const newDates: [Date, Date] = [
                      set(date as Date, { seconds: 0, milliseconds: 0 }),
                      dates[1],
                    ]
                    setDates(newDates)
                  }}
                  autoFocusCalendar={true}
                  formatString={t('datepicker.format')}
                  mask={t('datepicker.mask')}
                  placeholder={t('datepicker.placeholder')}
                  locale={locale}
                />
              </FormControl>
            </div>
            <div
              className={css({
                width: '120px',
                marginRight: theme.sizing.scale300,
              })}
            >
              <FormControl label={t('export.start_time')}>
                <TimePicker
                  value={dates[0]}
                  onChange={(time) => {
                    const newDates: [Date, Date] = [
                      set(time as Date, { seconds: 0, milliseconds: 0 }),
                      dates[1],
                    ]
                    setDates(newDates)
                    onDateChange(newDates)
                  }}
                  format={i18n.language === 'de' ? '24' : '12'}
                  creatable
                />
              </FormControl>
            </div>
            <div
              className={css({
                color: theme.colors.primary,
                marginRight: theme.sizing.scale300,
                paddingTop: theme.sizing.scale800,
              })}
            >
              <ArrowRight size={24} />
            </div>
            <div
              className={css({
                width: '120px',
                marginRight: theme.sizing.scale600,
              })}
            >
              <FormControl label={t('export.end_date')}>
                <DatePicker
                  value={dates[1]}
                  onChange={({ date }) => {
                    const newDates: [Date, Date] = [
                      dates[0],
                      set(date as Date, { seconds: 0, milliseconds: 0 }),
                    ]
                    setDates(newDates)
                    onDateChange(newDates)
                  }}
                  autoFocusCalendar={true}
                  formatString={t('datepicker.format')}
                  mask={t('datepicker.mask')}
                  placeholder={t('datepicker.placeholder')}
                  locale={locale}
                />
              </FormControl>
            </div>
            <div
              className={css({
                width: '120px',
              })}
            >
              <FormControl label={t('export.end_time')}>
                <TimePicker
                  value={dates[1]}
                  onChange={(time) => {
                    const newDates: [Date, Date] = [
                      dates[0],
                      set(time as Date, { seconds: 0, milliseconds: 0 }),
                    ]
                    setDates(newDates)
                    onDateChange(newDates)
                  }}
                  format={i18n.language === 'de' ? '24' : '12'}
                  creatable
                />
              </FormControl>
            </div>
          </div>
        </div>
      </Block>
      <Block
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        padding={'scale600'}
        backgroundColor={'backgroundSecondary'}
      >
        <Button
          onClick={previousStep}
          startEnhancer={() => <ChevronLeft size={24} />}
          overrides={{
            BaseButton: {
              style: {
                paddingLeft: '8px',
              },
            },
            StartEnhancer: {
              style: {
                marginRight: 0,
              },
            },
          }}
        >
          {t('export.prev')}
        </Button>
        <Button
          onClick={() => {
            goToStep && goToStep(3)
          }}
          endEnhancer={() => <ChevronRight size={24} />}
          disabled={isBefore(dates[1], dates[0])}
          overrides={{
            BaseButton: {
              style: {
                paddingRight: '8px',
              },
            },
            EndEnhancer: {
              style: {
                marginLeft: 0,
              },
            },
          }}
        >
          {t('export.next')}
        </Button>
      </Block>
    </Block>
  )
}

export { Step2 }
