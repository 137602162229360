import {
  faCheck,
  faDownload,
  faPen,
  faPlus,
  faTimes,
  faTrash,
  faUpload,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStyletron } from 'baseui'
import { Block } from 'baseui/block'
import { Breadcrumbs } from 'baseui/breadcrumbs'
import { Button, KIND } from 'baseui/button'
import { ButtonGroup } from 'baseui/button-group'
import { StatefulCheckbox } from 'baseui/checkbox'
import {
  Input,
  InputProps,
  StatefulInput,
  StatefulInputProps,
} from 'baseui/input'
import { ProgressBar } from 'baseui/progress-bar'
import { StatefulSelect } from 'baseui/select'
import { toaster } from 'baseui/toast'
import { Parameter } from 'client/dist/models'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import NumberFormat, { NumberFormatProps } from 'react-number-format'
import { useAuth } from 'react-oidc-context'
import { useParams } from 'react-router-dom'
import { AutoSizer, Column, SortDirectionType, Table } from 'react-virtualized'

import { useApi } from '../../ApiProvider'

const CustomInput: React.FC<StatefulInputProps> = (props) => {
  return (
    <Input size={'compact'} {...props}>
      {props.children}
    </Input>
  )
}

export const StatefulNumberFormat: React.FC<
  NumberFormatProps &
    InputProps & {
      initialValue: string | undefined
    }
> = (props) => {
  const [value, setValue] = useState<string | undefined>(props.initialValue)
  return (
    <NumberFormat
      {...props}
      value={value}
      isNumericString={true}
      onValueChange={(values) => {
        setValue(values.value)
        if (props.onValueChange) {
          props.onValueChange(values)
        }
      }}
      customInput={CustomInput}
    />
  )
}

const Machine: React.FC = () => {
  const { id } = useParams()
  const [css, theme] = useStyletron()
  const [parameters, setParameters] = useState<Parameter[]>([])
  const [loading, setLoading] = useState(false)
  const [editing, setEditing] = useState(false)
  const [valid, setValid] = useState(true)
  const [availableTags, setAvailableTags] = useState<string[]>([])
  const [sort, setSort] = useState<{
    column: string
    direction: SortDirectionType
  }>({
    column: 'index',
    direction: 'ASC',
  })
  const record = useRef<Parameter | undefined>()
  const fileInput = useRef<HTMLInputElement>(null)
  const [t, i18n] = useTranslation()
  const [api] = useApi()
  const auth = useAuth()

  const bodyCellClass = useMemo(
    () =>
      css({
        ...theme.typography.ParagraphSmall,
        ...theme.borders.border300,
        borderTop: 'none',
        borderBottom: 'none',
        [theme.direction === 'rtl' ? 'borderRight' : 'borderLeft']: 'none',
        borderColor: 'transparent',
        color: theme.colors.contentPrimary,
        paddingTop: theme.sizing.scale300,
        paddingRight: theme.sizing.scale600,
        paddingBottom: theme.sizing.scale300,
        paddingLeft: theme.sizing.scale600,
        ':last-of-type': {
          [theme.direction === 'rtl' ? 'borderLeft' : 'borderRight']: 'none',
        },
      }),
    [css, theme]
  )

  const addParameter = useCallback(
    (parameter: Parameter) => {
      api.parametersApi.addParameter(parameter).then((result) => {
        setParameters(
          [result.data, ...parameters].sort((a, b) => {
            const valueA = (a as any)[sort.column]
            const valueB = (b as any)[sort.column]
            if (valueA < valueB) {
              return sort.direction === 'ASC' ? -1 : 1
            }
            if (valueA > valueB) {
              return sort.direction === 'ASC' ? 1 : -1
            }
            return 0
          })
        )
        record.current = undefined
        setEditing(false)
      })
    },
    [api.parametersApi, parameters, sort.column, sort.direction]
  )

  const updateParameter = useCallback(
    (parameter: Parameter) => {
      if (parameter.id !== undefined) {
        api.parametersApi
          .updateParameter(parameter.id, parameter)
          .then((result) => {
            setParameters(
              parameters.map(({ id }, index) => {
                if (id === parameter.id) {
                  return result.data
                } else {
                  return parameters[index]
                }
              })
            )
            record.current = undefined
            setEditing(false)
          })
      }
    },
    [api.parametersApi, parameters]
  )

  const deleteParameter = useCallback(
    (parameter: Parameter) => {
      if (parameter.id !== undefined) {
        api.parametersApi.deleteParameter(parameter.id).then(() => {
          setParameters(parameters.filter(({ id }) => id !== parameter.id))
        })
      }
    },
    [api.parametersApi, parameters]
  )

  const startEdit = useCallback((parameter: Parameter) => {
    record.current = parameter
    setEditing(true)
    if (parameter.id === undefined) {
      setValid(false)
    }
  }, [])

  const cancelEdit = useCallback(() => {
    record.current = undefined
    setEditing(false)
    if (!valid) {
      setValid(true)
    }
  }, [valid])

  const commitEdit = useCallback(() => {
    if (editing && record.current !== undefined) {
      if (record.current.id !== undefined && record.current.id !== null) {
        updateParameter(record.current)
      } else {
        addParameter(record.current)
      }
    }
  }, [addParameter, editing, updateParameter])

  const isNew = useCallback(() => {
    return record.current !== undefined && record.current.id === undefined
  }, [])

  const isEditing = useCallback(
    (rowIndex: number) => {
      return (
        editing &&
        ((rowIndex === 0 && isNew()) ||
          (record.current !== undefined &&
            record.current.id ===
              parameters[isNew() ? rowIndex - 1 : rowIndex].id))
      )
    },
    [editing, isNew, parameters]
  )

  const validate = useCallback(() => {
    setValid(
      !!record.current?.name &&
        !!record.current?.address &&
        record.current?.lowerLimit !== undefined &&
        record.current?.upperLimit !== undefined
    )
  }, [])

  useEffect(() => {
    if (id !== undefined) {
      setLoading(true)
      api.parametersApi
        .listParameters(parseInt(id))
        .then((result) => {
          setParameters(result.data)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [api.parametersApi, id])

  useEffect(() => {
    api.machinesApi.browseMachineTags(parseInt(id)).then((response) => {
      setAvailableTags(response.data.slice().sort())
    })
  }, [api.machinesApi, id])

  return (
    <Block flex={'auto'} display={'flex'} flexDirection={'column'}>
      <input
        type={'file'}
        accept={'.xls,.xlsx'}
        style={{ display: 'none' }}
        ref={fileInput}
        onChange={(e) => {
          if (
            e.currentTarget.files !== null &&
            e.currentTarget.files.length === 1
          ) {
            setLoading(true)
            api.parametersApi
              .importParameters(id, e.currentTarget.files.item(0))
              .then((result) => {
                setParameters(result.data)
                toaster.positive(<>{t('settings.import_success')}</>, {})
              })
              .catch((reason) => {
                toaster.negative(<>{reason.response.data.message}</>, {})
              })
              .finally(() => {
                setLoading(false)
                if (fileInput.current) {
                  fileInput.current.value = ''
                }
              })
          }
        }}
      />
      <Block
        paddingTop={'scale600'}
        paddingRight={'scale600'}
        paddingBottom={'scale600'}
        paddingLeft={'scale600'}
      >
        <Breadcrumbs
          overrides={{
            Root: {
              style: ({ $theme }) => ({
                paddingBottom: $theme.sizing.scale600,
              }),
            },
          }}
        >
          <span>{t('settings.settings')}</span>
          <span>{t('settings.machines')}</span>
          <span>
            {t('settings.machine')} {id}
          </span>
        </Breadcrumbs>

        <Button
          size={'compact'}
          startEnhancer={() => <FontAwesomeIcon icon={faPlus} />}
          onClick={() => {
            if (id !== undefined && !editing) {
              startEdit({
                machineId: parseInt(id),
                name: '',
                lowerLimit: 0,
                upperLimit: 0,
                unit: '',
                active: true,
                exportEnabled: true,
                scale: 0,
                address: '',
              })
            }
          }}
          overrides={{
            StartEnhancer: {
              style: ({ $theme }) => ({
                marginRight: $theme.sizing.scale300,
              }),
            },
          }}
        >
          {t('add')}
        </Button>
        <Button
          size={'compact'}
          startEnhancer={() => <FontAwesomeIcon icon={faUpload} />}
          onClick={() => {
            fileInput.current?.click()
          }}
          overrides={{
            StartEnhancer: {
              style: ({ $theme }) => ({
                marginRight: $theme.sizing.scale300,
              }),
            },
            Root: {
              style: ({ $theme }) => ({
                marginLeft: $theme.sizing.scale600,
              }),
            },
          }}
        >
          {t('settings.import')}
        </Button>
        <Button
          size={'compact'}
          startEnhancer={() => <FontAwesomeIcon icon={faDownload} />}
          onClick={() => {
            const link = document.createElement('a')
            link.setAttribute('download', '')
            if (auth.user !== undefined) {
              link.href = `${window.env.APP_BASE_PATH}/api/v1/parameters/export?machineId=${id}&access_token=${auth.user?.access_token}`
            } else {
              link.href = `${window.env.APP_BASE_PATH}/api/v1/parameters/export?machineId=${id}`
            }
            link.click()
            console.log(link.href)
            if (link.parentNode !== null) {
              link.parentNode.removeChild(link)
            }
          }}
          overrides={{
            StartEnhancer: {
              style: ({ $theme }) => ({
                marginRight: $theme.sizing.scale300,
              }),
            },
            Root: {
              style: ({ $theme }) => ({
                marginLeft: $theme.sizing.scale600,
              }),
            },
          }}
        >
          {t('settings.download')}
        </Button>
      </Block>
      <Block flex={'auto'}>
        <AutoSizer>
          {({ width, height }) => (
            <>
              <Table
                height={height}
                width={width}
                headerHeight={44}
                rowHeight={36}
                rowCount={isNew() ? parameters.length + 1 : parameters.length}
                rowGetter={({ index }) => {
                  if (isNew()) {
                    if (index === 0) {
                      return record.current
                    }
                    return parameters[index - 1]
                  } else {
                    return parameters[index]
                  }
                }}
                rowClassName={({ index }) => {
                  if (index === -1) {
                    return css({
                      backgroundColor: theme.colors.tableHeadBackgroundColor,
                      boxShadow: theme.lighting.shadow400,
                      display: 'flex',
                      alignItems: 'center',
                    })
                  } else {
                    return css({
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: isEditing(index)
                        ? theme.colors.backgroundTertiary
                        : 'inherit',
                    })
                  }
                }}
                headerRowRenderer={({ className, columns, style }) => {
                  return (
                    <>
                      <div className={className} role='row' style={style}>
                        {columns}
                      </div>
                      {loading && (
                        <ProgressBar
                          infinite
                          overrides={{
                            Bar: {
                              style: {
                                position: 'absolute',
                                width: `${style.width}px`,
                                marginBottom: 0,
                                marginLeft: 0,
                                marginRight: 0,
                                marginTop: 0,
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                              },
                            },
                            BarProgress: {
                              style: {
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                              },
                            },
                          }}
                        />
                      )}
                    </>
                  )
                }}
                headerClassName={css({
                  ...theme.typography.LabelMedium,
                  ...theme.borders.border300,
                  borderTop: 'none',
                  borderBottom: 'none',
                  [theme.direction === 'rtl' ? 'borderRight' : 'borderLeft']:
                    'none',
                  color: theme.colors.contentPrimary,
                  paddingTop: theme.sizing.scale500,
                  paddingRight: theme.sizing.scale600,
                  paddingBottom: theme.sizing.scale500,
                  paddingLeft: theme.sizing.scale600,
                  ':focus': {
                    outline: 'none',
                  },
                  ':nth-last-of-type(2)': {
                    [theme.direction === 'rtl' ? 'borderLeft' : 'borderRight']:
                      'none',
                  },
                  ':last-of-type': {
                    [theme.direction === 'rtl' ? 'borderLeft' : 'borderRight']:
                      'none',
                  },
                })}
                gridClassName={css({
                  ':focus': {
                    outline: 'none',
                  },
                })}
                className={css({
                  backgroundColor: theme.colors.tableBackground,
                  display: 'flex',
                  flexDirection: 'column',
                })}
                sortBy={sort.column}
                sortDirection={sort.direction}
                sort={({ sortBy, sortDirection }) => {
                  setSort({
                    column: sortBy,
                    direction: sortDirection,
                  })
                  setParameters(
                    parameters.sort((a, b) => {
                      const valueA = (a as any)[sortBy]
                      const valueB = (b as any)[sortBy]
                      if (valueA < valueB) {
                        return sortDirection === 'ASC' ? -1 : 1
                      }
                      if (valueA > valueB) {
                        return sortDirection === 'ASC' ? 1 : -1
                      }
                      return 0
                    })
                  )
                }}
                noRowsRenderer={() => {
                  return (
                    <div
                      className={css({
                        ...theme.typography.ParagraphMedium,
                        color: theme.colors.contentPrimary,
                        textAlign: 'center',
                        paddingTop: theme.sizing.scale800,
                      })}
                    >
                      {t('settings.no_parameters')}
                    </div>
                  )
                }}
              >
                <Column
                  label={t('settings.name')}
                  dataKey={'name'}
                  width={100}
                  flexGrow={1}
                  className={bodyCellClass}
                  cellRenderer={({ cellData, rowIndex }) => {
                    if (isEditing(rowIndex) && record.current !== undefined) {
                      return (
                        <StatefulInput
                          placeholder={t('settings.name')}
                          initialState={{ value: cellData }}
                          onChange={(e) => {
                            if (record.current !== undefined) {
                              record.current.name = e.currentTarget.value
                            }
                            validate()
                          }}
                          size={'compact'}
                          autoFocus={true}
                          overrides={{
                            InputContainer: {
                              style: {
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: '2px',
                                borderLeftWidth: 0,
                                backgroundColor: 'transparent',
                              },
                            },
                            Input: {
                              style: {
                                paddingRight: 0,
                                paddingLeft: 0,
                              },
                            },
                          }}
                        />
                      )
                    } else {
                      return cellData
                    }
                  }}
                />
                <Column
                  label={t('settings.address')}
                  dataKey={'address'}
                  width={100}
                  flexGrow={1}
                  className={bodyCellClass}
                  cellRenderer={({ cellData, rowIndex }) => {
                    if (isEditing(rowIndex) && record.current !== undefined) {
                      return availableTags.length > 0 ? (
                        <StatefulSelect
                          placeholder={t('settings.address')}
                          options={availableTags
                            .filter((tag) => {
                              return (
                                !parameters.find(
                                  ({ address }) => address === tag
                                ) || cellData === tag
                              )
                            })
                            .map((tag) => ({
                              id: tag,
                              label: tag,
                            }))}
                          initialState={
                            cellData ? { value: [{ id: cellData }] } : undefined
                          }
                          onChange={(e) => {
                            if (record.current !== undefined) {
                              record.current.address = e.value[0]?.id as string
                              validate()
                            }
                          }}
                          size={'compact'}
                          clearable={false}
                          searchable
                          overrides={{
                            ControlContainer: {
                              style: {
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: '2px',
                                borderLeftWidth: 0,
                                backgroundColor: 'transparent',
                              },
                            },
                            ValueContainer: {
                              style: {
                                paddingLeft: 0,
                              },
                            },
                            IconsContainer: {
                              style: {
                                paddingRight: 0,
                              },
                            },
                            SingleValue: {
                              style: {
                                marginLeft: 0,
                              },
                            },
                          }}
                        />
                      ) : (
                        <StatefulInput
                          placeholder={t('settings.address')}
                          initialState={{ value: cellData }}
                          onChange={(e) => {
                            if (record.current !== undefined) {
                              record.current.address = e.currentTarget.value
                              validate()
                            }
                          }}
                          size={'compact'}
                          overrides={{
                            InputContainer: {
                              style: {
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: '2px',
                                borderLeftWidth: 0,
                                backgroundColor: 'transparent',
                              },
                            },
                            Input: {
                              style: {
                                paddingRight: 0,
                                paddingLeft: 0,
                              },
                            },
                          }}
                        />
                      )
                    } else {
                      return cellData
                    }
                  }}
                />
                <Column
                  label={t('settings.lower_limit')}
                  dataKey={'lowerLimit'}
                  width={110}
                  className={bodyCellClass}
                  cellRenderer={({ cellData, rowIndex, rowData }) => {
                    if (isEditing(rowIndex) && record.current !== undefined) {
                      return (
                        <StatefulNumberFormat
                          decimalSeparator={i18n.language === 'de' ? ',' : '.'}
                          decimalScale={6}
                          allowedDecimalSeparators={[',', '.']}
                          placeholder={t('settings.lower_limit')}
                          initialValue={cellData.toFixed(rowData['scale'])}
                          onValueChange={({ floatValue, value }) => {
                            if (
                              record.current !== undefined &&
                              floatValue !== undefined
                            ) {
                              const [, scale] = value.split('.')
                              record.current.lowerLimit = floatValue
                              record.current.scale =
                                scale !== undefined ? scale.length : 0
                              validate()
                            }
                          }}
                          overrides={{
                            InputContainer: {
                              style: {
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: '2px',
                                borderLeftWidth: 0,
                                backgroundColor: 'transparent',
                              },
                            },
                            Input: {
                              style: {
                                paddingRight: 0,
                                paddingLeft: 0,
                              },
                            },
                          }}
                        />
                      )
                    } else {
                      return cellData.toLocaleString(i18n.language, {
                        minimumFractionDigits: rowData['scale'],
                        maximumFractionDigits: rowData['scale'],
                        useGouping: false,
                      })
                    }
                  }}
                />
                <Column
                  label={t('settings.upper_limit')}
                  dataKey={'upperLimit'}
                  width={105}
                  className={bodyCellClass}
                  cellRenderer={({ cellData, rowIndex, rowData }) => {
                    if (isEditing(rowIndex) && record.current !== undefined) {
                      return (
                        <StatefulNumberFormat
                          decimalSeparator={i18n.language === 'de' ? ',' : '.'}
                          decimalScale={6}
                          allowedDecimalSeparators={[',', '.']}
                          placeholder={t('settings.upper_limit')}
                          initialValue={cellData.toFixed(rowData['scale'])}
                          onValueChange={({ floatValue, value }) => {
                            if (
                              record.current !== undefined &&
                              floatValue !== undefined
                            ) {
                              const [, scale] = value.split('.')
                              record.current.upperLimit = floatValue
                              record.current.scale =
                                scale !== undefined ? scale.length : 0
                              validate()
                            }
                          }}
                          overrides={{
                            InputContainer: {
                              style: {
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: '2px',
                                borderLeftWidth: 0,
                                backgroundColor: 'transparent',
                              },
                            },
                            Input: {
                              style: {
                                paddingRight: 0,
                                paddingLeft: 0,
                              },
                            },
                          }}
                        />
                      )
                    } else {
                      return cellData.toLocaleString(i18n.language, {
                        minimumFractionDigits: rowData['scale'],
                        maximumFractionDigits: rowData['scale'],
                        useGrouping: false,
                      })
                    }
                  }}
                />
                <Column
                  label={t('settings.unit')}
                  dataKey={'unit'}
                  width={70}
                  className={bodyCellClass}
                  cellRenderer={({ cellData, rowIndex }) => {
                    if (isEditing(rowIndex) && record.current !== undefined) {
                      return (
                        <StatefulInput
                          placeholder={t('settings.unit')}
                          initialState={{ value: cellData }}
                          onChange={(e) => {
                            if (record.current !== undefined) {
                              record.current.unit = e.currentTarget.value
                            }
                          }}
                          size={'compact'}
                          overrides={{
                            InputContainer: {
                              style: {
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: '2px',
                                borderLeftWidth: 0,
                                backgroundColor: 'transparent',
                              },
                            },
                            Input: {
                              style: {
                                paddingRight: 0,
                                paddingLeft: 0,
                              },
                            },
                          }}
                        />
                      )
                    } else {
                      return cellData
                    }
                  }}
                />
                <Column
                  label={t('settings.active')}
                  dataKey={'active'}
                  width={70}
                  className={bodyCellClass}
                  cellRenderer={({ cellData, rowIndex }) => {
                    if (isEditing(rowIndex) && record.current !== undefined) {
                      return (
                        <StatefulCheckbox
                          initialState={{
                            checked: cellData,
                          }}
                          onChange={(e) => {
                            if (record.current !== undefined) {
                              record.current.active = e.currentTarget.checked
                            }
                          }}
                        />
                      )
                    } else {
                      return cellData ? t('yes') : t('no')
                    }
                  }}
                />
                <Column
                  disableSort
                  dataKey={'action'}
                  width={60}
                  className={bodyCellClass}
                  style={{
                    textAlign: 'center',
                  }}
                  cellRenderer={({ rowIndex }) => {
                    if (!editing || isEditing(rowIndex)) {
                      return (
                        <ButtonGroup
                          size={'mini'}
                          kind={KIND.tertiary}
                          overrides={{
                            Root: {
                              style: {
                                justifyContent: 'end',
                              },
                            },
                          }}
                        >
                          <Button
                            disabled={!valid}
                            overrides={{
                              BaseButton: {
                                style: ({ $theme }) => ({
                                  ':hover': {
                                    backgroundColor: 'transparent',
                                    color: $theme.colors.linkHover,
                                  },
                                  ':focus': {
                                    backgroundColor: 'transparent',
                                  },
                                  ':active': {
                                    backgroundColor: 'transparent',
                                    color: $theme.colors.linkActive,
                                  },
                                  ':disabled': {
                                    backgroundColor: 'transparent',
                                  },
                                }),
                              },
                            }}
                            onClick={() => {
                              if (isEditing(rowIndex)) {
                                commitEdit()
                              } else {
                                startEdit({ ...parameters[rowIndex] })
                              }
                            }}
                          >
                            <FontAwesomeIcon
                              size={'lg'}
                              icon={isEditing(rowIndex) ? faCheck : faPen}
                            />
                          </Button>
                          <Button
                            overrides={{
                              BaseButton: {
                                style: ({ $theme }) => ({
                                  ':hover': {
                                    backgroundColor: 'transparent',
                                    color: $theme.colors.linkHover,
                                  },
                                  ':focus': {
                                    backgroundColor: 'transparent',
                                  },
                                  ':active': {
                                    backgroundColor: 'transparent',
                                    color: $theme.colors.linkActive,
                                  },
                                  ':disabled': {
                                    backgroundColor: 'transparent',
                                  },
                                }),
                              },
                            }}
                            onClick={() => {
                              if (isEditing(rowIndex)) {
                                cancelEdit()
                              } else {
                                deleteParameter(parameters[rowIndex])
                              }
                            }}
                          >
                            <FontAwesomeIcon
                              size={'lg'}
                              icon={isEditing(rowIndex) ? faTimes : faTrash}
                            />
                          </Button>
                        </ButtonGroup>
                      )
                    } else {
                      return null
                    }
                  }}
                />
              </Table>
            </>
          )}
        </AutoSizer>
      </Block>
    </Block>
  )
}

export { Machine }
