import { Block } from 'baseui/block'
import { Navigation } from 'baseui/side-navigation'
import { Machine as MachineModel } from 'client/dist/models'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom'

import { useApi } from '../ApiProvider'
import { themedUseStyletron as useStyletron } from '../themes'
import { connectorTypeDisplayName } from '../utils'
import { Axes } from './settings/Axes'
import { Connectors } from './settings/Connectors'
import { Info } from './settings/Info'
import { Machine } from './settings/Machine'
import { Machines } from './settings/Machines'
import { Templates } from './settings/Templates'

const Settings: React.FC = () => {
  const [machines, setMachines] = useState<MachineModel[]>([])
  const [css, theme] = useStyletron()
  const history = useHistory()
  const location = useLocation()
  const [t] = useTranslation()
  const [api] = useApi()

  const NAV_ITEMS = [
    {
      title: t('settings.adapters'),
      itemId: '/settings/connectors',
    },
    {
      title: t('settings.machines'),
      itemId: '/settings/machines',
      subNav: machines.map((machine) => {
        return {
          title: (
            <>
              <div>{machine.name}</div>
              <span
                className={css({
                  ...theme.typography.LabelSmall,
                })}
              >
                {connectorTypeDisplayName(machine.connector)}
              </span>
            </>
          ),
          itemId: `/settings/machines/${machine.id}`,
        }
      }),
    },
    {
      title: t('settings.axes'),
      itemId: '/settings/axes',
    },
    {
      title: t('settings.templates'),
      itemId: '/settings/templates',
    },
    {
      title: t('settings.info'),
      itemId: '/settings/info',
    },
  ]

  useEffect(() => {
    api.machinesApi.listMachines().then((result) => {
      setMachines(result.data.slice().sort((a, b) => a.id! - b.id!))
    })
  }, [api.machinesApi])

  return (
    <Block display={'flex'} flex={'auto'}>
      <Block
        flex={'0 0 250px'}
        backgroundColor={'backgroundSecondary'}
        overflow={'auto'}
      >
        <Navigation
          items={NAV_ITEMS}
          activeItemId={location.pathname}
          onChange={({ event, item }) => {
            event.preventDefault()
            history.push(item.itemId)
          }}
          overrides={{
            NavItem: {
              style: ({ $active }) => ({
                fontWeight: 500,
                borderLeftColor: $active ? theme.navItemColor : 'transparent',
              }),
            },
          }}
        />
      </Block>
      <Block flex={'1 1 auto'} display={'flex'}>
        <Switch>
          <Route exact path='/settings'>
            <Redirect to='/settings/connectors' />
          </Route>
          <Route exact path='/settings/connectors'>
            <Connectors />
          </Route>
          <Route exact path='/settings/machines'>
            <Machines
              onMachineAdded={(machine) => {
                setMachines(
                  [...machines, machine].sort((a, b) => a.id! - b.id!)
                )
              }}
              onMachineRemoved={(machine) => {
                setMachines(machines.filter(({ id }) => id !== machine.id))
              }}
            />
          </Route>
          <Route exact path='/settings/machines/:id'>
            <Machine />
          </Route>
          <Route exact path='/settings/templates'>
            <Templates />
          </Route>
          <Route exact path='/settings/axes'>
            <Axes />
          </Route>
          <Route exact path='/settings/info'>
            <Info />
          </Route>
        </Switch>
      </Block>
    </Block>
  )
}

export { Settings }
