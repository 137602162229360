import { faGripLines } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import arrayMove from 'array-move'
import { useStyletron } from 'baseui'
import { Block } from 'baseui/block'
import { Button } from 'baseui/button'
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox'
import { ChevronLeft, ChevronRight } from 'baseui/icon'
import { ProgressBar } from 'baseui/progress-bar'
import { Parameter } from 'client/dist/models'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc'
import { StepWizardChildProps } from 'react-step-wizard'
import { AutoSizer, Column, Table, TableRowProps } from 'react-virtualized'

import { useApi } from '../../ApiProvider'

const DragHandle = SortableHandle(() => (
  <span
    style={{
      fontSize: '24px',
      cursor: 'row-resize',
    }}
  >
    <FontAwesomeIcon title={'Drag'} icon={faGripLines} size={'1x'} />
  </span>
))

const TableRowRenderer: React.SFC<TableRowProps> = ({
  className,
  columns,
  index,
  key,
  onRowClick,
  onRowDoubleClick,
  onRowMouseOut,
  onRowMouseOver,
  onRowRightClick,
  rowData,
  style,
}) => {
  const a11yProps: any = { 'aria-rowindex': index + 1 }
  if (
    onRowClick ||
    onRowDoubleClick ||
    onRowMouseOut ||
    onRowMouseOver ||
    onRowRightClick
  ) {
    a11yProps['aria-label'] = 'row'
    a11yProps.tabIndex = 0

    if (onRowClick) {
      a11yProps.onClick = (event: React.MouseEvent<any>) =>
        onRowClick({ event, index, rowData })
    }

    if (onRowDoubleClick) {
      a11yProps.onDoubleClick = (event: React.MouseEvent<any>) =>
        onRowDoubleClick({ event, index, rowData })
    }

    if (onRowMouseOut) {
      a11yProps.onMouseOut = (event: React.MouseEvent<any>) =>
        onRowMouseOut({ event, index, rowData })
    }

    if (onRowMouseOver) {
      a11yProps.onMouseOver = (event: React.MouseEvent<any>) =>
        onRowMouseOver({ event, index, rowData })
    }

    if (onRowRightClick) {
      a11yProps.onContextMenu = (event: React.MouseEvent<any>) =>
        onRowRightClick({ event, index, rowData })
    }
  }

  return (
    <div
      {...a11yProps}
      className={className}
      key={key}
      role='row'
      style={style}
    >
      {columns}
    </div>
  )
}

const SortableTable = SortableContainer(Table, { withRef: true })
const SortableTableRowRenderer =
  SortableElement<TableRowProps>(TableRowRenderer)

const Step3: React.FC<
  Partial<StepWizardChildProps> & {
    selection: number[]
    machineId: number | undefined
    dateRange: Date[] | undefined
    onChange: (selection: number[]) => void
  }
> = ({
  previousStep,
  nextStep,
  goToStep,
  onChange,
  selection,
  machineId,
  dateRange,
}) => {
  const [css, theme] = useStyletron()
  const [parameters, setParameters] = useState<Parameter[]>([])
  const [loading, setLoading] = useState(false)
  const [t] = useTranslation()
  const [api] = useApi()

  useEffect(() => {
    if (machineId !== undefined) {
      setLoading(true)
      api.parametersApi
        .listParameters(machineId)
        .then((result) => {
          setParameters(result.data)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [api.parametersApi, machineId])

  const isSelected = useCallback(
    (parameterId: number) => {
      return selection.includes(parameterId)
    },
    [selection]
  )

  const bodyCellClass = useMemo(
    () =>
      css({
        ...theme.typography.ParagraphSmall,
        ...theme.borders.border300,
        borderTop: 'none',
        borderBottom: 'none',
        [theme.direction === 'rtl' ? 'borderRight' : 'borderLeft']: 'none',
        borderColor: 'transparent',
        color: theme.colors.contentPrimary,
        paddingTop: theme.sizing.scale300,
        paddingRight: theme.sizing.scale600,
        paddingBottom: theme.sizing.scale300,
        paddingLeft: theme.sizing.scale600,
        ':nth-last-of-type(2)': {
          [theme.direction === 'rtl' ? 'borderLeft' : 'borderRight']: 'none',
        },
        ':last-of-type': {
          [theme.direction === 'rtl' ? 'borderLeft' : 'borderRight']: 'none',
        },
      }),
    [css, theme]
  )

  return (
    <Block flex={'auto'} display={'flex'} flexDirection={'column'}>
      <Block
        flex={'auto'}
        overrides={{
          Block: {
            style: ({ $theme }) => ({
              borderBottomStyle: $theme.borders.border400.borderStyle,
              borderBottomWidth: $theme.borders.border400.borderWidth,
              borderBottomColor: $theme.colors.borderOpaque,
            }),
          },
        }}
      >
        <AutoSizer>
          {({ width, height }) => (
            <>
              <SortableTable
                distance={1}
                lockAxis={'y'}
                useDragHandle={false}
                height={height}
                width={width}
                headerHeight={44}
                rowHeight={36}
                rowCount={parameters.length}
                rowGetter={({ index }) => parameters[index]}
                rowClassName={({ index }) => {
                  if (index === -1) {
                    return css({
                      backgroundColor: theme.colors.tableHeadBackgroundColor,
                      boxShadow: theme.lighting.shadow400,
                      display: 'flex',
                      alignItems: 'center',
                      ':focus': {
                        outline: 'none',
                      },
                    })
                  } else {
                    return css({
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: isSelected(parameters[index].id!)
                        ? theme.colors.backgroundTertiary
                        : 'inherit',
                      ':focus': {
                        outline: 'none',
                      },
                      cursor: 'pointer',
                    })
                  }
                }}
                rowRenderer={(props) => <SortableTableRowRenderer {...props} />}
                onSortEnd={({ oldIndex, newIndex }) => {
                  const orderedParameters = arrayMove(
                    parameters,
                    oldIndex,
                    newIndex
                  )
                  onChange(
                    orderedParameters
                      .filter(({ id }) => selection.includes(id!))
                      .map(({ id }) => id!)
                  )
                  setParameters(orderedParameters)
                }}
                headerRowRenderer={({ className, columns, style }) => {
                  return (
                    <>
                      <div className={className} role='row' style={style}>
                        {columns}
                      </div>
                      {loading && (
                        <ProgressBar
                          infinite
                          overrides={{
                            Bar: {
                              style: {
                                position: 'absolute',
                                width: `${style.width}px`,
                                marginBottom: 0,
                                marginLeft: 0,
                                marginRight: 0,
                                marginTop: 0,
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                              },
                            },
                            BarProgress: {
                              style: {
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                              },
                            },
                          }}
                        />
                      )}
                    </>
                  )
                }}
                headerClassName={css({
                  ...theme.typography.LabelMedium,
                  ...theme.borders.border300,
                  borderTop: 'none',
                  borderBottom: 'none',
                  [theme.direction === 'rtl' ? 'borderRight' : 'borderLeft']:
                    'none',
                  color: theme.colors.contentPrimary,
                  paddingTop: theme.sizing.scale500,
                  paddingRight: theme.sizing.scale600,
                  paddingBottom: theme.sizing.scale500,
                  paddingLeft: theme.sizing.scale600,
                  ':focus': {
                    outline: 'none',
                  },
                  ':nth-last-of-type(2)': {
                    [theme.direction === 'rtl' ? 'borderLeft' : 'borderRight']:
                      'none',
                  },
                  ':last-of-type': {
                    [theme.direction === 'rtl' ? 'borderLeft' : 'borderRight']:
                      'none',
                  },
                })}
                gridClassName={css({
                  ':focus': {
                    outline: 'none',
                  },
                })}
                className={css({
                  ':focus': {
                    outline: 'none',
                  },
                  backgroundColor: theme.colors.tableBackground,
                  display: 'flex',
                  flexDirection: 'column',
                })}
                onRowClick={({ rowData, event }) => {
                  event.preventDefault()
                  if (!isSelected(rowData['id'])) {
                    onChange([...selection, rowData['id']])
                  } else {
                    onChange(
                      selection.filter(
                        (parameterId) => parameterId !== rowData['id']
                      )
                    )
                  }
                }}
                noRowsRenderer={() => {
                  return (
                    <div
                      className={css({
                        ...theme.typography.ParagraphMedium,
                        color: theme.colors.contentPrimary,
                        textAlign: 'center',
                        paddingTop: theme.sizing.scale800,
                      })}
                    >
                      {t('settings.no_parameters')}
                    </div>
                  )
                }}
              >
                <Column
                  label={t('settings.parameter')}
                  dataKey={'id'}
                  width={120}
                  headerRenderer={({ label }) => (
                    <Checkbox
                      checked={
                        selection.length > 0 &&
                        selection.length === parameters.length
                      }
                      labelPlacement={LABEL_PLACEMENT.right}
                      onChange={(e) => {
                        if (e.currentTarget.checked) {
                          onChange(parameters.map(({ id }) => id!))
                        } else {
                          onChange([])
                        }
                      }}
                      overrides={{
                        Checkmark: {
                          style: {
                            marginTop: 0,
                            marginBottom: 0,
                          },
                        },
                        Label: {
                          style: ({ $theme }) => ({
                            ...$theme.typography.LabelMedium,
                          }),
                        },
                      }}
                    >
                      {label}
                    </Checkbox>
                  )}
                  cellRenderer={({ rowData, dataKey }) => {
                    return (
                      <Checkbox
                        checked={isSelected(rowData[dataKey])}
                        labelPlacement={LABEL_PLACEMENT.right}
                        overrides={{
                          Label: {
                            style: ({ $theme }) => ({
                              ...$theme.typography.ParagraphSmall,
                            }),
                          },
                        }}
                      >
                        {rowData[dataKey]}
                      </Checkbox>
                    )
                  }}
                  className={bodyCellClass}
                />
                <Column
                  label={t('settings.name')}
                  dataKey={'name'}
                  width={100}
                  flexGrow={1}
                  className={bodyCellClass}
                />
                <Column
                  dataKey={'dragHandle'}
                  width={24}
                  cellRenderer={() => <DragHandle />}
                  className={bodyCellClass}
                />
              </SortableTable>
            </>
          )}
        </AutoSizer>
      </Block>
      <Block
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        padding={'scale600'}
        backgroundColor={'backgroundSecondary'}
      >
        <Button
          onClick={() => {
            if (dateRange !== undefined) {
              goToStep && goToStep(2)
            } else {
              previousStep && previousStep()
            }
          }}
          startEnhancer={() => <ChevronLeft size={24} />}
          overrides={{
            BaseButton: {
              style: {
                paddingLeft: '8px',
              },
            },
            StartEnhancer: {
              style: {
                marginRight: 0,
              },
            },
          }}
        >
          {t('export.prev')}
        </Button>
        <span
          className={css({
            ...theme.typography.LabelMedium,
            color: theme.colors.primary,
          })}
        >
          {t('export.parameter_selection_info', {
            count: selection.length,
            total: parameters.length,
          })}
        </span>
        <Button
          onClick={nextStep}
          endEnhancer={() => <ChevronRight size={24} />}
          disabled={selection.length === 0}
          overrides={{
            BaseButton: {
              style: {
                paddingRight: '8px',
              },
            },
            EndEnhancer: {
              style: {
                marginLeft: 0,
              },
            },
          }}
        >
          {t('export.next')}
        </Button>
      </Block>
    </Block>
  )
}

export { Step3 }
