import { faBars, faClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LocaleProvider, useStyletron } from 'baseui'
import { Block } from 'baseui/block'
import { Button, KIND } from 'baseui/button'
import { ButtonGroup } from 'baseui/button-group'
import { Calendar } from 'baseui/datepicker'
import { Locale as LocaleT } from 'baseui/locale'
import { StatefulMenu } from 'baseui/menu'
import { PLACEMENT, StatefulPopover } from 'baseui/popover'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { saveSvgAsPng } from 'save-svg-as-png'

import { useLocale } from '../../utils'

const isValidRange = (range: Date | Date[]) => {
  return (
    Array.isArray(range) &&
    range.length === 2 &&
    range[0] !== undefined &&
    range[1] !== undefined &&
    range[1].getTime() > range[0].getTime()
  )
}

const DateChooser: React.FC<{
  value: [Date, Date]
  close: () => void
  onSelect: (range: [Date, Date]) => void
}> = React.memo(({ value, close, onSelect }) => {
  const [range, setRange] = useState<Date | Date[]>(value)
  const [t, i18n] = useTranslation()
  const locale = useLocale()

  return (
    <Block
      display={'flex'}
      flexDirection={'column'}
      backgroundColor={'calendarBackground'}
    >
      <LocaleProvider
        locale={
          {
            datepicker: {
              timeSelectEndLabel: t('datepicker.timeSelectEndLabel'),
              timeSelectStartLabel: t('datepicker.timeSelectStartLabel'),
            },
          } as unknown as LocaleT
        }
      >
        <Calendar
          value={range}
          locale={locale}
          range
          timeSelectStart
          timeSelectEnd
          maxDate={new Date()}
          onChange={({ date }) => {
            if (Array.isArray(date)) {
              if (date[0] !== undefined && date[1] !== undefined) {
                if (date[1].getTime() > new Date().getTime()) {
                  date[1] = new Date()
                }
                if (date[1].getTime() < date[0].getTime()) {
                  setRange([date[1], date[0]])
                } else {
                  setRange([date[0], date[1]])
                }
              } else {
                setRange(date)
              }
            } else {
              setRange(date)
            }
          }}
          overrides={{
            TimeSelect: {
              props: {
                format: i18n.language === 'de' ? '24' : '12',
                size: 'compact',
                step: 3600,
                creatable: true,
              },
            },
          }}
        />
      </LocaleProvider>
      <Button
        size={'compact'}
        disabled={!isValidRange(range)}
        onClick={() => {
          close()
          if (range !== undefined) {
            onSelect(range as [Date, Date])
          }
        }}
        overrides={{
          BaseButton: {
            style: ({ $theme }) => ({
              marginLeft: $theme.sizing.scale600,
              marginRight: $theme.sizing.scale600,
              marginBottom: $theme.sizing.scale600,
            }),
          },
        }}
      >
        {t('apply')}
      </Button>
    </Block>
  )
})

const Controls: React.FC<{
  domain: [number, number]
  element: SVGElement
  onDateSelect: (range: [Date, Date]) => void
}> = React.memo(({ domain, element, onDateSelect }) => {
  const [, theme] = useStyletron()
  const [t] = useTranslation()

  const ITEMS = [{ label: t('save'), action: 'save' }]
  return (
    <>
      <ButtonGroup kind={KIND.primary}>
        <StatefulPopover
          content={({ close }) => (
            <DateChooser
              value={[new Date(domain[0]), new Date(domain[1])]}
              close={close}
              onSelect={onDateSelect}
            />
          )}
          placement={PLACEMENT.leftTop}
          showArrow
          accessibilityType={'menu'}
          overrides={{
            Arrow: {
              style: ({ $theme }) => ({
                backgroundColor: $theme.colors.calendarHeaderBackground,
              }),
            },
          }}
        >
          <Button
            size={'compact'}
            overrides={{
              StartEnhancer: {
                style: ({ $theme }) => ({
                  marginRight: $theme.sizing.scale100,
                }),
              },
              BaseButton: {
                style: {
                  opacity: 0.45,
                  ':hover': {
                    opacity: 1,
                  },
                },
              },
            }}
          >
            <FontAwesomeIcon icon={faClock} />
          </Button>
        </StatefulPopover>
        <StatefulPopover
          content={({ close }) => (
            <StatefulMenu
              items={ITEMS}
              onItemSelect={({ item }) => {
                if (item.action === 'save') {
                  saveSvgAsPng(element, 'chart.png', {
                    backgroundColor: theme.colors.background,
                  })
                }
                close()
              }}
              size={'compact'}
              overrides={{
                List: {
                  style: {
                    paddingTop: '8px',
                    paddingBottom: '8px',
                  },
                },
              }}
            />
          )}
          placement={PLACEMENT.bottomRight}
          accessibilityType={'menu'}
        >
          <Button
            size={'compact'}
            overrides={{
              StartEnhancer: {
                style: ({ $theme }) => ({
                  marginRight: $theme.sizing.scale100,
                }),
              },
              BaseButton: {
                style: {
                  opacity: 0.45,
                  ':hover': {
                    opacity: 1,
                  },
                },
              },
            }}
          >
            <FontAwesomeIcon icon={faBars} />
          </Button>
        </StatefulPopover>
      </ButtonGroup>
    </>
  )
})

export { Controls }
