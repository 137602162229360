import { faCircle, faPause, faPlay } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStyletron } from 'baseui'
import { Block } from 'baseui/block'
import { Button } from 'baseui/button'
import { Select } from 'baseui/select'
import { LabelSmall } from 'baseui/typography'
import { Machine } from 'client/dist/models'
import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useApi } from '../ApiProvider'
import { DispatchContext } from '../App'
import { StompState, useStomp } from '../StompProvider'
import { connectorTypeDisplayName } from '../utils'

const MachineChooser: React.FC<{
  value: number | undefined
  onChange: (machine: number) => void
}> = ({ value, onChange }) => {
  const [machines, setMachines] = useState<Machine[]>()
  const [t] = useTranslation()
  const [api] = useApi()
  const [css, theme] = useStyletron()

  useEffect(() => {
    api.machinesApi.listMachines().then((response) => {
      setMachines(response.data)
    })
  }, [api.channelsApi, api.machinesApi, api.parametersApi])

  return (
    <Select
      clearable={false}
      options={machines}
      labelKey={'name'}
      value={[
        {
          id: value,
        },
      ]}
      onChange={(params) => {
        if (params.value[0] !== undefined) {
          onChange(params.value[0].id as number)
        }
      }}
      size={'compact'}
      placeholder={t('header.select_machine')}
      noResultsMsg={t('no_results')}
      searchable={false}
      getOptionLabel={({ option }) => {
        return (
          <>
            <div
              className={css({
                paddingBottom: theme.sizing.scale100,
              })}
            >
              {option?.name}
            </div>
            <LabelSmall
              overrides={{
                Block: {
                  style: {
                    color: 'inherit',
                  },
                },
              }}
            >
              {connectorTypeDisplayName(option?.connector)}
            </LabelSmall>
          </>
        )
      }}
    />
  )
}

const HeaderItem: React.FC<{
  label?: ReactNode
  width?: string
}> = ({ label, width, children }) => {
  const [css, theme] = useStyletron()
  return label !== undefined ? (
    <div
      className={css({
        ...theme.typography.ParagraphSmall,
        color: theme.colors.contentPrimary,
        width: width ? width : theme.sizing.scale3200,
        paddingBottom: theme.sizing.scale800,
      })}
    >
      <Block
        color={'contentTertiary'}
        font={['LabelSmall', 'LabelSmall', 'LabelSmall', 'LabelMedium']}
      >
        {label}
      </Block>
      <div>{children}</div>
    </div>
  ) : (
    <div
      className={css({
        ...theme.typography.ParagraphSmall,
        color: theme.colors.contentPrimary,
        width: width ? width : theme.sizing.scale3200,
        paddingBottom: theme.sizing.scale800,
      })}
    >
      {children}
    </div>
  )
}

const Header: React.FC<{
  machine: number | undefined
  autoRefresh: boolean
  showOrders: boolean
  showSpools: boolean
  onChange: (machine: number) => void
}> = React.memo(
  ({ machine, autoRefresh, showOrders, showSpools, onChange }) => {
    const dispatch = useContext(DispatchContext)
    const [connectorStatus, setConnectorStatus] = useState<
      'ONLINE' | 'SOME_OFFLINE' | 'OFFLINE'
    >('OFFLINE')
    const [, theme] = useStyletron()
    const [t] = useTranslation()
    const [stomp] = useStomp()

    useEffect(() => {
      if (stomp.state === StompState.OPEN) {
        const subscription = stomp.client.subscribe(
          '/topic/monitoring',
          (message) => {
            const payload = JSON.parse(message.body)
            switch (payload.dataStatus) {
              case 'ONLINE':
                setConnectorStatus('ONLINE')
                break
              case 'SOME_OFFLINE':
                setConnectorStatus('SOME_OFFLINE')
                break
              case 'OFFLINE':
              case 'UNKNOWN':
              default:
                setConnectorStatus('OFFLINE')
                break
            }
          }
        )
        return () => {
          subscription.unsubscribe()
        }
      }
    }, [stomp.client, stomp.state])

    return (
      <Block
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        overrides={{
          Block: {
            style: {
              textAlign: 'center',
            },
          },
        }}
      >
        <HeaderItem width={theme.sizing.scale4800}>
          <MachineChooser value={machine} onChange={onChange} />
        </HeaderItem>
        <Button
          size={'compact'}
          onClick={() =>
            dispatch({
              type: 'setAutoRefresh',
              autoRefresh: !autoRefresh,
            })
          }
          $style={
            {
              //marginTop: theme.sizing.scale400,
            }
          }
          disabled={machine === undefined}
        >
          <FontAwesomeIcon icon={autoRefresh ? faPause : faPlay} />
        </Button>
        <HeaderItem label={t('header.data')} width={theme.sizing.scale4800}>
          <span>
            <FontAwesomeIcon
              icon={faCircle}
              color={(() => {
                switch (connectorStatus) {
                  case 'ONLINE':
                    return theme.colors.positive
                  case 'SOME_OFFLINE':
                    return theme.colors.warning
                  case 'OFFLINE':
                    return theme.colors.negative
                }
              })()}
            />
            &nbsp;
            {(() => {
              switch (connectorStatus) {
                case 'ONLINE':
                  return 'online'
                case 'SOME_OFFLINE':
                  return 'einige sind offline'
                case 'OFFLINE':
                  return 'offline'
              }
            })()}
          </span>
        </HeaderItem>
      </Block>
    )
  }
)

export { Header }
