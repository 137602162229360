import axios from 'axios'
import { useStyletron } from 'baseui'
import { Accordion, Panel } from 'baseui/accordion'
import { Block } from 'baseui/block'
import { Breadcrumbs } from 'baseui/breadcrumbs'
import { StyledLink } from 'baseui/link'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AutoSizer } from 'react-virtualized'

type LicenseInfoT = {
  name: string
  version: string
  homepage?: string
  license: string
  licenseText?: string
}

const Info: React.FC = () => {
  const [css, theme] = useStyletron()
  const [t] = useTranslation()
  const [buildInfo, setBuildInfo] = useState<any>()
  const [licenseInfo, setLicenseInfo] = useState<LicenseInfoT[]>([])

  useEffect(() => {
    axios.get(`${window.env.APP_BASE_PATH}/actuator/info`).then((result) => {
      setBuildInfo(result.data)
    })

    axios
      .get<LicenseInfoT[]>(
        `${window.env.APP_BASE_PATH}/3rd-party-licenses.json`
      )
      .then((result) => {
        setLicenseInfo(
          result.data.slice().sort((a, b) => {
            const nameA = a.name
              .substring(a.name.indexOf(':') + 1)
              .toUpperCase()
            const nameB = b.name
              .substring(b.name.indexOf(':') + 1)
              .toUpperCase()
            if (nameA < nameB) {
              return -1
            }
            if (nameA > nameB) {
              return 1
            }
            return 0
          })
        )
      })
  }, [])

  return (
    <Block flex={'auto'} display={'flex'} flexDirection={'column'}>
      <Block
        paddingTop={'scale600'}
        paddingRight={'scale600'}
        paddingLeft={'scale600'}
      >
        <Breadcrumbs
          overrides={{
            Root: {
              style: ({ $theme }) => ({
                paddingBottom: $theme.sizing.scale600,
              }),
            },
          }}
        >
          <span>{t('settings.settings')}</span>
          <span>{t('settings.info')}</span>
        </Breadcrumbs>
      </Block>
      <Block flex={'auto'} display={'flex'} flexDirection={'column'}>
        <Accordion
          initialState={{ expanded: ['version'] }}
          overrides={{
            Root: {
              style: {
                flex: 'auto',
                display: 'flex',
                flexDirection: 'column',
              },
            },
            Content: {
              style: ({ $theme, $expanded }) => ({
                paddingLeft: $theme.sizing.scale600,
                paddingRight: $theme.sizing.scale600,
                paddingTop: $expanded ? $theme.sizing.scale600 : 0,
                paddingBottom: $expanded ? $theme.sizing.scale600 : 0,
              }),
            },
          }}
        >
          <Panel key={'version'} title={t('settings.version')}>
            {buildInfo && (
              <Block font={'ParagraphSmall'} color={'contentPrimary'}>
                {buildInfo.build.version} ({buildInfo.git.commit.id})
              </Block>
            )}
          </Panel>
          <Panel
            title={t('settings.acknowledgments')}
            overrides={{
              PanelContainer: {
                style: {
                  flex: '1',
                  display: 'flex',
                  flexDirection: 'column',
                },
              },
              Content: {
                style: ({ $theme, $expanded }) => ({
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingTop: 0,
                  paddingBottom: 0,
                  flex: 'auto',
                }),
              },
            }}
          >
            <AutoSizer>
              {({ width, height }) => {
                return (
                  <div
                    className={css({
                      width: `${width}px`,
                      height: `${height}px`,
                      overflow: 'auto',
                    })}
                  >
                    {licenseInfo.map((license, index) => {
                      return (
                        <div
                          key={index}
                          className={css({
                            paddingTop: theme.sizing.scale600,
                            paddingLeft: theme.sizing.scale600,
                            paddingRight: theme.sizing.scale600,
                            paddingBottom: theme.sizing.scale600,
                          })}
                        >
                          <div
                            className={css({
                              ...theme.typography.HeadingXSmall,
                              textTransform: 'uppercase',
                            })}
                          >
                            {license.name.substring(
                              license.name.indexOf(':') + 1
                            )}
                          </div>
                          {license.homepage && (
                            <div
                              className={css({
                                paddingTop: theme.sizing.scale300,
                              })}
                            >
                              <span
                                className={css({
                                  ...theme.typography.LabelSmall,
                                  marginRight: theme.sizing.scale100,
                                })}
                              >
                                Homepage:
                              </span>
                              <StyledLink href={license.homepage}>
                                {license.homepage}
                              </StyledLink>
                            </div>
                          )}
                          <div
                            className={css({
                              paddingTop: theme.sizing.scale300,
                            })}
                          >
                            <span
                              className={css({
                                ...theme.typography.LabelSmall,
                                marginRight: theme.sizing.scale100,
                              })}
                            >
                              License:
                            </span>
                            <span
                              className={css({
                                fontWeight: 800,
                              })}
                            >
                              {license.license}
                            </span>
                          </div>
                          {license.licenseText && (
                            <pre
                              className={css({
                                ...theme.typography.ParagraphSmall,
                                fontFamily: 'monospace',
                              })}
                            >
                              {license.licenseText}
                            </pre>
                          )}
                        </div>
                      )
                    })}
                  </div>
                )
              }}
            </AutoSizer>
          </Panel>
        </Accordion>
      </Block>
    </Block>
  )
}

export { Info }
