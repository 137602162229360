import { createLightTheme } from 'baseui'
import { colors } from 'baseui/tokens'

const LightTheme = createLightTheme(
  {},
  {
    breakpoints: {
      small: 670,
      medium: 920,
      large: 1280,
    },
    mediaQuery: {
      small: '@media screen and (min-width: 670px)',
      medium: '@media screen and (min-width: 920px)',
      large: '@media screen and (min-width: 1280px)',
    },
    avatarBackgroundColor: colors.black,
    avatarInitialsColor: colors.white,
    navItemColor: colors.gray200,
  }
)
export default LightTheme
