import {
  faCheck,
  faPen,
  faPlus,
  faTimes,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStyletron } from 'baseui'
import { Accordion, Panel } from 'baseui/accordion'
import { Block } from 'baseui/block'
import { Breadcrumbs } from 'baseui/breadcrumbs'
import { Button, KIND } from 'baseui/button'
import { ButtonGroup } from 'baseui/button-group'
import { FormControl } from 'baseui/form-control'
import { SIZE, StatefulInput } from 'baseui/input'
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from 'baseui/modal'
import { ProgressBar } from 'baseui/progress-bar'
import { StatefulSelect, Value } from 'baseui/select'
import { Axis, AxisType, Machine } from 'client'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AutoSizer, Column, SortDirectionType, Table } from 'react-virtualized'

import { useApi } from '../../ApiProvider'
import { StatefulNumberFormat } from './Machine'

type AxisT = Omit<Axis, 'minLowerBound' | 'maxUpperBound'> & {
  minLowerBound?: number
  maxUpperBound?: number
}

const AxisChooser: React.FC<{
  isOpen: boolean
  onOk: (
    axis: AxisType,
    name: string,
    lowerLimit: number,
    upperLimit: number,
    scale: number,
    unit?: string
  ) => void
  onCancel: () => void
  usedAxes: AxisType[]
}> = (props) => {
  const [axis, setAxis] = useState<Value>([])
  const [name, setName] = useState<string>('')
  const [unit, setUnit] = useState<string>('')
  const [lowerLimit, setLowerLimit] = useState<number>()
  const [upperLimit, setUpperLimit] = useState<number>()
  const scale = useRef<number>(0)
  const [t, i18n] = useTranslation()

  return (
    <Modal isOpen={props.isOpen} closeable={false} unstable_ModalBackdropScroll>
      <ModalHeader>{t('settings.add_axis')}</ModalHeader>
      <ModalBody>
        <FormControl label={() => t('settings.axis_position_label')}>
          <StatefulSelect
            initialState={{
              value: axis,
            }}
            options={Object.keys(AxisType)
              .filter(
                (axisType) =>
                  !props.usedAxes.includes(axisType as AxisType) &&
                  axisType !== AxisType.NONE
              )
              .map((key) => {
                return {
                  label: t(`channel.axis.${key}`),
                  id: key,
                }
              })}
            clearable={false}
            searchable={false}
            deleteRemoves={false}
            backspaceRemoves={false}
            onChange={(selection) => setAxis(selection.value)}
            placeholder={t('settings.select_axis_position')}
          />
        </FormControl>
        <FormControl label={() => t('settings.name')}>
          <StatefulInput
            initialState={{
              value: name,
            }}
            onChange={(event) => setName(event.currentTarget.value)}
          />
        </FormControl>
        <FormControl label={() => t('settings.unit')}>
          <StatefulInput
            initialState={{
              value: unit,
            }}
            onChange={(event) => setUnit(event.currentTarget.value)}
          />
        </FormControl>
        <FormControl label={() => t('settings.lower_limit')}>
          <StatefulNumberFormat
            size={SIZE.default as any}
            decimalSeparator={i18n.language === 'de' ? ',' : '.'}
            decimalScale={6}
            allowedDecimalSeparators={[',', '.']}
            initialValue={lowerLimit?.toString() ?? ''}
            onValueChange={({ floatValue, value }) => {
              const [, decimals] = value.split('.')
              scale.current = decimals !== undefined ? decimals.length : 0
              setLowerLimit(floatValue)
            }}
          />
        </FormControl>
        <FormControl label={() => t('settings.upper_limit')}>
          <StatefulNumberFormat
            size={SIZE.default as any}
            decimalSeparator={i18n.language === 'de' ? ',' : '.'}
            decimalScale={6}
            allowedDecimalSeparators={[',', '.']}
            initialValue={upperLimit?.toString() ?? ''}
            onValueChange={({ floatValue, value }) => {
              const [, decimals] = value.split('.')
              scale.current = decimals !== undefined ? decimals.length : 0
              setUpperLimit(floatValue)
            }}
          />
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <ModalButton
          onClick={() => {
            props.onCancel()
            setAxis([])
            setName('')
            setUnit('')
            setLowerLimit(undefined)
            setUpperLimit(undefined)
          }}
        >
          {t('cancel')}
        </ModalButton>
        <ModalButton
          disabled={
            axis.length === 0 ||
            !name ||
            lowerLimit === undefined ||
            upperLimit === undefined
          }
          onClick={() => {
            props.onOk(
              axis[0].id as AxisType,
              name,
              lowerLimit!,
              upperLimit!,
              scale.current,
              unit
            )
            setAxis([])
            setName('')
            setUnit('')
            setLowerLimit(undefined)
            setUpperLimit(undefined)
          }}
        >
          {t('ok')}
        </ModalButton>
      </ModalFooter>
    </Modal>
  )
}

const AxesTable: React.FC<{ machienId: number }> = ({ machienId }) => {
  const [css, theme] = useStyletron()
  const [t, i18n] = useTranslation()
  const [loading, setLoading] = useState(false)
  const [editing, setEditing] = useState(false)
  const [valid, setValid] = useState(true)
  const [sort, setSort] = useState<{
    column: string
    direction: SortDirectionType
  }>({
    column: 'type',
    direction: 'ASC',
  })
  const [api] = useApi()
  const [isOpen, setIsOpen] = useState(false)
  const [axes, setAxes] = useState<Axis[]>([])
  const record = useRef<AxisT | undefined>()

  const addAxis = useCallback(
    (axis: Axis) => {
      api.axesApi.addAxis(axis).then((result) => {
        setAxes(
          [...axes, result.data].sort((a, b) => {
            const valueA = (a as any)[sort.column]
            const valueB = (b as any)[sort.column]
            if (valueA < valueB) {
              return sort.direction === 'ASC' ? -1 : 1
            }
            if (valueA > valueB) {
              return sort.direction === 'ASC' ? 1 : -1
            }
            return 0
          })
        )
      })
    },
    [api.axesApi, axes, sort.column, sort.direction]
  )

  const updateAxis = useCallback(
    (axis: Axis) => {
      if (axis.id !== undefined) {
        api.axesApi.updateAxis(axis.id, axis).then((result) => {
          setAxes(
            axes.map(({ id }, index) => {
              if (id === axis.id) {
                return result.data
              } else {
                return axes[index]
              }
            })
          )
          record.current = undefined
          setEditing(false)
        })
      }
    },
    [api.axesApi, axes]
  )

  const deleteAxis = useCallback(
    (axis: Axis) => {
      if (axis.id !== undefined) {
        api.axesApi.deleteAxis(axis.id).then(() => {
          setAxes(axes.filter(({ id }) => id !== axis.id))
        })
      }
    },
    [api.axesApi, axes]
  )

  const startEdit = useCallback((axis: Axis) => {
    record.current = axis
    setEditing(true)
  }, [])

  const cancelEdit = useCallback(() => {
    record.current = undefined
    setEditing(false)
    if (!valid) {
      setValid(true)
    }
  }, [valid])

  const commitEdit = useCallback(() => {
    if (editing && record.current !== undefined && valid) {
      updateAxis(record.current as Axis)
    }
  }, [editing, updateAxis, valid])

  const isEditing = useCallback(
    (rowIndex: number) => {
      return (
        editing &&
        record.current !== undefined &&
        record.current.id === axes[rowIndex].id
      )
    },
    [axes, editing]
  )

  const validate = useCallback(() => {
    setValid(
      !!record.current?.name &&
        record.current?.maxUpperBound !== undefined &&
        record.current?.minLowerBound !== undefined
    )
  }, [])

  const bodyCellClass = useMemo(
    () =>
      css({
        ...theme.typography.ParagraphSmall,
        ...theme.borders.border300,
        borderTop: 'none',
        borderBottom: 'none',
        [theme.direction === 'rtl' ? 'borderRight' : 'borderLeft']: 'none',
        borderColor: 'transparent',
        color: theme.colors.contentPrimary,
        paddingTop: theme.sizing.scale300,
        paddingRight: theme.sizing.scale600,
        paddingBottom: theme.sizing.scale300,
        paddingLeft: theme.sizing.scale600,
        ':last-of-type': {
          [theme.direction === 'rtl' ? 'borderLeft' : 'borderRight']: 'none',
        },
      }),
    [css, theme]
  )

  useEffect(() => {
    setLoading(true)
    api.axesApi
      .listAxes(machienId)
      .then((response) => {
        setAxes(response.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [api.axesApi, machienId])

  return (
    <Block display={'flex'} flexDirection={'column'} flex={'auto'}>
      <Block
        paddingTop={'scale600'}
        paddingLeft={'scale600'}
        paddingBottom={'scale600'}
      >
        <Button
          size={'compact'}
          startEnhancer={() => <FontAwesomeIcon icon={faPlus} />}
          onClick={() => {
            setIsOpen(true)
          }}
          overrides={{
            StartEnhancer: {
              style: ({ $theme }) => ({
                marginRight: $theme.sizing.scale300,
              }),
            },
          }}
        >
          {t('settings.add_axis')}
        </Button>
      </Block>
      <Block flex={'auto'}>
        <AutoSizer>
          {({ width, height }) => (
            <Table
              height={height}
              width={width}
              headerHeight={44}
              rowHeight={36}
              rowCount={axes.length}
              rowGetter={({ index }) => {
                return axes[index]
              }}
              rowClassName={({ index }) => {
                if (index === -1) {
                  return css({
                    backgroundColor: theme.colors.tableHeadBackgroundColor,
                    boxShadow: theme.lighting.shadow400,
                    display: 'flex',
                    alignItems: 'center',
                  })
                } else {
                  return css({
                    display: 'flex',
                    alignItems: 'center',
                    bbackgroundColor: isEditing(index)
                      ? theme.colors.backgroundTertiary
                      : 'inherit',
                  })
                }
              }}
              headerRowRenderer={({ className, columns, style }) => {
                return (
                  <>
                    <div className={className} role='row' style={style}>
                      {columns}
                    </div>
                    {loading && (
                      <ProgressBar
                        infinite
                        overrides={{
                          Bar: {
                            style: {
                              position: 'absolute',
                              width: `${style.width}px`,
                              marginBottom: 0,
                              marginLeft: 0,
                              marginRight: 0,
                              marginTop: 0,
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                            },
                          },
                          BarProgress: {
                            style: {
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                            },
                          },
                        }}
                      />
                    )}
                  </>
                )
              }}
              headerClassName={css({
                ...theme.typography.LabelMedium,
                ...theme.borders.border300,
                borderTop: 'none',
                borderBottom: 'none',
                [theme.direction === 'rtl' ? 'borderRight' : 'borderLeft']:
                  'none',
                color: theme.colors.contentPrimary,
                paddingTop: theme.sizing.scale500,
                paddingRight: theme.sizing.scale600,
                paddingBottom: theme.sizing.scale500,
                paddingLeft: theme.sizing.scale600,
                ':focus': {
                  outline: 'none',
                },
                ':nth-last-of-type(2)': {
                  [theme.direction === 'rtl' ? 'borderLeft' : 'borderRight']:
                    'none',
                },
                ':last-of-type': {
                  [theme.direction === 'rtl' ? 'borderLeft' : 'borderRight']:
                    'none',
                },
              })}
              gridClassName={css({
                ':focus': {
                  outline: 'none',
                },
              })}
              className={css({
                backgroundColor: theme.colors.tableBackground,
                display: 'flex',
                flexDirection: 'column',
              })}
              sortBy={sort.column}
              sortDirection={sort.direction}
              sort={({ sortBy, sortDirection }) => {
                setSort({
                  column: sortBy,
                  direction: sortDirection,
                })
                setAxes(
                  axes.slice().sort((a, b) => {
                    const valueA = (a as any)[sortBy]
                    const valueB = (b as any)[sortBy]
                    if (valueA < valueB) {
                      return sortDirection === 'ASC' ? -1 : 1
                    }
                    if (valueA > valueB) {
                      return sortDirection === 'ASC' ? 1 : -1
                    }
                    return 0
                  })
                )
              }}
              noRowsRenderer={() => {
                return (
                  <div
                    className={css({
                      ...theme.typography.ParagraphMedium,
                      color: theme.colors.contentPrimary,
                      textAlign: 'center',
                      paddingTop: theme.sizing.scale800,
                    })}
                  >
                    {t('settings.no_axes')}
                  </div>
                )
              }}
            >
              <Column
                label={t('settings.axis')}
                dataKey={'type'}
                width={200}
                className={bodyCellClass}
                cellRenderer={({ cellData, rowIndex }) => {
                  return t(`channel.axis.${cellData}`)
                }}
              />
              <Column
                label={t('settings.name')}
                dataKey={'name'}
                width={100}
                flexGrow={1}
                className={bodyCellClass}
                cellRenderer={({ cellData, rowIndex }) => {
                  if (isEditing(rowIndex) && record.current !== undefined) {
                    return (
                      <StatefulInput
                        placeholder={t('settings.name')}
                        initialState={{ value: cellData }}
                        onChange={(e) => {
                          if (record.current !== undefined) {
                            record.current.name = e.currentTarget.value
                          }
                          validate()
                        }}
                        size={'compact'}
                        autoFocus={true}
                        overrides={{
                          InputContainer: {
                            style: {
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: '2px',
                              borderLeftWidth: 0,
                              backgroundColor: 'transparent',
                            },
                          },
                          Input: {
                            style: {
                              paddingRight: 0,
                              paddingLeft: 0,
                            },
                          },
                        }}
                      />
                    )
                  } else {
                    return cellData
                  }
                }}
              />
              <Column
                label={t('settings.unit')}
                dataKey={'unit'}
                width={70}
                className={bodyCellClass}
                cellRenderer={({ cellData, rowIndex }) => {
                  if (isEditing(rowIndex) && record.current !== undefined) {
                    return (
                      <StatefulInput
                        placeholder={t('settings.unit')}
                        initialState={{ value: cellData }}
                        onChange={(e) => {
                          if (record.current !== undefined) {
                            record.current.unit = e.currentTarget.value
                          }
                          validate()
                        }}
                        size={'compact'}
                        autoFocus={true}
                        overrides={{
                          InputContainer: {
                            style: {
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: '2px',
                              borderLeftWidth: 0,
                              backgroundColor: 'transparent',
                            },
                          },
                          Input: {
                            style: {
                              paddingRight: 0,
                              paddingLeft: 0,
                            },
                          },
                        }}
                      />
                    )
                  } else {
                    return cellData
                  }
                }}
              />
              <Column
                label={t('settings.lower_limit')}
                dataKey={'minLowerBound'}
                width={110}
                className={bodyCellClass}
                cellRenderer={({ cellData, rowIndex, rowData }) => {
                  if (isEditing(rowIndex) && record.current !== undefined) {
                    return (
                      <StatefulNumberFormat
                        decimalSeparator={i18n.language === 'de' ? ',' : '.'}
                        decimalScale={6}
                        allowedDecimalSeparators={[',', '.']}
                        placeholder={t('settings.lower_limit')}
                        initialValue={cellData?.toFixed(rowData['scale'])}
                        onValueChange={({ floatValue, value }) => {
                          if (record.current !== undefined) {
                            const [, scale] = value.split('.')
                            record.current.minLowerBound = floatValue
                            record.current.scale =
                              scale !== undefined ? scale.length : 0
                            validate()
                          }
                        }}
                        overrides={{
                          InputContainer: {
                            style: {
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: '2px',
                              borderLeftWidth: 0,
                              backgroundColor: 'transparent',
                            },
                          },
                          Input: {
                            style: {
                              paddingRight: 0,
                              paddingLeft: 0,
                            },
                          },
                        }}
                      />
                    )
                  } else {
                    return cellData?.toLocaleString(i18n.language, {
                      minimumFractionDigits: rowData['scale'],
                      maximumFractionDigits: rowData['scale'],
                      useGouping: false,
                    })
                  }
                }}
              />
              <Column
                label={t('settings.upper_limit')}
                dataKey={'maxUpperBound'}
                width={110}
                className={bodyCellClass}
                cellRenderer={({ cellData, rowIndex, rowData }) => {
                  if (isEditing(rowIndex) && record.current !== undefined) {
                    return (
                      <StatefulNumberFormat
                        decimalSeparator={i18n.language === 'de' ? ',' : '.'}
                        decimalScale={6}
                        allowedDecimalSeparators={[',', '.']}
                        placeholder={t('settings.upper_limit')}
                        initialValue={cellData?.toFixed(rowData['scale'])}
                        onValueChange={({ floatValue, value }) => {
                          if (record.current !== undefined) {
                            const [, scale] = value.split('.')
                            record.current.maxUpperBound = floatValue
                            record.current.scale =
                              scale !== undefined ? scale.length : 0
                            validate()
                          }
                        }}
                        overrides={{
                          InputContainer: {
                            style: {
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: '2px',
                              borderLeftWidth: 0,
                              backgroundColor: 'transparent',
                            },
                          },
                          Input: {
                            style: {
                              paddingRight: 0,
                              paddingLeft: 0,
                            },
                          },
                        }}
                      />
                    )
                  } else {
                    return cellData?.toLocaleString(i18n.language, {
                      minimumFractionDigits: rowData['scale'],
                      maximumFractionDigits: rowData['scale'],
                      useGouping: false,
                    })
                  }
                }}
              />
              <Column
                disableSort
                dataKey={'action'}
                width={60}
                className={bodyCellClass}
                style={{
                  textAlign: 'center',
                }}
                cellRenderer={({ rowIndex }) => {
                  if (!editing || isEditing(rowIndex)) {
                    return (
                      <ButtonGroup
                        size={'mini'}
                        kind={KIND.tertiary}
                        overrides={{
                          Root: {
                            style: {
                              justifyContent: 'end',
                            },
                          },
                        }}
                      >
                        <Button
                          disabled={!valid}
                          overrides={{
                            BaseButton: {
                              style: ({ $theme }) => ({
                                ':hover': {
                                  backgroundColor: 'transparent',
                                  color: $theme.colors.linkHover,
                                },
                                ':focus': {
                                  backgroundColor: 'transparent',
                                },
                                ':active': {
                                  backgroundColor: 'transparent',
                                  color: $theme.colors.linkActive,
                                },
                                ':disabled': {
                                  backgroundColor: 'transparent',
                                },
                              }),
                            },
                          }}
                          onClick={() => {
                            if (isEditing(rowIndex)) {
                              commitEdit()
                            } else {
                              startEdit({ ...axes[rowIndex] })
                            }
                          }}
                        >
                          <FontAwesomeIcon
                            size={'lg'}
                            icon={isEditing(rowIndex) ? faCheck : faPen}
                          />
                        </Button>
                        <Button
                          overrides={{
                            BaseButton: {
                              style: ({ $theme }) => ({
                                ':hover': {
                                  backgroundColor: 'transparent',
                                  color: $theme.colors.linkHover,
                                },
                                ':focus': {
                                  backgroundColor: 'transparent',
                                },
                                ':active': {
                                  backgroundColor: 'transparent',
                                  color: $theme.colors.linkActive,
                                },
                                ':disabled': {
                                  backgroundColor: 'transparent',
                                },
                              }),
                            },
                          }}
                          onClick={() => {
                            if (isEditing(rowIndex)) {
                              cancelEdit()
                            } else {
                              deleteAxis(axes[rowIndex])
                            }
                          }}
                        >
                          <FontAwesomeIcon
                            size={'lg'}
                            icon={isEditing(rowIndex) ? faTimes : faTrash}
                          />
                        </Button>
                      </ButtonGroup>
                    )
                  } else {
                    return null
                  }
                }}
              />
            </Table>
          )}
        </AutoSizer>
        <AxisChooser
          isOpen={isOpen}
          onOk={(axis, name, lowerLimit, upperLimit, scale, unit) => {
            setIsOpen(false)
            addAxis({
              type: axis,
              name: name,
              unit: unit,
              machineId: machienId,
              minLowerBound: lowerLimit,
              maxUpperBound: upperLimit,
              lowerBound: lowerLimit,
              upperBound: upperLimit,
              scale: scale,
            })
          }}
          onCancel={() => setIsOpen(false)}
          usedAxes={axes.map(({ type }) => type)}
        />
      </Block>
    </Block>
  )
}

export const Axes: React.FC = () => {
  const [machines, setMachines] = useState<Machine[]>([])
  const [t] = useTranslation()
  const [api] = useApi()

  useEffect(() => {
    api.machinesApi
      .listMachines()
      .then((response) => setMachines(response.data))
  }, [api])

  return (
    <Block flex={'auto'} display={'flex'} flexDirection={'column'}>
      <Block
        paddingTop={'scale600'}
        paddingRight={'scale600'}
        paddingLeft={'scale600'}
      >
        <Breadcrumbs
          overrides={{
            Root: {
              style: ({ $theme }) => ({
                paddingBottom: $theme.sizing.scale600,
              }),
            },
          }}
        >
          <span>{t('settings.settings')}</span>
          <span>{t('settings.axes')}</span>
        </Breadcrumbs>
      </Block>
      <Block flex={'auto'} display={'flex'} flexDirection={'column'}>
        <Accordion
          overrides={{
            Root: {
              style: {
                flex: 'auto',
                display: 'flex',
                flexDirection: 'column',
              },
            },
            PanelContainer: {
              style: ({ $theme, $expanded }) => ({
                flex: $expanded ? 1 : 0,
                display: 'flex',
                flexDirection: 'column',
              }),
            },
            Content: {
              style: ({ $theme, $expanded }) => ({
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
                paddingBottom: 0,
                flex: 'auto',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: $theme.colors.backgroundPrimary,
              }),
            },
          }}
        >
          {machines.map((machine) => {
            return (
              <Panel key={machine.id} title={machine.name}>
                <AxesTable machienId={machine.id!} />
              </Panel>
            )
          })}
        </Accordion>
      </Block>
    </Block>
  )
}
