import { useStyletron } from 'baseui'
import { scaleUtc } from 'd3-scale'
import { format } from 'date-fns'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDrag } from 'react-use-gesture'

import { useLocale } from '../../utils'

const Cursor: React.FC<{
  position: 'left' | 'right'
  offset: {
    top: number
    bottom: number
    left: number
    right: number
    height: number
    width: number
  }
  label?: (position: number) => string
  onChange?: (position: number) => void
}> = React.memo(({ position, offset, label, onChange = () => {} }) => {
  const [css, theme] = useStyletron()
  const [x, setX] = useState<number>(0)
  const ref = useRef(null)

  const xOffset =
    position === 'left' ? offset.left : offset.left + offset.width - 40
  const cursorPos = position === 'left' ? x + 20 : offset.width + x - 20

  const bind = useDrag(
    (state) => {
      if (state.pinching) {
        return
      }

      if (state.axis === 'x' && state.event !== undefined) {
        state.event.preventDefault()
      }

      if (!state.dragging && state.last) {
        if (x === 0) {
          onChange(0)
        } else {
          onChange(cursorPos)
        }
      }

      if (state.dragging && !state.last) {
        if (
          position === 'left'
            ? x + state.delta[0] <= 0
            : x + state.delta[0] >= 0
        ) {
          if (x !== 0) {
            setX(0)
            onChange(0)
          }
        } else {
          if (
            (position === 'left' &&
              offset.width - (x + state.delta[0]) >= 65) ||
            (position === 'right' && x + state.delta[0] + offset.width >= 65)
          ) {
            setX(x + state.delta[0])
            onChange(cursorPos)
          }
        }
      }
    },
    {
      eventOptions: {
        passive: false,
      },
      lockDirection: true,
      filterTaps: true,
      delay: true,
      domTarget: ref,
    }
  )
  useEffect(bind, [bind])

  return (
    <g transform={`translate(${xOffset},${offset.top})`}>
      {x !== undefined && x !== 0 && (
        <>
          {label && (
            <text
              x={x + 20}
              y={-7}
              textAnchor={'middle'}
              className={css({
                fill: theme.colors.primary,
                fontSize: '12px',
                fontFamily: 'Open Sans',
                fontWeight: 400,
              })}
            >
              <tspan x={x + 20}>{label(cursorPos)}</tspan>
            </text>
          )}
          <polygon
            points={'0,6 4,0 8,6'}
            transform={`translate(${x + 16}, ${offset.height + 4})`}
            className={css({
              fill: theme.colors.primary,
              stroke: theme.colors.primary,
            })}
          />
          <line
            x1={x + 20}
            y1={offset.height + 4}
            x2={x + 20}
            y2={0}
            className={css({
              fill: 'none',
              stroke: theme.colors.primary,
              strokeWidth: 1,
            })}
          />
        </>
      )}
      <rect
        ref={ref}
        x={x}
        y={0}
        width={40}
        height={offset.height}
        className={css({
          fill: 'none',
          pointerEvents: 'visible',
          touchAction: 'pan-y',
          cursor: 'col-resize',
        })}
      />
    </g>
  )
})

const CursorWrapper: React.FC<{
  domain: [number, number]
  offset: {
    top: number
    bottom: number
    left: number
    right: number
    height: number
    width: number
  }
  left?: boolean
  right?: boolean
  onChange?: (values: { left: Date | undefined; right: Date }) => void
}> = React.memo(
  ({ domain, offset, left = false, right = false, onChange = () => {} }) => {
    const [positions, setPositions] = useState<{
      left: number
      right: number
    }>({
      left: 0,
      right: 0,
    })
    const locale = useLocale()

    const scale = useMemo(
      () => scaleUtc().domain(domain).rangeRound([0, offset.width]),
      [domain, offset.width]
    )

    useEffect(() => {
      if (positions.left !== 0 || positions.right !== 0) {
        const leftDate =
          positions.left !== 0 ? scale.invert(positions.left) : undefined
        const rightDate =
          positions.right !== 0
            ? scale.invert(positions.right)
            : scale.domain()[1]
        onChange({
          left:
            leftDate !== undefined && leftDate.getTime() > rightDate.getTime()
              ? rightDate
              : leftDate,
          right:
            leftDate !== undefined && leftDate.getTime() > rightDate.getTime()
              ? leftDate
              : rightDate,
        })
      } else {
        onChange({
          left: undefined,
          right: scale.domain()[1],
        })
      }
    }, [onChange, positions, scale])

    return (
      <>
        {left && (
          <Cursor
            position={'left'}
            offset={offset}
            onChange={useCallback((value) => {
              setPositions((prevPositions) => {
                return {
                  left: value,
                  right: prevPositions.right,
                }
              })
            }, [])}
            label={useCallback(
              (position) =>
                format(scale.invert(position), 'pp', {
                  locale,
                }),
              [locale, scale]
            )}
          />
        )}
        {right && (
          <Cursor
            position={'right'}
            offset={offset}
            onChange={useCallback((value) => {
              setPositions((prevPositions) => {
                return {
                  left: prevPositions.left,
                  right: value,
                }
              })
            }, [])}
            label={useCallback(
              (position) =>
                format(scale.invert(position), 'pp', {
                  locale,
                }),
              [locale, scale]
            )}
          />
        )}
      </>
    )
  }
)

export { CursorWrapper as Cursor }
