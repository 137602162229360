"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * datAIndustry
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrdersApi = exports.OrdersApiFactory = exports.OrdersApiFp = exports.OrdersApiAxiosParamCreator = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("../common");
// @ts-ignore
var base_1 = require("../base");
/**
 * OrdersApi - axios parameter creator
 * @export
 */
exports.OrdersApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary List all orders
         * @param {string} [query] RSQL Query
         * @param {number} [page] Page
         * @param {number} [size] Page size
         * @param {string} [sort] Sort property
         * @param {'ASC' | 'DESC'} [direction] Sort direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrders: function (query, page, size, sort, direction, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/orders";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication BearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication BearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (query !== undefined) {
                                localVarQueryParameter['query'] = query;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (size !== undefined) {
                                localVarQueryParameter['size'] = size;
                            }
                            if (sort !== undefined) {
                                localVarQueryParameter['sort'] = sort;
                            }
                            if (direction !== undefined) {
                                localVarQueryParameter['direction'] = direction;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary List all orders of a machine in a specified time range
         * @param {number} machineId The id of the machine for which the orders should be retrieved
         * @param {string} from Beginning of the time range to be retrieved
         * @param {string} to End of the time range to be retrieved
         * @param {boolean} [withSpools] Include spools
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrdersByMachine: function (machineId, from, to, withSpools, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'machineId' is not null or undefined
                            common_1.assertParamExists('listOrdersByMachine', 'machineId', machineId);
                            // verify required parameter 'from' is not null or undefined
                            common_1.assertParamExists('listOrdersByMachine', 'from', from);
                            // verify required parameter 'to' is not null or undefined
                            common_1.assertParamExists('listOrdersByMachine', 'to', to);
                            localVarPath = "/orders/{machineId}"
                                .replace("{" + "machineId" + "}", encodeURIComponent(String(machineId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication BearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication BearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (from !== undefined) {
                                localVarQueryParameter['from'] = (from instanceof Date) ?
                                    from.toISOString() :
                                    from;
                            }
                            if (to !== undefined) {
                                localVarQueryParameter['to'] = (to instanceof Date) ?
                                    to.toISOString() :
                                    to;
                            }
                            if (withSpools !== undefined) {
                                localVarQueryParameter['withSpools'] = withSpools;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary List all spools of one order
         * @param {string} orderNumber The id of the order for which the spools should be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSpoolsByOrder: function (orderNumber, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'orderNumber' is not null or undefined
                            common_1.assertParamExists('listSpoolsByOrder', 'orderNumber', orderNumber);
                            localVarPath = "/orders/{orderNumber}/spools"
                                .replace("{" + "orderNumber" + "}", encodeURIComponent(String(orderNumber)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication BearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication BearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * OrdersApi - functional programming interface
 * @export
 */
exports.OrdersApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.OrdersApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary List all orders
         * @param {string} [query] RSQL Query
         * @param {number} [page] Page
         * @param {number} [size] Page size
         * @param {string} [sort] Sort property
         * @param {'ASC' | 'DESC'} [direction] Sort direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrders: function (query, page, size, sort, direction, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listOrders(query, page, size, sort, direction, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary List all orders of a machine in a specified time range
         * @param {number} machineId The id of the machine for which the orders should be retrieved
         * @param {string} from Beginning of the time range to be retrieved
         * @param {string} to End of the time range to be retrieved
         * @param {boolean} [withSpools] Include spools
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrdersByMachine: function (machineId, from, to, withSpools, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listOrdersByMachine(machineId, from, to, withSpools, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary List all spools of one order
         * @param {string} orderNumber The id of the order for which the spools should be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSpoolsByOrder: function (orderNumber, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listSpoolsByOrder(orderNumber, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * OrdersApi - factory interface
 * @export
 */
exports.OrdersApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.OrdersApiFp(configuration);
    return {
        /**
         *
         * @summary List all orders
         * @param {string} [query] RSQL Query
         * @param {number} [page] Page
         * @param {number} [size] Page size
         * @param {string} [sort] Sort property
         * @param {'ASC' | 'DESC'} [direction] Sort direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrders: function (query, page, size, sort, direction, options) {
            return localVarFp.listOrders(query, page, size, sort, direction, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary List all orders of a machine in a specified time range
         * @param {number} machineId The id of the machine for which the orders should be retrieved
         * @param {string} from Beginning of the time range to be retrieved
         * @param {string} to End of the time range to be retrieved
         * @param {boolean} [withSpools] Include spools
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrdersByMachine: function (machineId, from, to, withSpools, options) {
            return localVarFp.listOrdersByMachine(machineId, from, to, withSpools, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary List all spools of one order
         * @param {string} orderNumber The id of the order for which the spools should be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSpoolsByOrder: function (orderNumber, options) {
            return localVarFp.listSpoolsByOrder(orderNumber, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * OrdersApi - object-oriented interface
 * @export
 * @class OrdersApi
 * @extends {BaseAPI}
 */
var OrdersApi = /** @class */ (function (_super) {
    __extends(OrdersApi, _super);
    function OrdersApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary List all orders
     * @param {string} [query] RSQL Query
     * @param {number} [page] Page
     * @param {number} [size] Page size
     * @param {string} [sort] Sort property
     * @param {'ASC' | 'DESC'} [direction] Sort direction
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    OrdersApi.prototype.listOrders = function (query, page, size, sort, direction, options) {
        var _this = this;
        return exports.OrdersApiFp(this.configuration).listOrders(query, page, size, sort, direction, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary List all orders of a machine in a specified time range
     * @param {number} machineId The id of the machine for which the orders should be retrieved
     * @param {string} from Beginning of the time range to be retrieved
     * @param {string} to End of the time range to be retrieved
     * @param {boolean} [withSpools] Include spools
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    OrdersApi.prototype.listOrdersByMachine = function (machineId, from, to, withSpools, options) {
        var _this = this;
        return exports.OrdersApiFp(this.configuration).listOrdersByMachine(machineId, from, to, withSpools, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary List all spools of one order
     * @param {string} orderNumber The id of the order for which the spools should be retrieved
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    OrdersApi.prototype.listSpoolsByOrder = function (orderNumber, options) {
        var _this = this;
        return exports.OrdersApiFp(this.configuration).listSpoolsByOrder(orderNumber, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return OrdersApi;
}(base_1.BaseAPI));
exports.OrdersApi = OrdersApi;
