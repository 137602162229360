import {
  faCheck,
  faPen,
  faPlus,
  faTimes,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStyletron } from 'baseui'
import { Block } from 'baseui/block'
import { Breadcrumbs } from 'baseui/breadcrumbs'
import { Button, KIND } from 'baseui/button'
import { ButtonGroup } from 'baseui/button-group'
import { StatefulCheckbox } from 'baseui/checkbox'
import { StatefulInput } from 'baseui/input'
import { ProgressBar } from 'baseui/progress-bar'
import { StatefulSelect } from 'baseui/select'
import { Connector, Machine } from 'client/dist/models'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AutoSizer, Column, SortDirectionType, Table } from 'react-virtualized'

import { useApi } from '../../ApiProvider'
import { connectorTypeDisplayName } from '../../utils'

type MachineT = Machine & { isNew: boolean }

const Machines: React.FC<{
  onMachineAdded?: (machine: Machine) => void
  onMachineRemoved?: (machine: Machine) => void
}> = ({ onMachineAdded = () => {}, onMachineRemoved = () => {} }) => {
  const [css, theme] = useStyletron()
  const [machines, setMachines] = useState<MachineT[]>([])
  const [connnectors, setConnectors] = useState<Connector[]>([])
  const [selectedConnector, setSelectedConnector] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [editing, setEditing] = useState(false)
  const [valid, setValid] = useState(true)
  const [availableConnections, setAvailableConnections] = useState<string[]>([])
  const [sort, setSort] = useState<{
    column: string
    direction: SortDirectionType
  }>({
    column: 'id',
    direction: 'ASC',
  })
  const record = useRef<MachineT | undefined>()
  const [t] = useTranslation()
  const [api] = useApi()

  const addMachine = useCallback(
    (machine: MachineT) => {
      api.machinesApi.addMachine(machine).then((result) => {
        setMachines(
          [{ ...result.data, isNew: false }, ...machines].sort((a, b) => {
            const valueA = (a as any)[sort.column]
            const valueB = (b as any)[sort.column]
            if (valueA < valueB) {
              return sort.direction === 'ASC' ? -1 : 1
            }
            if (valueA > valueB) {
              return sort.direction === 'ASC' ? 1 : -1
            }
            return 0
          })
        )
        record.current = undefined
        setSelectedConnector(undefined)
        setEditing(false)
        onMachineAdded(result.data)
      })
    },
    [api.machinesApi, machines, onMachineAdded, sort.column, sort.direction]
  )

  const updateMachine = useCallback(
    (machine: MachineT) => {
      api.machinesApi.updateMachine(machine.id!, machine).then((result) => {
        setMachines(
          machines.map(({ id }, index) => {
            if (id === machine.id) {
              return { ...result.data, isNew: false }
            } else {
              return machines[index]
            }
          })
        )
        record.current = undefined
        setSelectedConnector(undefined)
        setEditing(false)
      })
    },
    [api.machinesApi, machines]
  )

  const deleteMachine = useCallback(
    (machine: MachineT) => {
      api.machinesApi.deleteMachine(machine.id!).then(() => {
        setMachines(machines.filter(({ id }) => id !== machine.id))
        onMachineRemoved(machine)
      })
    },
    [api.machinesApi, machines, onMachineRemoved]
  )

  const startEdit = useCallback((machine: MachineT) => {
    record.current = machine
    setSelectedConnector(machine.connector)
    setEditing(true)
    if (machine.isNew) {
      setValid(false)
    }
  }, [])

  const cancelEdit = useCallback(() => {
    record.current = undefined
    setSelectedConnector(undefined)
    setEditing(false)
  }, [])

  const commitEdit = useCallback(() => {
    if (editing && record.current !== undefined) {
      if (!record.current.isNew) {
        updateMachine(record.current)
      } else {
        addMachine(record.current)
      }
    }
  }, [addMachine, editing, updateMachine])

  const isNew = useCallback(() => {
    return record.current !== undefined && record.current.isNew
  }, [])

  const isEditing = useCallback(
    (rowIndex: number) => {
      return (
        editing &&
        ((rowIndex === 0 && isNew()) ||
          (record.current !== undefined &&
            record.current.id ===
              machines[isNew() ? rowIndex - 1 : rowIndex].id))
      )
    },
    [editing, isNew, machines]
  )

  const validate = useCallback(() => {
    setValid(
      !!record.current?.name &&
        !!record.current?.connector &&
        !!record.current?.connectionString &&
        record.current.readRate >= 100
    )
  }, [])

  useEffect(() => {
    setLoading(true)
    api.machinesApi
      .listMachines()
      .then((result) => {
        setMachines(
          result.data.map((machine) => {
            return { ...machine, isNew: false }
          })
        )
      })
      .finally(() => {
        setLoading(false)
      })
    api.connectorsApi.listConnectors().then((result) => {
      setConnectors(result.data)
    })
  }, [api.connectorsApi, api.machinesApi])

  useEffect(() => {
    if (selectedConnector !== undefined) {
      api.connectorsApi
        .browseConnections(selectedConnector)
        .then((response) => {
          setAvailableConnections(response.data.slice().sort())
        })
        .catch(() => setAvailableConnections([]))
    } else {
      setAvailableConnections([])
    }
  }, [api.connectorsApi, editing, selectedConnector])

  const bodyCellClass = useMemo(
    () =>
      css({
        ...theme.typography.ParagraphSmall,
        ...theme.borders.border300,
        borderTop: 'none',
        borderBottom: 'none',
        [theme.direction === 'rtl' ? 'borderRight' : 'borderLeft']: 'none',
        borderColor: 'transparent',
        color: theme.colors.contentPrimary,
        paddingTop: theme.sizing.scale300,
        paddingRight: theme.sizing.scale600,
        paddingBottom: theme.sizing.scale300,
        paddingLeft: theme.sizing.scale600,
        ':last-of-type': {
          [theme.direction === 'rtl' ? 'borderLeft' : 'borderRight']: 'none',
        },
      }),
    [css, theme]
  )

  return (
    <Block flex={'auto'} display={'flex'} flexDirection={'column'}>
      <Block
        paddingTop={'scale600'}
        paddingRight={'scale600'}
        paddingBottom={'scale600'}
        paddingLeft={'scale600'}
      >
        <Breadcrumbs
          overrides={{
            Root: {
              style: ({ $theme }) => ({
                paddingBottom: $theme.sizing.scale600,
              }),
            },
          }}
        >
          <span>{t('settings.settings')}</span>
          <span>{t('settings.machines')}</span>
        </Breadcrumbs>
        <Button
          size={'compact'}
          startEnhancer={() => <FontAwesomeIcon icon={faPlus} />}
          onClick={() => {
            if (!editing) {
              startEdit({
                isNew: true,
                id: undefined,
                name: `Verbindung ${
                  machines.length === 0
                    ? 1
                    : Math.max(...machines.map(({ id }) => id!)) + 1
                }`,
                active: true,
                exportEnabled: false,
                exportFormat: 'CSV',
                exportFolder: '',
                connector: '',
                connectionString: '',
                readRate: 1000,
              })
            }
          }}
          disabled={machines.length >= 16}
          overrides={{
            StartEnhancer: {
              style: ({ $theme }) => ({
                marginRight: $theme.sizing.scale300,
              }),
            },
          }}
        >
          {t('add')}
        </Button>
      </Block>
      <Block flex={'auto'}>
        <AutoSizer>
          {({ width, height }) => (
            <Table
              height={height}
              width={width}
              headerHeight={44}
              rowHeight={36}
              rowCount={isNew() ? machines.length + 1 : machines.length}
              rowGetter={({ index }) => {
                if (isNew()) {
                  if (index === 0) {
                    return record.current
                  }
                  return machines[index - 1]
                } else {
                  return machines[index]
                }
              }}
              rowClassName={({ index }) => {
                if (index === -1) {
                  return css({
                    backgroundColor: theme.colors.tableHeadBackgroundColor,
                    boxShadow: theme.lighting.shadow400,
                    display: 'flex',
                    alignItems: 'center',
                  })
                } else {
                  return css({
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: isEditing(index)
                      ? theme.colors.backgroundTertiary
                      : 'inherit',
                  })
                }
              }}
              headerRowRenderer={({ className, columns, style }) => {
                return (
                  <>
                    <div className={className} role='row' style={style}>
                      {columns}
                    </div>
                    {loading && (
                      <ProgressBar
                        infinite
                        overrides={{
                          Bar: {
                            style: {
                              position: 'absolute',
                              width: `${style.width}px`,
                              marginBottom: 0,
                              marginLeft: 0,
                              marginRight: 0,
                              marginTop: 0,
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                            },
                          },
                          BarProgress: {
                            style: {
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                            },
                          },
                        }}
                      />
                    )}
                  </>
                )
              }}
              headerClassName={css({
                ...theme.typography.LabelMedium,
                ...theme.borders.border300,
                borderTop: 'none',
                borderBottom: 'none',
                [theme.direction === 'rtl' ? 'borderRight' : 'borderLeft']:
                  'none',
                color: theme.colors.contentPrimary,
                paddingTop: theme.sizing.scale500,
                paddingRight: theme.sizing.scale600,
                paddingBottom: theme.sizing.scale500,
                paddingLeft: theme.sizing.scale600,
                ':focus': {
                  outline: 'none',
                },
                ':nth-last-of-type(2)': {
                  [theme.direction === 'rtl' ? 'borderLeft' : 'borderRight']:
                    'none',
                },
                ':last-of-type': {
                  [theme.direction === 'rtl' ? 'borderLeft' : 'borderRight']:
                    'none',
                },
              })}
              gridClassName={css({
                ':focus': {
                  outline: 'none',
                },
              })}
              className={css({
                backgroundColor: theme.colors.tableBackground,
                display: 'flex',
                flexDirection: 'column',
              })}
              sortBy={sort.column}
              sortDirection={sort.direction}
              sort={({ sortBy, sortDirection }) => {
                setSort({
                  column: sortBy,
                  direction: sortDirection,
                })
                setMachines(
                  machines.sort((a, b) => {
                    const valueA = (a as any)[sortBy]
                    const valueB = (b as any)[sortBy]
                    if (valueA < valueB) {
                      return sortDirection === 'ASC' ? -1 : 1
                    }
                    if (valueA > valueB) {
                      return sortDirection === 'ASC' ? 1 : -1
                    }
                    return 0
                  })
                )
              }}
              noRowsRenderer={() => {
                return (
                  <div
                    className={css({
                      ...theme.typography.ParagraphMedium,
                      color: theme.colors.contentPrimary,
                      textAlign: 'center',
                      paddingTop: theme.sizing.scale800,
                    })}
                  >
                    {t('settings.no_machines')}
                  </div>
                )
              }}
            >
              <Column
                label={t('settings.name')}
                dataKey={'name'}
                width={150}
                flexGrow={1}
                className={bodyCellClass}
                cellRenderer={({ cellData, rowIndex }) => {
                  if (isEditing(rowIndex) && record.current !== undefined) {
                    return (
                      <StatefulInput
                        placeholder={t('settings.name')}
                        initialState={{ value: cellData }}
                        onChange={(e) => {
                          if (record.current !== undefined) {
                            record.current.name = e.currentTarget.value
                          }
                          validate()
                        }}
                        size={'compact'}
                        autoFocus
                        overrides={{
                          InputContainer: {
                            style: {
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: '2px',
                              borderLeftWidth: 0,
                              backgroundColor: 'transparent',
                            },
                          },
                          Input: {
                            style: {
                              paddingRight: 0,
                              paddingLeft: 0,
                            },
                          },
                        }}
                      />
                    )
                  } else {
                    return cellData
                  }
                }}
              />
              <Column
                label={t('settings.connector_type')}
                dataKey={'connector'}
                width={150}
                flexGrow={1}
                className={bodyCellClass}
                cellRenderer={({ cellData, rowIndex }) => {
                  if (isEditing(rowIndex) && record.current !== undefined) {
                    return (
                      <StatefulSelect
                        placeholder={t('settings.connector_type')}
                        options={connnectors}
                        initialState={
                          cellData
                            ? { value: [{ identifier: cellData }] }
                            : undefined
                        }
                        onChange={(e) => {
                          if (record.current !== undefined) {
                            record.current.connector = e.value[0]
                              ?.identifier as string
                            setSelectedConnector(
                              e.value[0]?.identifier as string
                            )
                            validate()
                          }
                        }}
                        labelKey={'name'}
                        valueKey={'identifier'}
                        size={'compact'}
                        clearable={false}
                        searchable={false}
                        overrides={{
                          ControlContainer: {
                            style: {
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: '2px',
                              borderLeftWidth: 0,
                              backgroundColor: 'transparent',
                            },
                          },
                          ValueContainer: {
                            style: {
                              paddingLeft: 0,
                            },
                          },
                          IconsContainer: {
                            style: {
                              paddingRight: 0,
                            },
                          },
                          SingleValue: {
                            style: {
                              marginLeft: 0,
                            },
                          },
                        }}
                      />
                    )
                  } else {
                    return connectorTypeDisplayName(cellData)
                  }
                }}
              />
              <Column
                label={t('settings.connection_string')}
                dataKey={'connectionString'}
                width={150}
                flexGrow={1}
                className={bodyCellClass}
                cellRenderer={({ cellData, rowIndex }) => {
                  if (isEditing(rowIndex) && record.current !== undefined) {
                    return availableConnections.length > 0 ? (
                      <StatefulSelect
                        placeholder={t('settings.connection_string')}
                        options={availableConnections
                          .filter((connection) => {
                            return (
                              !machines.find(({ connectionString }) => {
                                console.log(connectionString)
                                return connectionString === connection
                              }) || cellData === connection
                            )
                          })
                          .map((connectionString) => ({
                            id: connectionString,
                            label: connectionString,
                          }))}
                        initialState={
                          cellData ? { value: [{ id: cellData }] } : undefined
                        }
                        onChange={(e) => {
                          if (record.current !== undefined) {
                            record.current.connectionString = e.value[0]
                              ?.id as string
                            validate()
                          }
                        }}
                        size={'compact'}
                        clearable={false}
                        searchable
                        overrides={{
                          ControlContainer: {
                            style: {
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: '2px',
                              borderLeftWidth: 0,
                              backgroundColor: 'transparent',
                            },
                          },
                          ValueContainer: {
                            style: {
                              paddingLeft: 0,
                            },
                          },
                          IconsContainer: {
                            style: {
                              paddingRight: 0,
                            },
                          },
                          SingleValue: {
                            style: {
                              marginLeft: 0,
                            },
                          },
                        }}
                      />
                    ) : (
                      <StatefulInput
                        placeholder={t('settings.connection_string')}
                        initialState={{ value: cellData }}
                        onChange={(e) => {
                          if (record.current !== undefined) {
                            record.current.connectionString =
                              e.currentTarget.value
                            validate()
                          }
                        }}
                        size={'compact'}
                        overrides={{
                          InputContainer: {
                            style: {
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: '2px',
                              borderLeftWidth: 0,
                              backgroundColor: 'transparent',
                            },
                          },
                          Input: {
                            style: {
                              paddingRight: 0,
                              paddingLeft: 0,
                            },
                          },
                        }}
                      />
                    )
                  } else {
                    return cellData
                  }
                }}
              />
              <Column
                label={t('settings.sampling_rate')}
                dataKey={'readRate'}
                width={150}
                flexGrow={1}
                className={bodyCellClass}
                cellRenderer={({ cellData, rowIndex }) => {
                  if (isEditing(rowIndex) && record.current !== undefined) {
                    return (
                      <StatefulInput
                        inputMode={'number'}
                        placeholder={t('settings.sampling_rate')}
                        initialState={{ value: cellData }}
                        onKeyDown={(e) => {
                          if (
                            e.keyCode >= 32 &&
                            e.keyCode <= 126 &&
                            !e.key.match('[0-9]')
                          ) {
                            e.preventDefault()
                          }
                        }}
                        onChange={(e) => {
                          if (record.current !== undefined) {
                            record.current.readRate = parseInt(
                              e.currentTarget.value
                            )
                            validate()
                          }
                        }}
                        size={'compact'}
                        overrides={{
                          InputContainer: {
                            style: {
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: '2px',
                              borderLeftWidth: 0,
                              backgroundColor: 'transparent',
                            },
                          },
                          Input: {
                            style: {
                              paddingRight: 0,
                              paddingLeft: 0,
                            },
                          },
                        }}
                      />
                    )
                  } else {
                    return `${cellData} ms`
                  }
                }}
              />
              <Column
                label={t('settings.active')}
                dataKey={'active'}
                width={70}
                cellRenderer={({ cellData, rowIndex }) => {
                  if (isEditing(rowIndex) && record.current !== undefined) {
                    return (
                      <StatefulCheckbox
                        initialState={{
                          checked: cellData,
                        }}
                        onChange={(e) => {
                          if (record.current !== undefined) {
                            record.current.active = e.currentTarget.checked
                          }
                        }}
                      />
                    )
                  } else {
                    return cellData ? t('yes') : t('no')
                  }
                }}
                className={bodyCellClass}
              />
              <Column
                disableSort
                dataKey={'action'}
                width={60}
                className={bodyCellClass}
                style={{
                  textAlign: 'center',
                }}
                cellRenderer={({ rowIndex }) => {
                  if (!editing || isEditing(rowIndex)) {
                    return (
                      <ButtonGroup
                        size={'mini'}
                        kind={KIND.tertiary}
                        overrides={{
                          Root: {
                            style: {
                              justifyContent: 'end',
                            },
                          },
                        }}
                      >
                        <Button
                          disabled={!valid && isEditing(rowIndex)}
                          overrides={{
                            BaseButton: {
                              style: ({ $theme }) => ({
                                ':hover': {
                                  backgroundColor: 'transparent',
                                  color: $theme.colors.linkHover,
                                },
                                ':focus': {
                                  backgroundColor: 'transparent',
                                },
                                ':active': {
                                  backgroundColor: 'transparent',
                                  color: $theme.colors.linkActive,
                                },
                                ':disabled': {
                                  backgroundColor: 'transparent',
                                },
                              }),
                            },
                          }}
                          onClick={() => {
                            if (isEditing(rowIndex)) {
                              commitEdit()
                            } else {
                              startEdit({ ...machines[rowIndex] })
                            }
                          }}
                        >
                          <FontAwesomeIcon
                            size={'lg'}
                            icon={isEditing(rowIndex) ? faCheck : faPen}
                          />
                        </Button>
                        <Button
                          overrides={{
                            BaseButton: {
                              style: ({ $theme }) => ({
                                ':hover': {
                                  backgroundColor: 'transparent',
                                  color: $theme.colors.linkHover,
                                },
                                ':focus': {
                                  backgroundColor: 'transparent',
                                },
                                ':active': {
                                  backgroundColor: 'transparent',
                                  color: $theme.colors.linkActive,
                                },
                                ':disabled': {
                                  backgroundColor: 'transparent',
                                },
                              }),
                            },
                          }}
                          onClick={() => {
                            if (isEditing(rowIndex)) {
                              cancelEdit()
                            } else {
                              deleteMachine(machines[rowIndex])
                            }
                          }}
                        >
                          <FontAwesomeIcon
                            size={'lg'}
                            icon={isEditing(rowIndex) ? faTimes : faTrash}
                          />
                        </Button>
                      </ButtonGroup>
                    )
                  } else {
                    return null
                  }
                }}
              />
            </Table>
          )}
        </AutoSizer>
      </Block>
    </Block>
  )
}

export { Machines }
