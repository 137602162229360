"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * datAIndustry
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AxisType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var AxisType;
(function (AxisType) {
    AxisType["PRIMARY"] = "PRIMARY";
    AxisType["LEFT2"] = "LEFT2";
    AxisType["LEFT3"] = "LEFT3";
    AxisType["LEFT4"] = "LEFT4";
    AxisType["SECONDARY"] = "SECONDARY";
    AxisType["RIGHT2"] = "RIGHT2";
    AxisType["RIGHT3"] = "RIGHT3";
    AxisType["RIGHT4"] = "RIGHT4";
    AxisType["NONE"] = "NONE";
})(AxisType = exports.AxisType || (exports.AxisType = {}));
