"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * datAIndustry
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectorsApi = exports.ConnectorsApiFactory = exports.ConnectorsApiFp = exports.ConnectorsApiAxiosParamCreator = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("../common");
// @ts-ignore
var base_1 = require("../base");
/**
 * ConnectorsApi - axios parameter creator
 * @export
 */
exports.ConnectorsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Browse available connections
         * @param {string} connectorId The identifier of the connector
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        browseConnections: function (connectorId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'connectorId' is not null or undefined
                            common_1.assertParamExists('browseConnections', 'connectorId', connectorId);
                            localVarPath = "/connectors/{connectorId}/browse"
                                .replace("{" + "connectorId" + "}", encodeURIComponent(String(connectorId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication BearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication BearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get the configuration
         * @param {string} connectorId The identifier of the connector
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProperties: function (connectorId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'connectorId' is not null or undefined
                            common_1.assertParamExists('getProperties', 'connectorId', connectorId);
                            localVarPath = "/connectors/{connectorId}/properties"
                                .replace("{" + "connectorId" + "}", encodeURIComponent(String(connectorId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication BearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication BearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary List all connectors
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectors: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/connectors";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication BearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication BearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Start the connector
         * @param {string} connectorId The identifier of the connector
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        start: function (connectorId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'connectorId' is not null or undefined
                            common_1.assertParamExists('start', 'connectorId', connectorId);
                            localVarPath = "/connectors/{connectorId}/start"
                                .replace("{" + "connectorId" + "}", encodeURIComponent(String(connectorId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication BearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication BearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Stop the connector
         * @param {string} connectorId The identifier of the connector
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stop: function (connectorId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'connectorId' is not null or undefined
                            common_1.assertParamExists('stop', 'connectorId', connectorId);
                            localVarPath = "/connectors/{connectorId}/stop"
                                .replace("{" + "connectorId" + "}", encodeURIComponent(String(connectorId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication BearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication BearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update the configuration
         * @param {string} connectorId The identifier of the connector
         * @param {{ [key: string]: string; }} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProperties: function (connectorId, requestBody, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'connectorId' is not null or undefined
                            common_1.assertParamExists('updateProperties', 'connectorId', connectorId);
                            // verify required parameter 'requestBody' is not null or undefined
                            common_1.assertParamExists('updateProperties', 'requestBody', requestBody);
                            localVarPath = "/connectors/{connectorId}/properties"
                                .replace("{" + "connectorId" + "}", encodeURIComponent(String(connectorId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication BearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication BearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * ConnectorsApi - functional programming interface
 * @export
 */
exports.ConnectorsApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.ConnectorsApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Browse available connections
         * @param {string} connectorId The identifier of the connector
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        browseConnections: function (connectorId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.browseConnections(connectorId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get the configuration
         * @param {string} connectorId The identifier of the connector
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProperties: function (connectorId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getProperties(connectorId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary List all connectors
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectors: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listConnectors(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Start the connector
         * @param {string} connectorId The identifier of the connector
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        start: function (connectorId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.start(connectorId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Stop the connector
         * @param {string} connectorId The identifier of the connector
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stop: function (connectorId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.stop(connectorId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Update the configuration
         * @param {string} connectorId The identifier of the connector
         * @param {{ [key: string]: string; }} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProperties: function (connectorId, requestBody, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateProperties(connectorId, requestBody, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * ConnectorsApi - factory interface
 * @export
 */
exports.ConnectorsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.ConnectorsApiFp(configuration);
    return {
        /**
         *
         * @summary Browse available connections
         * @param {string} connectorId The identifier of the connector
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        browseConnections: function (connectorId, options) {
            return localVarFp.browseConnections(connectorId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get the configuration
         * @param {string} connectorId The identifier of the connector
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProperties: function (connectorId, options) {
            return localVarFp.getProperties(connectorId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary List all connectors
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectors: function (options) {
            return localVarFp.listConnectors(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Start the connector
         * @param {string} connectorId The identifier of the connector
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        start: function (connectorId, options) {
            return localVarFp.start(connectorId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Stop the connector
         * @param {string} connectorId The identifier of the connector
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stop: function (connectorId, options) {
            return localVarFp.stop(connectorId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update the configuration
         * @param {string} connectorId The identifier of the connector
         * @param {{ [key: string]: string; }} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProperties: function (connectorId, requestBody, options) {
            return localVarFp.updateProperties(connectorId, requestBody, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * ConnectorsApi - object-oriented interface
 * @export
 * @class ConnectorsApi
 * @extends {BaseAPI}
 */
var ConnectorsApi = /** @class */ (function (_super) {
    __extends(ConnectorsApi, _super);
    function ConnectorsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Browse available connections
     * @param {string} connectorId The identifier of the connector
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorsApi
     */
    ConnectorsApi.prototype.browseConnections = function (connectorId, options) {
        var _this = this;
        return exports.ConnectorsApiFp(this.configuration).browseConnections(connectorId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get the configuration
     * @param {string} connectorId The identifier of the connector
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorsApi
     */
    ConnectorsApi.prototype.getProperties = function (connectorId, options) {
        var _this = this;
        return exports.ConnectorsApiFp(this.configuration).getProperties(connectorId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary List all connectors
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorsApi
     */
    ConnectorsApi.prototype.listConnectors = function (options) {
        var _this = this;
        return exports.ConnectorsApiFp(this.configuration).listConnectors(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Start the connector
     * @param {string} connectorId The identifier of the connector
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorsApi
     */
    ConnectorsApi.prototype.start = function (connectorId, options) {
        var _this = this;
        return exports.ConnectorsApiFp(this.configuration).start(connectorId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Stop the connector
     * @param {string} connectorId The identifier of the connector
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorsApi
     */
    ConnectorsApi.prototype.stop = function (connectorId, options) {
        var _this = this;
        return exports.ConnectorsApiFp(this.configuration).stop(connectorId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update the configuration
     * @param {string} connectorId The identifier of the connector
     * @param {{ [key: string]: string; }} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorsApi
     */
    ConnectorsApi.prototype.updateProperties = function (connectorId, requestBody, options) {
        var _this = this;
        return exports.ConnectorsApiFp(this.configuration).updateProperties(connectorId, requestBody, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ConnectorsApi;
}(base_1.BaseAPI));
exports.ConnectorsApi = ConnectorsApi;
