import iro from '@jaames/iro'
import { IroColorPicker } from '@jaames/iro/dist/ColorPicker'
import { useStyletron } from 'baseui'
import { Block } from 'baseui/block'
import chroma from 'chroma-js'
import React, { useEffect, useRef } from 'react'

const swatches = chroma
  .scale(['#3498db', '#2ecc71', '#f1c40f', '#e74c3c', '#2c3e50'])
  .mode('hsv')
  .colors(16)

const ColorPicker: React.FC<{
  value: string
  onChange: (color: string) => void
  onFinalChange: (color: string) => void
}> = React.memo(({ value, onChange, onFinalChange }) => {
  const [css, theme] = useStyletron()
  const colorPicker = useRef<IroColorPicker | null>(null)
  const el = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!el.current) {
      return
    }
    if (!colorPicker.current) {
      colorPicker.current = iro.ColorPicker(el.current, {
        width: 264,
        color: value,
        handleSvg: '#handle',
      })
      if (!colorPicker.current) {
        return
      }
      colorPicker.current.on('input:end', (color: { hexString: string }) => {
        onFinalChange(color.hexString)
      })
    } else if (value !== colorPicker.current.color.hexString) {
      colorPicker.current.color.set(value)
    }
  }, [onChange, onFinalChange, value])

  return (
    <Block
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      flexWrap={true}
      backgroundColor={'backgroundSecondary'}
    >
      <div>
        <div
          className={css({
            paddingLeft: '16px',
            paddingRight: '16px',
            paddingTop: '16px',
          })}
          ref={el}
        >
          <svg display={'none'}>
            <defs>
              <g id='handle'>
                <circle
                  r='8'
                  fill='none'
                  strokeWidth='2.5'
                  stroke={theme.colors.contentOnColor}
                ></circle>
              </g>
            </defs>
          </svg>
        </div>
        <table
          className={css({
            borderSpacing: theme.sizing.scale300,
            paddingTop: theme.sizing.scale300,
            paddingBottom: theme.sizing.scale300,
            paddingLeft: theme.sizing.scale300,
            paddingRight: theme.sizing.scale300,
          })}
        >
          <tbody>
            {Array.from(Array(2).keys()).map((_, i) => {
              return (
                <tr key={i}>
                  {Array.from(Array(8).keys()).map((_, j) => {
                    return (
                      <td
                        key={j}
                        className={css({
                          textAlign: 'center',
                          verticalAlign: 'middle',
                          width: theme.sizing.scale800,
                          height: theme.sizing.scale800,
                          backgroundColor: swatches[j + i * 8],
                          cursor: 'pointer',
                        })}
                        onClick={() => {
                          onFinalChange(swatches[j + i * 8])
                        }}
                      ></td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </Block>
  )
})

export { ColorPicker }
