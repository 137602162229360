import {
  faCheck,
  faPen,
  faTimes,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStyletron } from 'baseui'
import { Block } from 'baseui/block'
import { Breadcrumbs } from 'baseui/breadcrumbs'
import { Button, KIND } from 'baseui/button'
import { ButtonGroup } from 'baseui/button-group'
import { StatefulCheckbox } from 'baseui/checkbox'
import { StatefulInput } from 'baseui/input'
import { ProgressBar } from 'baseui/progress-bar'
import { ChannelTemplate } from 'client/dist/models'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'react-oidc-context'
import { AutoSizer, Column, SortDirectionType, Table } from 'react-virtualized'

import { useApi } from '../../ApiProvider'
const Templates: React.FC = () => {
  const [css, theme] = useStyletron()
  const [templates, setTemplates] = useState<ChannelTemplate[]>([])
  const [loading, setLoading] = useState(false)
  const [editing, setEditing] = useState(false)
  const [valid, setValid] = useState(true)
  const [sort, setSort] = useState<{
    column: string
    direction: SortDirectionType
  }>({
    column: 'name',
    direction: 'ASC',
  })
  const record = useRef<ChannelTemplate>()
  const [t] = useTranslation()
  const [api] = useApi()
  const auth = useAuth()

  const updateTemplate = useCallback(
    (template: ChannelTemplate) => {
      if (template.id !== undefined) {
        api.channelTemplatesApi
          .updateTemplate(template.id, template)
          .then((result) => {
            setTemplates(
              templates.map(({ id }, index) => {
                if (id === template.id) {
                  return { ...result.data, isNew: false }
                } else {
                  return templates[index]
                }
              })
            )
            record.current = undefined
            setEditing(false)
          })
      }
    },
    [api.channelTemplatesApi, templates]
  )

  const deleteTemplate = useCallback(
    (template: ChannelTemplate) => {
      if (template.id !== undefined) {
        api.channelTemplatesApi.deleteTemplate(template.id).then(() => {
          setTemplates(templates.filter(({ id }) => id !== template.id))
        })
      }
    },
    [api.channelTemplatesApi, templates]
  )

  const startEdit = useCallback((template: ChannelTemplate) => {
    record.current = template
    setEditing(true)
  }, [])

  const cancelEdit = useCallback(() => {
    record.current = undefined
    setEditing(false)
    if (!valid) {
      setValid(true)
    }
  }, [valid])

  const commitEdit = useCallback(() => {
    if (editing && record.current !== undefined) {
      updateTemplate(record.current)
    }
  }, [editing, updateTemplate])

  const isEditing = useCallback(
    (rowIndex: number) => {
      return (
        editing &&
        record.current !== undefined &&
        record.current.id === templates[rowIndex].id
      )
    },
    [editing, templates]
  )

  useEffect(() => {
    setLoading(true)
    api.channelTemplatesApi
      .listTemplates()
      .then((result) => {
        setTemplates(
          result.data.filter(({ user }) => {
            if (auth.user !== undefined) {
              return user === auth.user?.profile.sub
            } else {
              return true
            }
          })
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }, [api.channelTemplatesApi, auth.user])

  const bodyCellClass = useMemo(
    () =>
      css({
        ...theme.typography.ParagraphSmall,
        ...theme.borders.border300,
        borderTop: 'none',
        borderBottom: 'none',
        [theme.direction === 'rtl' ? 'borderRight' : 'borderLeft']: 'none',
        borderColor: 'transparent',
        color: theme.colors.contentPrimary,
        paddingTop: theme.sizing.scale300,
        paddingRight: theme.sizing.scale600,
        paddingBottom: theme.sizing.scale300,
        paddingLeft: theme.sizing.scale600,
        ':last-of-type': {
          [theme.direction === 'rtl' ? 'borderLeft' : 'borderRight']: 'none',
        },
      }),
    [css, theme]
  )

  return (
    <Block flex={'auto'} display={'flex'} flexDirection={'column'}>
      <Block
        paddingTop={'scale600'}
        paddingRight={'scale600'}
        paddingLeft={'scale600'}
      >
        <Breadcrumbs
          overrides={{
            Root: {
              style: ({ $theme }) => ({
                paddingBottom: $theme.sizing.scale600,
              }),
            },
          }}
        >
          <span>{t('settings.settings')}</span>
          <span>{t('settings.templates')}</span>
        </Breadcrumbs>
      </Block>
      <Block flex={'auto'}>
        <AutoSizer>
          {({ width, height }) => (
            <Table
              height={height}
              width={width}
              headerHeight={44}
              rowHeight={36}
              rowCount={templates.length}
              rowGetter={({ index }) => {
                return templates[index]
              }}
              rowClassName={({ index }) => {
                if (index === -1) {
                  return css({
                    backgroundColor: theme.colors.tableHeadBackgroundColor,
                    boxShadow: theme.lighting.shadow400,
                    display: 'flex',
                    alignItems: 'center',
                  })
                } else {
                  return css({
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: isEditing(index)
                      ? theme.colors.backgroundTertiary
                      : 'inherit',
                  })
                }
              }}
              headerRowRenderer={({ className, columns, style }) => {
                return (
                  <>
                    <div className={className} role='row' style={style}>
                      {columns}
                    </div>
                    {loading && (
                      <ProgressBar
                        infinite
                        overrides={{
                          Bar: {
                            style: {
                              position: 'absolute',
                              width: `${style.width}px`,
                              marginBottom: 0,
                              marginLeft: 0,
                              marginRight: 0,
                              marginTop: 0,
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                            },
                          },
                          BarProgress: {
                            style: {
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                            },
                          },
                        }}
                      />
                    )}
                  </>
                )
              }}
              headerClassName={css({
                ...theme.typography.LabelMedium,
                ...theme.borders.border300,
                borderTop: 'none',
                borderBottom: 'none',
                [theme.direction === 'rtl' ? 'borderRight' : 'borderLeft']:
                  'none',
                color: theme.colors.contentPrimary,
                paddingTop: theme.sizing.scale500,
                paddingRight: theme.sizing.scale600,
                paddingBottom: theme.sizing.scale500,
                paddingLeft: theme.sizing.scale600,
                ':focus': {
                  outline: 'none',
                },
                ':nth-last-of-type(2)': {
                  [theme.direction === 'rtl' ? 'borderLeft' : 'borderRight']:
                    'none',
                },
                ':last-of-type': {
                  [theme.direction === 'rtl' ? 'borderLeft' : 'borderRight']:
                    'none',
                },
              })}
              gridClassName={css({
                ':focus': {
                  outline: 'none',
                },
              })}
              className={css({
                backgroundColor: theme.colors.tableBackground,
                display: 'flex',
                flexDirection: 'column',
              })}
              sortBy={sort.column}
              sortDirection={sort.direction}
              sort={({ sortBy, sortDirection }) => {
                setSort({
                  column: sortBy,
                  direction: sortDirection,
                })
                setTemplates(
                  templates.sort((a, b) => {
                    const valueA = (a as any)[sortBy]
                    const valueB = (b as any)[sortBy]
                    if (valueA < valueB) {
                      return sortDirection === 'ASC' ? -1 : 1
                    }
                    if (valueA > valueB) {
                      return sortDirection === 'ASC' ? 1 : -1
                    }
                    return 0
                  })
                )
              }}
              noRowsRenderer={() => {
                return (
                  <div
                    className={css({
                      ...theme.typography.ParagraphMedium,
                      color: theme.colors.contentPrimary,
                      textAlign: 'center',
                      paddingTop: theme.sizing.scale800,
                    })}
                  >
                    {t('settings.no_templates')}
                  </div>
                )
              }}
            >
              <Column
                label={t('settings.name')}
                dataKey={'name'}
                width={250}
                flexGrow={1}
                className={bodyCellClass}
                cellRenderer={({ cellData, rowIndex }) => {
                  if (isEditing(rowIndex) && record.current !== undefined) {
                    return (
                      <StatefulInput
                        placeholder={t('settings.name')}
                        initialState={{ value: cellData }}
                        onChange={(e) => {
                          if (record.current !== undefined) {
                            record.current.name = e.currentTarget.value
                          }
                        }}
                        size={'compact'}
                        autoFocus={true}
                        overrides={{
                          InputContainer: {
                            style: {
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: 'transparent',
                            },
                          },
                          Input: {
                            style: {
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                            },
                          },
                        }}
                      />
                    )
                  } else {
                    return cellData
                  }
                }}
              />
              {auth.user !== undefined && (
                <Column
                  label={t('settings.shared')}
                  dataKey={'shared'}
                  width={70}
                  className={bodyCellClass}
                  cellRenderer={({ cellData, rowIndex }) => {
                    if (isEditing(rowIndex) && record.current !== undefined) {
                      return (
                        <StatefulCheckbox
                          initialState={{
                            checked: cellData,
                          }}
                          onChange={(e) => {
                            if (record.current !== undefined) {
                              record.current.shared = e.currentTarget.checked
                            }
                          }}
                        />
                      )
                    } else {
                      return cellData ? t('yes') : t('no')
                    }
                  }}
                />
              )}
              <Column
                disableSort
                dataKey={'action'}
                width={60}
                className={bodyCellClass}
                style={{
                  textAlign: 'center',
                }}
                cellRenderer={({ rowIndex, rowData }) => {
                  if (!editing || isEditing(rowIndex)) {
                    return (
                      <ButtonGroup size={'mini'} kind={KIND.tertiary}>
                        <Button
                          disabled={!valid}
                          overrides={{
                            BaseButton: {
                              style: ({ $theme }) => ({
                                ':hover': {
                                  backgroundColor: 'transparent',
                                  color: $theme.colors.linkHover,
                                },
                                ':focus': {
                                  backgroundColor: 'transparent',
                                },
                                ':active': {
                                  backgroundColor: 'transparent',
                                  color: $theme.colors.linkActive,
                                },
                                ':disabled': {
                                  backgroundColor: 'transparent',
                                },
                              }),
                            },
                          }}
                          onClick={() => {
                            if (isEditing(rowIndex)) {
                              commitEdit()
                            } else {
                              startEdit({ ...templates[rowIndex] })
                            }
                          }}
                        >
                          <FontAwesomeIcon
                            size={'lg'}
                            icon={isEditing(rowIndex) ? faCheck : faPen}
                          />
                        </Button>
                        <Button
                          overrides={{
                            BaseButton: {
                              style: ({ $theme }) => ({
                                ':hover': {
                                  backgroundColor: 'transparent',
                                  color: $theme.colors.linkHover,
                                },
                                ':focus': {
                                  backgroundColor: 'transparent',
                                },
                                ':active': {
                                  backgroundColor: 'transparent',
                                  color: $theme.colors.linkActive,
                                },
                                ':disabled': {
                                  backgroundColor: 'transparent',
                                },
                              }),
                            },
                          }}
                          onClick={() => {
                            if (isEditing(rowIndex)) {
                              cancelEdit()
                            } else {
                              deleteTemplate(templates[rowIndex])
                            }
                          }}
                        >
                          <FontAwesomeIcon
                            size={'lg'}
                            icon={isEditing(rowIndex) ? faTimes : faTrash}
                          />
                        </Button>
                      </ButtonGroup>
                    )
                  } else {
                    return null
                  }
                }}
              />
            </Table>
          )}
        </AutoSizer>
      </Block>
    </Block>
  )
}

export { Templates }
