import 'react-app-polyfill/stable'
import 'fast-text-encoding'
import './index.css'
import './i18n'

import axios from 'axios'
import { Configuration } from 'client'
import React, { ReactNode, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { AuthContext, AuthContextProps, AuthProvider } from 'react-oidc-context'
import { HashRouter as Router } from 'react-router-dom'
import SockJS from 'sockjs-client'

import { ApiProvider, getUser } from './ApiProvider'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { StompProvider } from './StompProvider'

type OidcConfigT = {
  authority: string
  client_id: string
}

let status = 'pending'
let result: OidcConfigT | undefined

const fetchOidcConfig = () => {
  const fetching = axios
    .get(`${window.env.APP_BASE_PATH}/actuator/features`)
    .then((response) => {
      if (response.data.authentication?.enabled === true) {
        return axios.get<OidcConfigT>(
          `${window.env.APP_BASE_PATH}/keycloak.json`
        )
      } else {
        return undefined
      }
    })
    .then((response) => {
      return response?.data
    })
    .then((success) => {
      status = 'fulfilled'
      result = success
    })
    .catch((e) => {
      status = 'rejected'
      result = e
    })

  return () => {
    if (status === 'pending') {
      throw fetching
    } else if (status === 'rejected') {
      throw result
    } else if (status === 'fulfilled') {
      return result
    }
  }
}

const oidcConfigData = fetchOidcConfig()

const AuthWrapper: React.FC<{
  children: (oidcConfig?: OidcConfigT) => ReactNode
}> = ({ children }) => {
  const oidcConfig = oidcConfigData()
  if (oidcConfig !== undefined) {
    return (
      <AuthProvider
        {...oidcConfig}
        redirect_uri={window.location.href}
        onSigninCallback={(user) => {
          window.history.replaceState(
            {},
            document.title,
            window.location.pathname + window.location.hash
          )
        }}
      >
        {children(oidcConfig)}
      </AuthProvider>
    )
  } else {
    return (
      <AuthContext.Provider
        value={
          {
            isAuthenticated: true,
            clearStaleState: () => Promise.resolve(),
            removeUser: () => Promise.resolve(),
            signinPopup: () => Promise.reject(),
            signinSilent: () => Promise.resolve(null),
            signinRedirect: () => Promise.resolve(),
            signoutRedirect: () => Promise.resolve(),
            signoutPopup: () => Promise.resolve(),
            signoutSilent: () => Promise.resolve(),
            querySessionStatus: () => Promise.resolve(null),
            revokeTokens: async () => Promise.resolve(),
            startSilentRenew: () => {
              // Not used
            },
            stopSilentRenew: () => {
              // Not used
            },
          } as AuthContextProps
        }
      >
        {children(oidcConfig)}
      </AuthContext.Provider>
    )
  }
}

const AppWrapper: React.FC = () => {
  return (
    <AuthWrapper>
      {(oidcConfig) => (
        <ApiProvider
          configuration={
            new Configuration({
              basePath: `${window.env.APP_BASE_PATH}/api/v1`,
              accessToken:
                oidcConfig !== undefined
                  ? () => {
                      const user = getUser(
                        oidcConfig.authority,
                        oidcConfig.client_id
                      )
                      return user?.access_token || ''
                    }
                  : undefined,
            })
          }
        >
          <StompProvider
            configuration={{
              webSocketFactory: () =>
                new SockJS(`${window.env.APP_BASE_PATH}/ws`, undefined, {
                  transports: ['xhr-polling', 'xhr-streaming'],
                }),
            }}
          >
            <Router>
              <App />
            </Router>
          </StompProvider>
        </ApiProvider>
      )}
    </AuthWrapper>
  )
}

ReactDOM.render(
  <Suspense fallback={<div>Loading OIDC configuration ...</div>}>
    <AppWrapper />
  </Suspense>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
