import './Export.css'

import { styled, useStyletron } from 'baseui'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import StepWizard, { StepWizardChildProps } from 'react-step-wizard'

import { useApi } from '../ApiProvider'
import { Step1 as ExportStep1 } from './export/Step1'
import { Step2 as ExportStep2 } from './export/Step2'
import { Step3 as ExportStep3 } from './export/Step3'
import { Step4 as ExportStep4 } from './export/Step4'

const StyledStep = styled<{ $active?: boolean; $completed?: boolean }, 'li'>(
  'li',
  ({ $theme, $active, $completed }) => ({
    ...$theme.typography.LabelMedium,
    position: 'relative',
    display: 'table-cell',
    textAlign: 'center',
    paddingTop: $theme.sizing.scale600,
    paddingBottom: $theme.sizing.scale600,
    ':before': {
      content: 'attr(data-step)',
      display: 'block',
      marginTop: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: $theme.sizing.scale200,
      color: $theme.colors.white,
      backgroundColor: $completed
        ? 'rgb(120,186,60)'
        : $active
        ? $theme.colors.accent500
        : $theme.colors.backgroundInverseSecondary,
      width: $theme.sizing.scale1000,
      height: $theme.sizing.scale1000,
      lineHeight: $theme.sizing.scale1000,
      borderRadius: '100%',
      position: 'relative',
    },
    ':after': {
      content: '""',
      position: 'absolute',
      display: 'block',
      backgroundColor: $completed
        ? 'rgb(120,186,60)'
        : $theme.colors.backgroundInverseSecondary,
      width: 'calc(100% - 60px)',
      height: $theme.sizing.scale200,
      top: `calc((${$theme.sizing.scale1000} / 2) - (${$theme.sizing.scale200} / 2) + ${$theme.sizing.scale600})`,
      left: 'calc(50% + 30px)',
    },
    ':last-child:after': {
      display: 'none',
    },
  })
)

const ProgressBar: React.FC<
  Partial<StepWizardChildProps> & {
    completed: boolean
  }
> = ({ currentStep, completed }) => {
  const [css, theme] = useStyletron()
  const [t] = useTranslation()
  return (
    <ol
      className={css({
        listStyle: 'none',
        margin: 0,
        padding: 0,
        display: 'table',
        tableLayout: 'fixed',
        width: '100%',
        color: theme.colors.contentPrimary,
      })}
    >
      <StyledStep
        data-step={'1'}
        $active={currentStep === 1}
        $completed={currentStep !== undefined && currentStep > 1}
      >
        {t('export.machine')}
      </StyledStep>
      <StyledStep
        data-step={'2'}
        $active={currentStep === 2}
        $completed={currentStep !== undefined && currentStep > 2}
      >
        {t('export.date_range')}
      </StyledStep>
      <StyledStep
        data-step={'3'}
        $active={currentStep === 3}
        $completed={currentStep !== undefined && currentStep > 3}
      >
        {t('export.parameters')}
      </StyledStep>
      <StyledStep
        data-step={'4'}
        $active={currentStep === 4}
        $completed={completed}
      >
        {t('export.export')}
      </StyledStep>
    </ol>
  )
}

const Export: React.FC = () => {
  const [css] = useStyletron()
  const [selectedMachine, setSelectedMachine] = useState<number>()
  const [selectedDateRange, setSelectedDateRange] = useState<
    [Date, Date] | undefined
  >()
  const [selectedParameters, setSelectedParameters] = useState<number[]>([])
  const [completed, setCompleted] = useState(false)
  const [api] = useApi()

  return (
    <StepWizard
      nav={<ProgressBar completed={completed} />}
      className={css({
        display: 'flex',
        flexDirection: 'column',
        flex: 'auto',
      })}
      transitions={{}}
      isLazyMount={true}
      onStepChange={({ previousStep, activeStep }) => {
        if (activeStep < previousStep) {
          setCompleted(false)
        }
      }}
    >
      <ExportStep1
        selection={selectedMachine}
        onChange={useCallback(
          (selection) => {
            if (selection === undefined) {
              setSelectedParameters([])
            } else if (selection !== selectedMachine) {
              api.parametersApi.listParameters(selection).then((result) => {
                setSelectedParameters(
                  result.data
                    .filter(({ exportEnabled }) => exportEnabled)
                    .map(({ id }) => id!)
                )
              })
            }
            setSelectedMachine(selection)
          },
          [api.parametersApi, selectedMachine]
        )}
      />
      <ExportStep2
        machineId={selectedMachine}
        onDateChange={useCallback((dates) => {
          setSelectedDateRange(dates)
        }, [])}
      />
      <ExportStep3
        selection={selectedParameters}
        machineId={selectedMachine}
        dateRange={selectedDateRange}
        onChange={useCallback((selection) => {
          setSelectedParameters(selection)
        }, [])}
      />
      <ExportStep4
        machineId={selectedMachine as number}
        dateRange={selectedDateRange}
        parameters={selectedParameters}
        onFinished={useCallback(() => {
          setCompleted(true)
        }, [])}
      />
    </StepWizard>
  )
}

export { Export }
