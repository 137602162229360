import { styled } from 'baseui'
import { Avatar } from 'baseui/avatar'
import { ChevronDown, ChevronUp } from 'baseui/icon'
import {
  ARTWORK_SIZES,
  ListItem,
  ListItemLabel,
  MenuAdapter,
} from 'baseui/list'
import { StatefulMenu, StyledList } from 'baseui/menu'
import { PLACEMENT, StatefulPopover } from 'baseui/popover'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'react-oidc-context'

import { themedUseStyletron as useStyletron } from '../../themes'

const StyledButton = styled<{ $isFocusVisible: boolean }, 'button'>(
  'button',
  ({ $theme, $isFocusVisible }) => ({
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    color: $theme.colors.contentPrimary,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    paddingTop: '0',
    paddingBottom: '0',
    paddingLeft: '0',
    paddingRight: '0',
    marginLeft: 0,
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    outline: $isFocusVisible ? `3px solid ${$theme.colors.accent}` : 'none',
    outlineOffset: '-3px',
    WebkitAppearance: 'none',
    cursor: 'pointer',
  })
)

export const UserMenu = () => {
  const auth = useAuth()
  const [t] = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [, theme] = useStyletron()

  return (
    <StatefulPopover
      placement={PLACEMENT.bottomRight}
      content={({ close }) => (
        <StatefulMenu
          items={[
            {
              title: t('navigation.logout'),
              action: 'logout',
            },
          ]}
          onItemSelect={({ item }) => {
            if (item.action === 'logout') {
              auth.signoutRedirect({
                post_logout_redirect_uri: window.location.href,
              })
            }
            close()
          }}
          overrides={{
            List: {
              component: React.forwardRef(({ children, ...restProps }, ref) => (
                <StyledList {...restProps} ref={ref}>
                  <ListItem
                    overrides={{
                      Root: {
                        style: ({ $theme }) => ({
                          backgroundColor: $theme.colors.menuFill,
                        }),
                      },
                      Content: {
                        style: {
                          borderBottomStyle: 'none',
                        },
                      },
                      ArtworkContainer: {
                        style: ({ $theme }) => ({
                          width: $theme.sizing.scale1200,
                          height: $theme.sizing.scale1200,
                          marginRight: $theme.sizing.scale600,
                          paddingLeft: $theme.sizing.scale600,
                        }),
                      },
                    }}
                    artwork={() => (
                      <Avatar
                        name={auth.user?.profile.name ?? 'Unknown User'}
                        overrides={{
                          Root: {
                            style: ({ $theme }) => ({
                              width: $theme.sizing.scale1200,
                              height: $theme.sizing.scale1200,
                              backgroundColor: theme.avatarBackgroundColor,
                            }),
                          },
                          Initials: {
                            style: ({ $theme }) => ({
                              color: theme.avatarInitialsColor,
                            }),
                          },
                        }}
                      />
                    )}
                  >
                    <ListItemLabel description={auth.user?.profile.email}>
                      {auth.user?.profile.name}
                    </ListItemLabel>
                  </ListItem>
                  {children}
                </StyledList>
              )),
              style: { width: '275px' },
            },
            Option: {
              props: {
                overrides: {
                  ListItem: {
                    component: React.forwardRef((props: any, ref) => (
                      <MenuAdapter
                        {...props}
                        ref={ref}
                        artwork={props.item.icon}
                        artworkSize={ARTWORK_SIZES.LARGE}
                      >
                        <ListItemLabel description={props.item.subtitle}>
                          {props.item.title}
                        </ListItemLabel>
                      </MenuAdapter>
                    )),
                  },
                },
              },
            },
          }}
        />
      )}
      onOpen={() => {
        setIsOpen(true)
      }}
      onClose={() => {
        setIsOpen(false)
      }}
    >
      <StyledButton $isFocusVisible={false}>
        <Avatar
          name={auth.user?.profile.name ?? 'Unknown User'}
          size={'scale1000'}
          overrides={{
            Root: {
              style: {
                backgroundColor: theme.avatarBackgroundColor,
              },
            },
            Initials: {
              style: ({ $theme }) => ({
                color: theme.avatarInitialsColor,
              }),
            },
          }}
        />
        {!isOpen ? (
          <ChevronDown
            size={'scale800'}
            overrides={{
              Svg: {
                style: ({ $theme }) => ({
                  paddingLeft: $theme.sizing.scale200,
                }),
              },
            }}
          />
        ) : (
          <ChevronUp
            size={'scale800'}
            overrides={{
              Svg: {
                style: ({ $theme }) => ({
                  paddingLeft: $theme.sizing.scale200,
                }),
              },
            }}
          />
        )}
      </StyledButton>
    </StatefulPopover>
  )
}
