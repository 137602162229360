import { StatefulCheckbox } from 'baseui/checkbox'
import { FormControl } from 'baseui/form-control'
import { StatefulInput } from 'baseui/input'
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from 'baseui/modal'
import { toaster } from 'baseui/toast'
import { Axis, Channel, ChannelTemplate } from 'client'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'react-oidc-context'

import { useApi } from '../../ApiProvider'

const SaveDialog: React.FC<{
  isOpen: boolean
  machine?: number
  channels: Channel[]
  axes: Axis[]
  onClose: (template?: ChannelTemplate) => void
}> = ({ isOpen, machine, channels, axes, onClose }) => {
  const [name, setName] = useState('')
  const [shared, setShared] = useState(false)
  const [error, setError] = useState<string>()
  const [t] = useTranslation()
  const [api] = useApi()
  const auth = useAuth()

  return (
    <Modal isOpen={isOpen} closeable={false} unstable_ModalBackdropScroll>
      <ModalHeader>{t('template.save')}</ModalHeader>
      <ModalBody>
        <FormControl error={error !== undefined && error}>
          <StatefulInput
            placeholder={t('template.name')}
            onChange={(e) => {
              setName(e.currentTarget.value)
            }}
          />
        </FormControl>
        {auth.user !== undefined && (
          <FormControl>
            <StatefulCheckbox
              onChange={(e) => {
                setShared(e.currentTarget.checked)
              }}
            >
              {t('settings.shared')}
            </StatefulCheckbox>
          </FormControl>
        )}
      </ModalBody>
      <ModalFooter>
        <ModalButton
          onClick={() => {
            setError(undefined)
            onClose()
          }}
        >
          {t('cancel')}
        </ModalButton>
        <ModalButton
          disabled={name.length === 0}
          onClick={() => {
            if (machine !== undefined) {
              api.channelTemplatesApi
                .addTemplate({
                  name,
                  machineId: machine,
                  channels,
                  axes,
                  shared,
                })
                .then((result) => {
                  setError(undefined)
                  onClose(result.data)
                  toaster.positive(<>{t('template.save_success')}</>, {})
                })
                .catch((error) => {
                  if (error.response !== undefined) {
                    if (error.response.status === 409) {
                      setError(t('template.exists'))
                    } else {
                      setError(undefined)
                      onClose()
                      toaster.negative(<>{error.message}</>, {
                        autoHideDuration: 0,
                      })
                    }
                  } else {
                    setError(undefined)
                    onClose()
                    toaster.negative(<>{error.message}</>, {
                      autoHideDuration: 0,
                    })
                  }
                })
            }
          }}
        >
          {t('save')}
        </ModalButton>
      </ModalFooter>
    </Modal>
  )
}

export { SaveDialog }
