import { styled } from 'baseui'
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from 'baseui/modal'
import { StatefulSelect, Value } from 'baseui/select'
import { toaster } from 'baseui/toast'
import { Axis, Channel, ChannelTemplate } from 'client'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'react-oidc-context'

import { useApi } from '../../ApiProvider'
import { DispatchContext } from '../../App'

const StyledOptgroupHeader = styled('li', (props) => {
  const paddingX = props.$theme.sizing.scale300
  const paddingY = props.$theme.sizing.scale200
  return {
    ...props.$theme.typography.LabelSmall,
    color: props.$theme.colors.contentPrimary,
    paddingTop: paddingY,
    paddingBottom: paddingY,
    paddingRight: paddingX,
    paddingLeft: paddingX,
  }
})

const LoadDialog: React.FC<{
  templates: ChannelTemplate[]
  isOpen: boolean
  onClose: (
    template?: ChannelTemplate,
    channels?: Channel[],
    axes?: Axis[]
  ) => void
}> = ({ templates, isOpen, onClose }) => {
  const dispatch = useContext(DispatchContext)
  const [selectedTemplate, setSelectedTemplate] = useState<Value>([])
  const [t] = useTranslation()
  const [api] = useApi()
  const auth = useAuth()

  useEffect(() => {
    api.channelTemplatesApi.listTemplates().then((result) => {
      dispatch({
        type: 'setTemplates',
        templates: result.data,
      })
    })
  }, [api.channelTemplatesApi, dispatch])

  return (
    <Modal isOpen={isOpen} closeable={false} unstable_ModalBackdropScroll>
      <ModalHeader>{t('template.load')}</ModalHeader>
      <ModalBody>
        <StatefulSelect
          options={templates.reduce(
            (result, template) => {
              if (auth.user !== undefined) {
                if (
                  template.shared &&
                  template.user !== auth.user?.profile.sub
                ) {
                  return { ...result, shared: [...result.shared, template] }
                } else {
                  return {
                    ...result,
                    owned: [...result.owned, template],
                  }
                }
              } else {
                return {
                  ...result,
                  __ungrouped: [...result.__ungrouped, template],
                }
              }
            },
            {
              __ungrouped: [] as ChannelTemplate[],
              owned: [] as ChannelTemplate[],
              shared: [] as ChannelTemplate[],
            }
          )}
          labelKey={'name'}
          clearable={false}
          searchable={true}
          placeholder={t('template.select_template')}
          noResultsMsg={t('no_results')}
          onChange={(selection) => setSelectedTemplate(selection.value)}
          overrides={{
            StatefulMenu: {
              props: {
                overrides: {
                  OptgroupHeader: {
                    component: (props: any) => (
                      <StyledOptgroupHeader
                        {...props}
                        children={t(`template.${props.children}`)}
                      />
                    ),
                  },
                },
              },
            },
          }}
        />
      </ModalBody>
      <ModalFooter>
        <ModalButton
          onClick={() => {
            setSelectedTemplate([])
            onClose()
          }}
        >
          {t('cancel')}
        </ModalButton>
        <ModalButton
          disabled={selectedTemplate.length === 0}
          onClick={() => {
            api.channelTemplatesApi
              .loadTemplate(selectedTemplate[0].id as number)
              .then((result) => {
                onClose(
                  selectedTemplate[0] as ChannelTemplate,
                  result.data.channels,
                  result.data.axes
                )
                setSelectedTemplate([])
                toaster.positive(<>{t('template.load_success')}</>, {})
              })
              .catch((error) => {
                setSelectedTemplate([])
                onClose()
                if (error.response !== undefined) {
                  toaster.negative(<>{error.response.data.message}</>, {
                    autoHideDuration: 0,
                  })
                } else {
                  toaster.negative(<>{error.message}</>, {
                    autoHideDuration: 0,
                  })
                }
              })
          }}
        >
          {t('ok')}
        </ModalButton>
      </ModalFooter>
    </Modal>
  )
}

export { LoadDialog }
