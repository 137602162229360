import {
  AxesApi,
  ChannelsApi,
  ChannelTemplatesApi,
  Configuration,
  ConnectorsApi,
  ExportsApi,
  MachinesApi,
  OrdersApi,
  ParametersApi,
  SettingsApi,
  StatisticsApi,
  TimeSeriesApi,
} from 'client'
import { User } from 'oidc-client-ts'
import React, { createContext, useContext, useMemo } from 'react'

export type ApiT = {
  channelsApi: ChannelsApi
  channelTemplatesApi: ChannelTemplatesApi
  parametersApi: ParametersApi
  timeSeriesApi: TimeSeriesApi
  machinesApi: MachinesApi
  ordersApi: OrdersApi
  settingsApi: SettingsApi
  exportsApi: ExportsApi
  statisticsApi: StatisticsApi
  connectorsApi: ConnectorsApi
  axesApi: AxesApi
}

export const ApiContext = createContext<ApiT>({
  channelsApi: new ChannelsApi(),
  channelTemplatesApi: new ChannelTemplatesApi(),
  parametersApi: new ParametersApi(),
  timeSeriesApi: new TimeSeriesApi(),
  machinesApi: new MachinesApi(),
  ordersApi: new OrdersApi(),
  settingsApi: new SettingsApi(),
  exportsApi: new ExportsApi(),
  statisticsApi: new StatisticsApi(),
  connectorsApi: new ConnectorsApi(),
  axesApi: new AxesApi(),
})

export const ApiProvider: React.FC<{ configuration: Configuration }> = ({
  configuration,
  children,
}) => {
  const api = useMemo(() => {
    return {
      channelsApi: new ChannelsApi(configuration),
      channelTemplatesApi: new ChannelTemplatesApi(configuration),
      parametersApi: new ParametersApi(configuration),
      timeSeriesApi: new TimeSeriesApi(configuration),
      machinesApi: new MachinesApi(configuration),
      ordersApi: new OrdersApi(configuration),
      settingsApi: new SettingsApi(configuration),
      exportsApi: new ExportsApi(configuration),
      statisticsApi: new StatisticsApi(configuration),
      connectorsApi: new ConnectorsApi(configuration),
      axesApi: new AxesApi(configuration),
    }
  }, [configuration])
  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
}

export const useApi = (): [ApiT] => {
  const api = useContext(ApiContext)
  return [api]
}

export const getUser = (authority: string, clientId: string) => {
  const oidcStorage = sessionStorage.getItem(
    `oidc.user:${authority}:${clientId}`
  )
  if (!oidcStorage) {
    return null
  }
  return User.fromStorageString(oidcStorage)
}
