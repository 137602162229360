import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStyletron } from 'baseui'
import { Block } from 'baseui/block'
import { StatefulDatePicker } from 'baseui/datepicker'
import { StatefulInput } from 'baseui/input'
import { ProgressBar } from 'baseui/progress-bar'
import { Order } from 'client/dist/models'
import { endOfDay, format, startOfDay } from 'date-fns'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import {
  AutoSizer,
  Column,
  InfiniteLoader,
  SortDirectionType,
  Table,
  TableHeaderProps,
} from 'react-virtualized'
import { and, comparison, eq, ge, le } from 'rsql-builder'
import { debounce } from 'throttle-debounce'

import { useApi } from '../ApiProvider'
import { DispatchContext } from '../App'
import { useLocale } from '../utils'

type FilterT = {
  machine?: string
  orderNumber?: string
  recipeNumber?: string
  start?: Date
  end?: Date
}

const Search: React.FC = () => {
  const dispatch = useContext(DispatchContext)
  const [css, theme] = useStyletron()
  const total = useRef(0)
  const [orders, setOrders] = useState<Order[]>([])
  const [filter, setFilter] = useState<FilterT>({})
  const [query, setQuery] = useState('')
  const [sort, setSort] = useState<{
    column: string
    direction: SortDirectionType
  }>({
    column: 'start',
    direction: 'DESC',
  })
  const [loading, setLoading] = useState(false)
  const [t] = useTranslation()
  const locale = useLocale()
  const [api] = useApi()
  const history = useHistory()

  useEffect(() => {
    setLoading(true)
    api.ordersApi
      .listOrders(query, 0, 100, sort.column, sort.direction)
      .then((result) => {
        total.current = parseInt(result.headers['x-total-count'])
        setOrders(result.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [api.machinesApi, api.ordersApi, query, sort.column, sort.direction])

  const bodyCellClass = useMemo(
    () =>
      css({
        ...theme.typography.ParagraphSmall,
        ...theme.borders.border300,
        borderTop: 'none',
        borderBottom: 'none',
        [theme.direction === 'rtl' ? 'borderRight' : 'borderLeft']: 'none',
        borderColor: 'transparent',
        color: theme.colors.contentPrimary,
        paddingTop: theme.sizing.scale300,
        paddingRight: theme.sizing.scale600,
        paddingBottom: theme.sizing.scale300,
        paddingLeft: theme.sizing.scale600,
        ':last-of-type': {
          [theme.direction === 'rtl' ? 'borderLeft' : 'borderRight']: 'none',
        },
      }),
    [css, theme]
  )

  const headerRenderer = useCallback(
    ({ dataKey, label, sortBy, sortDirection }: TableHeaderProps) => {
      return (
        <>
          <span
            className='ReactVirtualized__Table__headerTruncatedText'
            key='label'
            title={typeof label === 'string' ? label : undefined}
          >
            {label}
          </span>
          {sortBy === dataKey && (
            <FontAwesomeIcon
              style={{
                transform:
                  sortDirection === 'ASC'
                    ? 'translateY(25%)'
                    : 'translateY(-25%)',
              }}
              key={'sort'}
              icon={sortDirection === 'ASC' ? faSortUp : faSortDown}
              fixedWidth
            />
          )}
          <div
            className={css({
              height: '36px',
              paddingTop: theme.sizing.scale500,
            })}
            key={'filter'}
            onClick={(e) => e.stopPropagation()}
          >
            {(dataKey === 'machine' ||
              dataKey === 'orderNumber' ||
              dataKey === 'recipeNumber') && (
              <StatefulInput
                size={'compact'}
                clearable
                onChange={(e) => {
                  if (!!e.currentTarget.value) {
                    setFilter((prev) => ({
                      ...prev,
                      [dataKey]: e.currentTarget.value,
                    }))
                  } else {
                    setFilter((prev) => ({
                      ...prev,
                      [dataKey]: undefined,
                    }))
                  }
                }}
              />
            )}
            {(dataKey === 'start' || dataKey === 'end') && (
              <StatefulDatePicker
                size={'compact'}
                locale={locale}
                placeholder={' '}
                formatString={t('datepicker.format')}
                clearable
                onChange={({ date }) => {
                  if (date instanceof Date) {
                    setFilter((prev) => ({
                      ...prev,
                      [dataKey]:
                        dataKey === 'start' ? startOfDay(date) : endOfDay(date),
                    }))
                  } else {
                    setFilter((prev) => ({
                      ...prev,
                      [dataKey]: undefined,
                    }))
                  }
                }}
              />
            )}
          </div>
        </>
      )
    },
    [css, locale, t, theme.sizing.scale500]
  )

  const updateQuery = useCallback(
    debounce(250, (filter: FilterT) => {
      const filters: Array<any> = []
      if (filter.machine) {
        filters.push(comparison('machine.name', eq(filter.machine)))
      }
      if (filter.orderNumber) {
        filters.push(comparison('orderNumber', eq(filter.orderNumber)))
      }
      if (filter.recipeNumber) {
        filters.push(comparison('recipeNumber', eq(filter.recipeNumber)))
      }
      if (filter.start) {
        filters.push(comparison('start', ge(filter.start.getTime())))
      }
      if (filter.end) {
        filters.push(comparison('end', le(filter.end.getTime())))
      }
      setQuery(and.apply(null, filters))
    }),
    []
  )

  useEffect(() => {
    updateQuery(filter)
  }, [filter, updateQuery])

  return (
    <Block flex={'auto'}>
      <AutoSizer>
        {({ width, height }) => (
          <InfiniteLoader
            isRowLoaded={({ index }) => {
              return !!orders[index]
            }}
            loadMoreRows={({ startIndex }) => {
              setLoading(true)
              return api.ordersApi
                .listOrders(
                  query,
                  Math.floor(startIndex / 100),
                  100,
                  sort.column,
                  sort.direction
                )
                .then((result) => {
                  setOrders([...orders, ...result.data])
                })
                .finally(() => {
                  setLoading(false)
                })
            }}
            minimumBatchSize={100}
            rowCount={total.current}
          >
            {({ onRowsRendered, registerChild }) => (
              <Table
                height={height}
                width={width}
                headerHeight={94}
                rowHeight={36}
                rowCount={orders.length}
                rowGetter={({ index }) => orders[index]}
                rowClassName={({ index }) => {
                  if (index === -1) {
                    return css({
                      backgroundColor: theme.colors.tableHeadBackgroundColor,
                      boxShadow: theme.lighting.shadow400,
                      display: 'flex',
                      alignItems: 'center',
                    })
                  } else {
                    return css({
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                    })
                  }
                }}
                headerRowRenderer={({ className, columns, style }) => {
                  return (
                    <>
                      <div className={className} role='row' style={style}>
                        {columns}
                      </div>
                      {loading && (
                        <ProgressBar
                          infinite
                          overrides={{
                            Bar: {
                              style: {
                                position: 'absolute',
                                width: `${style.width}px`,
                                marginBottom: 0,
                                marginLeft: 0,
                                marginRight: 0,
                                marginTop: 0,
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                              },
                            },
                            BarProgress: {
                              style: {
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                              },
                            },
                          }}
                        />
                      )}
                    </>
                  )
                }}
                headerClassName={css({
                  ...theme.typography.LabelMedium,
                  ...theme.borders.border300,
                  borderTop: 'none',
                  borderBottom: 'none',
                  [theme.direction === 'rtl' ? 'borderRight' : 'borderLeft']:
                    'none',
                  color: theme.colors.contentPrimary,
                  paddingTop: theme.sizing.scale500,
                  paddingRight: theme.sizing.scale600,
                  paddingBottom: theme.sizing.scale600,
                  paddingLeft: theme.sizing.scale600,
                  ':focus': {
                    outline: 'none',
                  },
                  ':last-of-type': {
                    [theme.direction === 'rtl' ? 'borderLeft' : 'borderRight']:
                      'none',
                  },
                })}
                className={css({
                  backgroundColor: theme.colors.tableBackground,
                  display: 'flex',
                  flexDirection: 'column',
                })}
                onRowClick={({ rowData }) => {
                  // Stop auto refersh
                  dispatch({
                    type: 'setAutoRefresh',
                    autoRefresh: false,
                  })
                  history.push({
                    pathname: `/dashboard/${rowData.machine.id}`,
                    search: `?s=${Math.floor(rowData.start / 1000)}&e=${
                      rowData.end === 0
                        ? Math.floor(new Date().getTime() / 1000)
                        : Math.floor(rowData.end / 1000)
                    }`,
                  })
                }}
                sortBy={sort.column}
                sortDirection={sort.direction}
                sort={({ sortBy, sortDirection }) => {
                  setSort({
                    column: sortBy,
                    direction: sortDirection,
                  })
                }}
                onRowsRendered={onRowsRendered}
                ref={registerChild}
                noRowsRenderer={() => {
                  return (
                    <div
                      className={css({
                        ...theme.typography.ParagraphMedium,
                        color: theme.colors.contentPrimary,
                        textAlign: 'center',
                        paddingTop: theme.sizing.scale800,
                      })}
                    >
                      {t('search.no_orders')}
                    </div>
                  )
                }}
              >
                <Column
                  label={t('search.machine')}
                  dataKey={'machine'}
                  headerRenderer={headerRenderer}
                  cellDataGetter={({ dataKey, rowData }) => {
                    return rowData[dataKey].name
                  }}
                  width={200}
                  flexGrow={1}
                  className={bodyCellClass}
                />
                <Column
                  label={t('search.order')}
                  dataKey={'orderNumber'}
                  headerRenderer={headerRenderer}
                  width={150}
                  flexGrow={1}
                  className={bodyCellClass}
                />
                <Column
                  label={t('search.recipe')}
                  dataKey={'recipeNumber'}
                  headerRenderer={headerRenderer}
                  width={150}
                  flexGrow={1}
                  className={bodyCellClass}
                />
                <Column
                  label={t('search.start_date')}
                  dataKey={'start'}
                  headerRenderer={headerRenderer}
                  cellDataGetter={({ dataKey, rowData }) => {
                    return format(rowData[dataKey], 'Ppp', {
                      locale,
                    })
                  }}
                  width={175}
                  className={bodyCellClass}
                />
                <Column
                  label={t('search.end_date')}
                  dataKey={'end'}
                  headerRenderer={headerRenderer}
                  cellDataGetter={({ dataKey, rowData }) => {
                    if (rowData[dataKey] === 0) {
                      return t('search.running')
                    } else {
                      return format(rowData[dataKey], 'Ppp', {
                        locale,
                      })
                    }
                  }}
                  width={175}
                  className={bodyCellClass}
                />
              </Table>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </Block>
  )
}

export { Search }
