import { useStyletron } from 'baseui'
import { KIND, SIZE } from 'baseui/button'
import { Input } from 'baseui/input'
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from 'baseui/modal'
import { TableBuilder, TableBuilderColumn } from 'baseui/table-semantic'
import { PropertyDescriptor } from 'client'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export const ConnectorSettings: React.FC<{
  isOpen: boolean
  supportedProperties: PropertyDescriptor[]
  propertyValues: Record<string, string>
  onSave: (data: Record<string, any>) => void
  onCancel: () => void
}> = ({ isOpen, supportedProperties, propertyValues, onSave, onCancel }) => {
  const [css, theme] = useStyletron()
  const [t] = useTranslation()

  const { control, handleSubmit, errors, reset, formState } = useForm({
    mode: 'onBlur',
  })

  useEffect(() => {
    reset(
      supportedProperties.reduce(
        (result, { name, defaultValue }) => ({
          ...result,
          [name]: propertyValues[name] || '',
        }),
        {}
      )
    )
  }, [propertyValues, reset, supportedProperties])

  return (
    <Modal
      isOpen={isOpen}
      closeable={false}
      unstable_ModalBackdropScroll
      overrides={{
        Dialog: {
          style: {
            width: '40vw',
            display: 'flex',
            flexDirection: 'column',
          },
        },
      }}
    >
      <form
        onSubmit={handleSubmit((data) => {
          onSave(data)
        })}
      >
        <ModalHeader>{t('settings.connector_settings')}</ModalHeader>
        <ModalBody>
          <TableBuilder
            data={supportedProperties}
            overrides={{
              Root: {
                style: {
                  maxHeight: '300px',
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                },
              },
            }}
          >
            <TableBuilderColumn header={'Name'}>
              {(row) => (
                <span
                  className={css({
                    fontWeight: row.required ? 'bold' : 'normal',
                    color:
                      errors[row.name] !== undefined
                        ? theme.colors.negative300
                        : theme.colors.primary,
                  })}
                >
                  {row.displayName}
                </span>
              )}
            </TableBuilderColumn>
            <TableBuilderColumn
              header={'Value'}
              overrides={{
                TableBodyCell: {
                  style: {
                    paddingTop: 0,
                    paddingBottom: 0,
                  },
                },
              }}
            >
              {(row) => (
                <Controller
                  as={<Input />}
                  control={control}
                  rules={{
                    required: row.required,
                  }}
                  name={row.name}
                  placeholder={row.defaultValue}
                  size={'compact'}
                  type={row.sensitive ? 'password' : undefined}
                  disabled={row.readonly}
                  overrides={{
                    InputContainer: {
                      style: {
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: '2px',
                        borderLeftWidth: 0,
                        backgroundColor: 'transparent',
                      },
                    },
                    Input: {
                      style: {
                        paddingRight: 0,
                        paddingLeft: 0,
                      },
                    },
                  }}
                />
              )}
            </TableBuilderColumn>
          </TableBuilder>
        </ModalBody>
        <ModalFooter>
          <ModalButton
            onClick={(e) => {
              onCancel()
              e.preventDefault()
            }}
            size={SIZE.compact}
            kind={KIND.tertiary}
          >
            {t('cancel')}
          </ModalButton>
          <ModalButton
            disabled={!formState.dirty}
            onClick={(e) => {
              reset()
              e.preventDefault()
            }}
            size={SIZE.compact}
            kind={KIND.secondary}
          >
            {t('revert')}
          </ModalButton>
          <ModalButton type={'submit'} size={SIZE.compact}>
            {t('save')}
          </ModalButton>
        </ModalFooter>
      </form>
    </Modal>
  )
}
