import { faEllipsisV, faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'baseui'
import { Block } from 'baseui/block'
import { Button, KIND, SHAPE, SIZE } from 'baseui/button'
import { Checkbox, STYLE_TYPE } from 'baseui/checkbox'
import { FormControl } from 'baseui/form-control'
import { Input, StatefulInput } from 'baseui/input'
import { StatefulMenu } from 'baseui/menu'
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from 'baseui/modal'
import { PLACEMENT, StatefulPopover } from 'baseui/popover'
import {
  Select,
  StatefulSelect,
  StyledDropdownListItem,
  Value,
} from 'baseui/select'
import {
  Axis,
  AxisType,
  Channel as ChannelModel,
  Parameter,
} from 'client/dist/models'
import { format } from 'date-fns'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { useApi } from '../ApiProvider'
import { CursorValuesContext, DispatchContext, ParameterT } from '../App'
import { generateColor, useLocale } from '../utils'
import { ColorPicker } from './ColorPicker'

const StyledTableContainer = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 'auto',
}))

const StyledTable = styled('table', ({ $theme }) => ({
  marginLeft: $theme.sizing.scale600,
  marginRight: $theme.sizing.scale600,
  marginBottom: $theme.sizing.scale600,
  marginTop: $theme.sizing.scale600,
  borderCollapse: 'collapse',
  flex: 'auto',
  tableLayout: 'fixed',
}))

const StyledTableRow = styled('tr', ({ $theme }) => ({
  borderBottomWidth: $theme.sizing.scale200,
  borderBottomColor: $theme.colors.backgroundPrimary,
  borderBottomStyle: 'solid',
  ':last-child': { borderBottomStyle: 'none' },
}))

const StyledTableColumn = styled('td', ({ $theme }) => ({
  ...$theme.typography.LabelSmall,
  fontWeight: 400,
  fontFamily: 'Open Sans',
  lineHeight: $theme.sizing.scale800,
  backgroundColor: $theme.colors.contentInverseTertiary,
  paddingTop: $theme.sizing.scale300,
  paddingBottom: $theme.sizing.scale300,
}))

const StyledTableColumnHeader = styled('th', ({ $theme }) => ({
  ...$theme.typography.LabelSmall,
  lineHeight: $theme.sizing.scale800,
  color: $theme.colors.primary,
}))

const StyledMenuDivider = styled('li', ({ $theme }) => ({
  color: $theme.colors.contentPrimary,
  borderBottomWidth: '2px',
  borderBottomColor: $theme.borders.border300.borderColor,
  borderBottomStyle: $theme.borders.border300.borderStyle as any,
  marginTop: $theme.sizing.scale100,
  marginBottom: $theme.sizing.scale100,
  marginLeft: $theme.sizing.scale500,
  marginRight: $theme.sizing.scale500,
  listStyle: 'none',
  height: 0,
}))

const ParameterChooser: React.FC<{
  isOpen: boolean
  onClose: (parameter?: Parameter, axis?: AxisType, newAxis?: Axis) => void
  parameters: ParameterT[]
  axes: Axis[]
}> = (props) => {
  const [parameter, setParameter] = useState<Value>([])
  const [axis, setAxis] = useState<Value>([{ type: 'NONE' }])
  const [newAxis, setNewAxis] = useState<Value>([])
  const [axisName, setAxisName] = useState<string>()
  const [axisUnit, setAxisUnit] = useState<string>()
  const [t] = useTranslation()

  useEffect(() => {
    setAxisUnit(parameter[0]?.unit ?? '')
  }, [parameter])

  return (
    <Modal isOpen={props.isOpen} closeable={false} unstable_ModalBackdropScroll>
      <ModalHeader>{t('channel.add')}</ModalHeader>
      <ModalBody>
        <FormControl label={() => t('channel.parameter_label')}>
          <StatefulSelect
            options={props.parameters
              .filter(({ inUse }) => !inUse)
              .map(({ name, id, inUse, unit }) => {
                return {
                  label: name,
                  id,
                  inUse,
                  unit,
                }
              })}
            clearable={false}
            placeholder={t('channel.select_parameter')}
            noResultsMsg={t('no_results')}
            onChange={({ value }) => setParameter(value)}
            maxDropdownHeight={'350px'}
          />
        </FormControl>
        <FormControl label={() => t('channel.axis_label')}>
          <StatefulSelect
            initialState={{
              value: axis,
            }}
            options={[
              ...props.axes.filter(({ unit }) => unit === parameter[0]?.unit),
              { type: AxisType.NONE, name: t('channel.axis.NONE') },
              { divider: true },
              { type: 'NEW', name: t('channel.axis.NEW') },
            ]}
            valueKey={'type'}
            labelKey={'name'}
            clearable={false}
            searchable={false}
            deleteRemoves={false}
            backspaceRemoves={false}
            onChange={({ value }) => {
              setAxis(value)
            }}
            overrides={{
              DropdownListItem: {
                component: (componentProps: any) => {
                  return componentProps.item.divider ? (
                    <StyledMenuDivider />
                  ) : (
                    <StyledDropdownListItem {...componentProps} />
                  )
                },
              },
            }}
          />
        </FormControl>
        {axis[0]?.type === 'NEW' && (
          <>
            <hr />
            <FormControl label={() => t('channel.axis_position_label')}>
              <StatefulSelect
                options={Object.keys(AxisType)
                  .filter(
                    (axisType) =>
                      !props.axes
                        .map(({ type }) => type)
                        .includes(axisType as AxisType) &&
                      axisType !== AxisType.NONE
                  )
                  .map((key) => {
                    return {
                      label: t(`channel.axis.${key}`),
                      id: key,
                    }
                  })}
                clearable={false}
                searchable={false}
                deleteRemoves={false}
                backspaceRemoves={false}
                onChange={(selection) => setNewAxis(selection.value)}
                placeholder={t('channel.select_axis_position')}
              />
            </FormControl>
            <FormControl label={() => t('settings.name')}>
              <StatefulInput
                initialState={{ value: axisName ?? '' }}
                onChange={(event) => setAxisName(event.currentTarget.value)}
              />
            </FormControl>
            <FormControl label={() => t('settings.unit')}>
              <Input
                value={axisUnit}
                onChange={(e) => setAxisUnit(e.currentTarget.value)}
              />
            </FormControl>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <ModalButton
          onClick={() => {
            props.onClose()
            setParameter([])
            setAxis([{ type: AxisType.NONE }])
            setNewAxis([])
            setAxisUnit(undefined)
            setAxisName(undefined)
          }}
        >
          {t('cancel')}
        </ModalButton>
        <ModalButton
          disabled={
            parameter.length === 0 ||
            (axis[0]?.type === 'NEW' && (!axisName || newAxis.length === 0))
          }
          onClick={() => {
            const selectedParameter = props.parameters.find(
              ({ id }) => id === parameter[0].id
            )
            props.onClose(
              selectedParameter,
              axis[0]?.type === 'NEW'
                ? (newAxis[0]?.id as AxisType)
                : (axis[0]?.type as AxisType),
              axis[0]?.type === 'NEW'
                ? {
                    machineId: selectedParameter?.machineId!,
                    minLowerBound: selectedParameter?.lowerLimit!,
                    lowerBound: selectedParameter?.lowerLimit!,
                    maxUpperBound: selectedParameter?.upperLimit!,
                    upperBound: selectedParameter?.upperLimit!,
                    scale: selectedParameter?.scale!,
                    name: axisName!,
                    unit: axisUnit,
                    type: newAxis[0]?.id as AxisType,
                  }
                : undefined
            )
            setParameter([])
            setAxis([{ type: AxisType.NONE }])
            setNewAxis([])
            setAxisUnit(undefined)
            setAxisName(undefined)
          }}
        >
          {t('ok')}
        </ModalButton>
      </ModalFooter>
    </Modal>
  )
}

const ChannelList: React.FC<{
  channels: ChannelModel[]
  parameters: ParameterT[]
  axes: Axis[]
  machine: number | undefined
}> = React.memo(({ channels, parameters, axes, machine }) => {
  const dispatch = useContext(DispatchContext)
  const [isOpen, setIsOpen] = useState(false)
  const cursorValues = useContext(CursorValuesContext)
  const [t, i18n] = useTranslation()
  const locale = useLocale()
  const history = useHistory()
  const [api] = useApi()

  useEffect(() => {
    if (machine !== undefined) {
      Promise.all([
        api.parametersApi.listParameters(machine),
        api.channelsApi.listChannels(machine),
        api.axesApi.listAxes(machine),
      ]).then(([parametersResponse, channelsResponse, axesResponse]) => {
        dispatch({
          type: 'setParametersAndChannels',
          parameters: parametersResponse.data
            .filter((parameter) => parameter.active)
            .map((parameter) => {
              return {
                ...parameter,
                inUse:
                  parameter.id !== undefined &&
                  channelsResponse.data
                    .map(({ parameterId }) => parameterId)
                    .includes(parameter.id),
              }
            }),
          channels: channelsResponse.data,
          axes: axesResponse.data,
        })
      })
    }
  }, [
    api.axesApi,
    api.channelsApi,
    api.machinesApi,
    api.parametersApi,
    dispatch,
    machine,
  ])

  return (
    <StyledTableContainer>
      <StyledTable>
        {channels.length > 0 && (
          <thead>
            <StyledTableRow
              $style={{
                textAlign: 'left',
              }}
            >
              <StyledTableColumnHeader></StyledTableColumnHeader>
              <StyledTableColumnHeader></StyledTableColumnHeader>
              <StyledTableColumnHeader>
                {t('channel.axis_label')}
              </StyledTableColumnHeader>
              <StyledTableColumnHeader>
                {t('channel.parameter_label')}
              </StyledTableColumnHeader>
              <StyledTableColumnHeader>
                {t('channel.unit_label')}
              </StyledTableColumnHeader>
              {cursorValues.left.active && (
                <>
                  <StyledTableColumnHeader>
                    {t('channel.x_value_1')}
                  </StyledTableColumnHeader>
                  <StyledTableColumnHeader>
                    {t('channel.y_value_1')}
                  </StyledTableColumnHeader>
                  <StyledTableColumnHeader>
                    {t('channel.x_value_2')}
                  </StyledTableColumnHeader>
                  <StyledTableColumnHeader>
                    {t('channel.y_value_2')}
                  </StyledTableColumnHeader>
                </>
              )}
              {!cursorValues.left.active && (
                <>
                  <StyledTableColumnHeader>
                    {t('channel.x_value')}
                  </StyledTableColumnHeader>
                  <StyledTableColumnHeader>
                    {t('channel.y_value')}
                  </StyledTableColumnHeader>
                </>
              )}
              {window.env.showStatistics && (
                <>
                  <StyledTableColumnHeader>
                    {t('channel.min')}
                  </StyledTableColumnHeader>
                  <StyledTableColumnHeader>
                    {t('channel.max')}
                  </StyledTableColumnHeader>
                  <StyledTableColumnHeader>
                    {t('channel.avg')}
                  </StyledTableColumnHeader>
                  <StyledTableColumnHeader>
                    {t('channel.sd')}
                  </StyledTableColumnHeader>
                  <StyledTableColumnHeader>
                    {t('channel.integral')}
                  </StyledTableColumnHeader>
                </>
              )}
              <StyledTableColumnHeader></StyledTableColumnHeader>
            </StyledTableRow>
          </thead>
        )}
        <tbody>
          {channels.map((channel) => {
            const parameter = parameters.find(
              ({ id }) => id === channel.parameterId
            )
            return (
              <StyledTableRow key={channel.id}>
                <StyledTableColumn
                  $style={{
                    borderLeftWidth: '4px',
                    borderLeftColor: channel.color,
                    borderLeftStyle: 'solid',
                    width: '40px',
                    textAlign: 'center',
                  }}
                >
                  <Checkbox
                    checked={channel.active}
                    onChange={(e) => {
                      if (channel.id) {
                        api.channelsApi
                          .updateChannel(channel.id, {
                            ...channel,
                            active: e.currentTarget.checked,
                          })
                          .then((resonse) => {
                            dispatch({
                              type: 'updateChannel',
                              channel: resonse.data,
                            })
                          })
                      }
                    }}
                    checkmarkType={STYLE_TYPE.toggle_round}
                    overrides={{
                      Label: () => null,
                      ToggleTrack: {
                        style: ({ $theme }) => {
                          return {
                            backgroundColor: $theme.colors.contentSecondary,
                          }
                        },
                      },
                      Toggle: {
                        style: ({ $theme, $checked }) => {
                          return {
                            backgroundColor: $checked
                              ? $theme.colors.primaryA
                              : $theme.colors.primaryB,
                          }
                        },
                      },
                    }}
                  />
                </StyledTableColumn>
                <StyledTableColumn
                  $style={{
                    width: '32px',
                    paddingLeft: '8px',
                    paddingRight: '8px',
                  }}
                >
                  <StatefulPopover
                    placement={PLACEMENT.leftBottom}
                    showArrow
                    content={({ close }) => (
                      <ColorPicker
                        value={channel.color}
                        onChange={(color) => {}}
                        onFinalChange={(color) => {
                          if (channel.id) {
                            api.channelsApi
                              .updateChannel(channel.id, {
                                ...channel,
                                color: color,
                              })
                              .then((resonse) => {
                                dispatch({
                                  type: 'updateChannel',
                                  channel: resonse.data,
                                })
                                close()
                              })
                          }
                        }}
                      />
                    )}
                    overrides={{
                      Arrow: {
                        style: ({ $theme }) => ({
                          backgroundColor: $theme.colors.backgroundSecondary,
                        }),
                      },
                    }}
                  >
                    <Button
                      disabled={!channel.active}
                      shape={SHAPE.square}
                      overrides={{
                        BaseButton: {
                          style: ({ $theme, $disabled }) => ({
                            display: 'block',
                            width: '24px',
                            height: '24px',
                            paddingTop: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            paddingRight: 0,
                            backgroundColor: channel.color,
                            ':hover': {
                              backgroundColor: 'none',
                            },
                            ':disabled': {
                              backgroundColor: channel.color,
                              opacity: 0.3,
                            },
                            ':active': {
                              backgroundColor: 'none',
                            },
                            ':focus': {
                              backgroundColor: 'none',
                            },
                          }),
                        },
                      }}
                    ></Button>
                  </StatefulPopover>
                </StyledTableColumn>
                <StyledTableColumn
                  $style={{ width: '180px', paddingRight: '8px' }}
                >
                  <Select
                    disabled={!channel.active}
                    options={[
                      ...axes.filter(({ unit }) => unit === parameter?.unit),
                      { type: AxisType.NONE, name: t('channel.axis.NONE') },
                    ]}
                    labelKey={'name'}
                    valueKey={'type'}
                    value={[{ type: channel.axis }]}
                    clearable={false}
                    searchable={false}
                    placeholder={t('channel.select_axis')}
                    onChange={({ value }) => {
                      if (channel.id) {
                        api.channelsApi
                          .updateChannel(channel.id, {
                            ...channel,
                            axis: value[0].type as AxisType,
                          })
                          .then((resonse) => {
                            dispatch({
                              type: 'updateChannel',
                              channel: resonse.data,
                            })
                          })
                      }
                    }}
                    size={'mini'}
                    deleteRemoves={false}
                    backspaceRemoves={false}
                    overrides={{
                      ControlContainer: {
                        style: {
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                        },
                      },
                      ValueContainer: {
                        style: {
                          paddingTop: '2px',
                          paddingBottom: '2px',
                          paddingLeft: '5px',
                        },
                      },
                      IconsContainer: {
                        style: {
                          paddingRight: '5px',
                        },
                      },
                    }}
                  />
                </StyledTableColumn>
                <StyledTableColumn>
                  {parameter?.name || 'Unknown'}
                </StyledTableColumn>
                <StyledTableColumn>{parameter?.unit || '--'}</StyledTableColumn>
                {cursorValues.left.active && (
                  <>
                    <StyledTableColumn>
                      {channel.active && parameter
                        ? cursorValues.left.values?.[
                            'value' + parameter?.id
                          ]?.toLocaleString(i18n.language, {
                            minimumFractionDigits: parameter.scale,
                            maximumFractionDigits: parameter.scale,
                            useGrouping: false,
                          }) || '--'
                        : '--'}
                    </StyledTableColumn>
                    <StyledTableColumn
                      $style={{
                        width: '200px',
                      }}
                    >
                      {(channel.active &&
                        cursorValues.left.values &&
                        format(cursorValues.left.values.timestamp, 'PPpp', {
                          locale,
                        })) ||
                        '--'}
                    </StyledTableColumn>
                  </>
                )}
                <StyledTableColumn>
                  {channel.active && parameter
                    ? cursorValues.right?.[
                        'value' + parameter?.id
                      ]?.toLocaleString(i18n.language, {
                        minimumFractionDigits: parameter.scale,
                        maximumFractionDigits: parameter.scale,
                        useGrouping: false,
                      }) || '--'
                    : '--'}
                </StyledTableColumn>
                <StyledTableColumn
                  $style={{
                    width: '200px',
                  }}
                >
                  {(channel.active &&
                    cursorValues.right &&
                    format(cursorValues.right.timestamp, 'PPpp', {
                      locale,
                    })) ||
                    '--'}
                </StyledTableColumn>
                {window.env.showStatistics && (
                  <>
                    <StyledTableColumn>
                      {channel.active && parameter
                        ? cursorValues.statistics?.[
                            'value' + parameter?.id
                          ]?.min?.toLocaleString(i18n.language, {
                            minimumFractionDigits: parameter.scale,
                            maximumFractionDigits: parameter.scale,
                            useGrouping: false,
                          }) || '--'
                        : '--'}
                    </StyledTableColumn>
                    <StyledTableColumn>
                      {channel.active && parameter
                        ? cursorValues.statistics?.[
                            'value' + parameter?.id
                          ]?.max?.toLocaleString(i18n.language, {
                            minimumFractionDigits: parameter.scale,
                            maximumFractionDigits: parameter.scale,
                            useGrouping: false,
                          }) || '--'
                        : '--'}
                    </StyledTableColumn>
                    <StyledTableColumn>
                      {channel.active && parameter
                        ? cursorValues.statistics?.[
                            'value' + parameter?.id
                          ]?.avg?.toLocaleString(i18n.language, {
                            minimumFractionDigits: parameter.scale,
                            maximumFractionDigits: parameter.scale,
                            useGrouping: false,
                          }) || '--'
                        : '--'}
                    </StyledTableColumn>
                    <StyledTableColumn>
                      {channel.active && parameter
                        ? cursorValues.statistics?.[
                            'value' + parameter?.id
                          ]?.stddev?.toLocaleString(i18n.language, {
                            minimumFractionDigits: parameter.scale,
                            maximumFractionDigits: parameter.scale,
                            useGrouping: false,
                          }) || '--'
                        : '--'}
                    </StyledTableColumn>
                    <StyledTableColumn>
                      {channel.active && parameter
                        ? cursorValues.statistics?.[
                            'value' + parameter?.id
                          ]?.area?.toLocaleString(i18n.language, {
                            minimumFractionDigits: parameter.scale,
                            maximumFractionDigits: parameter.scale,
                            useGrouping: false,
                          }) || '--'
                        : '--'}
                    </StyledTableColumn>
                  </>
                )}
                <StyledTableColumn
                  $style={{
                    textAlign: 'center',
                    width: '24px',
                    lineHeight: '16px',
                  }}
                >
                  <StatefulPopover
                    placement={PLACEMENT.bottomRight}
                    content={({ close }) => (
                      <StatefulMenu
                        items={[
                          {
                            label: t('channel.settings'),
                            action: 'settings',
                          },
                          { label: t('channel.remove'), action: 'remove' },
                        ]}
                        onItemSelect={({ item }) => {
                          if (item.action === 'remove' && channel.id) {
                            api.channelsApi
                              .deleteChannel(channel.id)
                              .then(() => {
                                dispatch({
                                  type: 'deleteChannel',
                                  id: channel.id,
                                })
                              })
                          } else if (item.action === 'settings') {
                            history.push(
                              `settings/machines/${channel.machineId}`
                            )
                          }
                          close()
                        }}
                        size={'compact'}
                      />
                    )}
                  >
                    <Button
                      shape={SHAPE.square}
                      overrides={{
                        BaseButton: {
                          style: ({ $theme }) => ({
                            paddingLeft: $theme.sizing.scale100,
                            paddingTop: '0px',
                            paddingRight: '0px',
                            paddingBottom: '0px',
                            backgroundColor: 'transparent',
                            color: $theme.colors.black,
                            ':hover': {
                              backgroundColor: 'none',
                              color: $theme.colors.linkHover,
                            },
                            ':active': {
                              backgroundColor: 'none',
                              color: $theme.colors.linkActive,
                            },
                            ':focus': {
                              backgroundColor: 'none',
                            },
                          }),
                        },
                      }}
                    >
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </Button>
                  </StatefulPopover>
                </StyledTableColumn>
              </StyledTableRow>
            )
          })}
        </tbody>
      </StyledTable>
      {channels.length < 32 && (
        <Block
          flex={'auto'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          marginBottom={'scale600'}
          marginLeft={'scale600'}
          marginRight={'scale600'}
          overrides={{
            Block: {
              style: ({ $theme }) => ({
                borderStyle: 'dashed',
                borderWidth: '2px',
                boxSizing: 'border-box',
                color: $theme.colors.contentInverseTertiary,
                height: $theme.sizing.scale1000,
              }),
            },
          }}
        >
          <Button
            shape={SHAPE.default}
            onClick={() => {
              setIsOpen(true)
            }}
            disabled={machine === undefined}
            size={SIZE.default}
            kind={KIND.tertiary}
            overrides={{
              Root: {
                style: {},
              },
              BaseButton: {
                style: ({ $theme }) => ({
                  paddingTop: 0,
                  paddingBottom: 0,
                  width: '100%',
                  ':hover': {
                    backgroundColor: 'transparent',
                    color: $theme.colors.linkHover,
                  },
                  ':focus': {
                    backgroundColor: 'transparent',
                  },
                  ':active': {
                    backgroundColor: 'transparent',
                    color: $theme.colors.linkActive,
                  },
                  ':disabled': {
                    backgroundColor: 'transparent',
                  },
                }),
              },
            }}
            startEnhancer={<FontAwesomeIcon icon={faPlusSquare} size={'lg'} />}
          >
            {t('channel.add')}
          </Button>
        </Block>
      )}
      <ParameterChooser
        isOpen={isOpen}
        onClose={(parameter, axis, newAxis) => {
          setIsOpen(false)

          if (newAxis !== undefined) {
            api.axesApi
              .addAxis(newAxis)
              .then((response) => {
                dispatch({ type: 'addAxis', axis: response.data })
                if (parameter && parameter.id && axis && machine) {
                  return api.channelsApi.addChannel({
                    machineId: machine,
                    parameterId: parameter.id,
                    color: generateColor(),
                    active: true,
                    minValue: parameter.lowerLimit,
                    maxValue: parameter.upperLimit,
                    axis: axis,
                  })
                }
              })
              .then((response) => {
                if (response) {
                  dispatch({ type: 'addChannel', channel: response.data })
                }
              })
          } else {
            if (parameter && parameter.id && axis && machine) {
              api.channelsApi
                .addChannel({
                  machineId: machine,
                  parameterId: parameter.id,
                  color: generateColor(),
                  active: true,
                  minValue: parameter.lowerLimit,
                  maxValue: parameter.upperLimit,
                  axis: axis,
                })
                .then((response) => {
                  dispatch({ type: 'addChannel', channel: response.data })
                })
            }
          }
        }}
        parameters={parameters}
        axes={axes}
      />
    </StyledTableContainer>
  )
})

export { ChannelList }
