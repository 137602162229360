import './Chart.css'

import useInterval from '@use-it/interval'
import { useStyletron } from 'baseui'
import {
  Axis,
  AxisType,
  Channel,
  Order,
  Parameter,
  TimeSeries,
  TimeSeriesValue,
} from 'client/dist/models'
import { scaleLinear } from 'd3-scale'
import { format, set, subMilliseconds } from 'date-fns'
import React, {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useGesture } from 'react-use-gesture'
import {
  CartesianGrid,
  Customized,
  Label,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts'

import { useApi } from '../ApiProvider'
import { DispatchContext, ParameterT } from '../App'
import { useLocale } from '../utils'
import { Controls } from './chart/Controls'
import { Cursor } from './chart/Cursor'

export type ChartRef = {
  getXAxisDomain: () => [number, number] | undefined
}

const CustomTooltip: React.FC<TooltipProps> = ({
  active,
  payload,
  label,
  labelFormatter,
}) => {
  const [css, theme] = useStyletron()
  const [, i18n] = useTranslation()
  if (active) {
    return (
      <div>
        <table
          className={css({
            ...theme.borders.border400,
            backgroundColor: theme.colors.contentInverseTertiary,
            borderCollapse: 'collapse',
            borderSpacing: 0,
            emptyCells: 'show',
            opacity: 0.9,
          })}
        >
          <tbody>
            <tr
              className={css({
                backgroundColor: theme.colors.contentInverseSecondary,
              })}
            >
              <th
                colSpan={3}
                className={css({
                  paddingTop: theme.sizing.scale0,
                  paddingRight: theme.sizing.scale100,
                  paddingBottom: theme.sizing.scale0,
                  paddingLeft: theme.sizing.scale100,
                  fontWeight: 'bold',
                  textAlign: 'left',
                })}
              >
                {labelFormatter && label ? labelFormatter(label) : label}
              </th>
            </tr>
            {payload &&
              payload.map((payload, index) => {
                return (
                  <tr
                    key={index}
                    className={css({ ...theme.borders.border400 })}
                  >
                    <td
                      className={css({
                        paddingTop: theme.sizing.scale100,
                        paddingRight: theme.sizing.scale200,
                        paddingBottom: theme.sizing.scale100,
                        paddingLeft: theme.sizing.scale200,
                      })}
                    >
                      <span
                        className={css({
                          backgroundColor: payload.color,
                          width: theme.sizing.scale400,
                          height: theme.sizing.scale400,
                          display: 'inline-block',
                          marginRight: theme.sizing.scale200,
                        })}
                      ></span>
                      {payload.name}
                    </td>
                    <td
                      className={css({
                        paddingTop: theme.sizing.scale100,
                        paddingRight: theme.sizing.scale200,
                        paddingBottom: theme.sizing.scale100,
                        paddingLeft: theme.sizing.scale200,
                        borderLeftWidth: theme.borders.border400.borderWidth,
                        borderLeftColor: theme.borders.border400.borderColor,
                        borderLeftStyle: 'dotted',
                        textAlign: 'right',
                      })}
                    >
                      {(payload.value as number).toLocaleString(i18n.language, {
                        minimumFractionDigits: payload.payload.scale ?? 0,
                        maximumFractionDigits: payload.payload.scale ?? 2,
                        useGrouping: false,
                      })}
                    </td>
                    <td
                      className={css({
                        paddingTop: theme.sizing.scale100,
                        paddingRight: payload.unit ? theme.sizing.scale200 : 0,
                        paddingBottom: theme.sizing.scale100,
                      })}
                    >
                      {payload.unit}
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    )
  }
  return null
}

const ChartControls: React.FC = React.memo(({ children }) => {
  const container = document.getElementById('container')
  if (container !== null && container.firstChild !== null) {
    return ReactDOM.createPortal(children, container.firstChild as Element)
  }
  return null
})

const PanAndZoomHelper: React.FC<{
  onDrag: (state: any) => any
  onWheel: (state: any) => any
  onPinch: (state: any) => any
  getRect: (
    ref: React.RefObject<SVGRectElement>
  ) => React.ReactElement<SVGRectElement>
}> = React.memo(({ onDrag, onWheel, onPinch, getRect }) => {
  const ref = useRef(null)
  const bind: (...args: any[]) => any = useGesture(
    {
      onDrag: onDrag,
      onWheel: onWheel,
      onPinch: onPinch,
    },
    {
      eventOptions: {
        passive: false,
      },
      drag: {
        lockDirection: true,
      },
      domTarget: ref,
    }
  )

  useEffect(bind, [bind])
  return getRect(ref)
})

const generateXAxisTicks = (
  minValue: number,
  maxValue: number,
  tickCount: number
) => {
  const ticks = []
  const a = maxValue - minValue
  const b = Math.floor(a / tickCount)
  ticks.push(minValue)
  for (let i = 1; i <= tickCount - 1; i++) {
    ticks.push(minValue + b * i)
  }
  ticks.push(maxValue)
  return ticks
}

const generateYAxisTicks = (
  minValue: number,
  maxValue: number,
  tickCount: number
) => {
  const ticks = []
  const a = maxValue - minValue
  const b = a / tickCount
  ticks.push(minValue)
  for (let i = 1; i <= tickCount - 1; i++) {
    ticks.push(minValue + b * i)
  }
  ticks.push(maxValue)
  return ticks
}

const getYAxisOrientation = (axis: AxisType) => {
  switch (axis) {
    case AxisType.PRIMARY:
    case AxisType.LEFT2:
    case AxisType.LEFT3:
    case AxisType.LEFT4:
      return 'left'
    case AxisType.SECONDARY:
    case AxisType.RIGHT2:
    case AxisType.RIGHT3:
    case AxisType.RIGHT4:
      return 'right'
    default:
      return undefined
  }
}

const getMaxDate = (timeSeries: TimeSeries) => {
  return timeSeries.context.maxDate
}

const getMinDate = (timeSeries: TimeSeries) => {
  return timeSeries.context.minDate
}

const getYAxisWidth = (axis: Axis) => {
  const maxValue = axis.lowerBound.toFixed(axis.scale)
  const minValue = axis.upperBound.toFixed(axis.scale)
  const length = Math.max(maxValue.length, minValue.length)
  return 35 + length * 6
}

const Chart = React.memo(
  React.forwardRef<
    ChartRef,
    {
      channels: Channel[]
      parameters: ParameterT[]
      axes: Axis[]
      machine: number | undefined
      autoRefresh: boolean
      showOrders: boolean
      showSpools: boolean
      delay?: number
      domain?: [number, number]
      onCursorChange?: (values: { left: Date | undefined; right: Date }) => void
    }
  >(
    (
      {
        channels,
        parameters,
        axes,
        machine,
        autoRefresh,
        showOrders,
        showSpools,
        delay = 10000,
        domain,
        onCursorChange = () => {},
      },
      ref
    ) => {
      const dispatch = useContext(DispatchContext)
      const [css, theme] = useStyletron()
      const [data, setData] = useState<{
        timeSeries: TimeSeries | undefined
        orders: Order[]
      }>({
        timeSeries: undefined,
        orders: [],
      })
      const [xAxisDomain, setXAxisDomain] = useState<[number, number]>(() => {
        if (domain !== undefined) {
          return domain
        }

        const now = subMilliseconds(
          set(new Date(), {
            milliseconds: 0,
          }),
          delay
        )
        return [
          subMilliseconds(now, window.env.INITIAL_TIME_PERIOD).getTime(),
          now.getTime(),
        ]
      })
      const [dragging, setDragging] = useState<boolean>(false)
      const loading = useRef(false)
      const virtualXAxisDomain = useRef<[number, number]>([
        xAxisDomain[0] - (xAxisDomain[1] - xAxisDomain[0]) / 2,
        xAxisDomain[1] + (xAxisDomain[1] - xAxisDomain[0]) / 2,
      ])
      const chart = useRef(null)
      const [t, i18n] = useTranslation()
      const locale = useLocale()
      const [api] = useApi()

      useImperativeHandle(ref, () => ({
        getXAxisDomain: () => {
          return xAxisDomain
        },
      }))

      const chartChannels: (Channel & {
        parameter: Parameter
        getSeries: (
          data: TimeSeries | undefined
        ) => (TimeSeriesValue & { scale: number })[] | undefined
      })[] = useMemo(
        () =>
          channels.map((channel) => {
            const parameter = parameters.filter(
              (parameter) => parameter.id === channel.parameterId
            )[0]
            return {
              ...channel,
              parameter,
              getSeries: (data: TimeSeries | undefined) => {
                return data && parameter
                  ? data['value' + parameter.id]?.map(
                      (value: TimeSeriesValue) => ({
                        ...value,
                        scale: parameter.scale,
                      })
                    )
                  : undefined
              },
            }
          }),
        [channels, parameters]
      )

      const chartAxes: (Axis & {
        ticks: number[] | undefined
        orientation: 'left' | 'right' | undefined
      })[] = useMemo(() => {
        return channels
          .reduce((result, { axis }) => {
            if (!result.includes(axis)) {
              result.push(axis)
            }
            return result
          }, [] as AxisType[])
          .filter((axisType) => axisType !== AxisType.NONE)
          .map((channelAxis) => {
            const axis = axes.filter(({ type }) => type === channelAxis)[0]
            return {
              ...axis,
              ticks: generateYAxisTicks(axis.lowerBound, axis.upperBound, 10),
              orientation: getYAxisOrientation(axis.type),
            }
          })
      }, [axes, channels])

      const fetchData = useCallback(
        (domain: [number, number]) => {
          if (machine) {
            loading.current = true
            Promise.all([
              api.timeSeriesApi.retrieveSeries(
                machine,
                new Date(domain[0]).toISOString(),
                new Date(domain[1]).toISOString(),
                parameters.map(({ id }) => id!)
              ),
              showOrders || showSpools
                ? api.ordersApi.listOrdersByMachine(
                    machine,
                    new Date(domain[0]).toISOString(),
                    new Date(domain[1]).toISOString(),
                    showSpools
                  )
                : undefined,
            ])
              .then(([timeSeries, orders]) => {
                setData({
                  timeSeries: timeSeries.data,
                  orders: orders !== undefined ? orders.data : [],
                })
              })
              .catch(() => {
                setData({
                  timeSeries: undefined,
                  orders: [],
                })
              })
              .finally(() => {
                // Set new virtual domain
                virtualXAxisDomain.current = domain
                loading.current = false
              })
          }
        },
        [
          api.ordersApi,
          api.timeSeriesApi,
          machine,
          parameters,
          showOrders,
          showSpools,
        ]
      )

      useInterval(
        () => {
          const now = subMilliseconds(
            set(new Date(), { milliseconds: 0 }),
            delay
          ).getTime()
          setXAxisDomain([now - (xAxisDomain[1] - xAxisDomain[0]), now])
          fetchData([
            now - (xAxisDomain[1] - xAxisDomain[0]) * 1.5,
            now + (xAxisDomain[1] - xAxisDomain[0]) / 2,
          ])
        },
        autoRefresh ? 1000 : null
      )

      useEffect(() => {
        if (parameters.length > 0) {
          fetchData(virtualXAxisDomain.current)
        } else {
          setData({
            timeSeries: undefined,
            orders: [],
          })
        }
      }, [fetchData, parameters.length])

      const handleDrag = useCallback(
        (newXAxisDomain: [number, number]) => {
          if (autoRefresh) {
            dispatch({ type: 'setAutoRefresh', autoRefresh: false })
          }

          if (newXAxisDomain[1] > xAxisDomain[1]) {
            // Drag to left
            const now = subMilliseconds(
              set(new Date(), { milliseconds: 0 }),
              delay
            ).getTime()
            if (newXAxisDomain[1] >= now) {
              newXAxisDomain = [
                now - (newXAxisDomain[1] - newXAxisDomain[0]),
                now,
              ]
            }
            setXAxisDomain(newXAxisDomain)
            if (
              virtualXAxisDomain.current[1] - newXAxisDomain[1] <
                ((newXAxisDomain[1] - newXAxisDomain[0]) * 2) / 10 &&
              !loading.current
            ) {
              const x2 =
                virtualXAxisDomain.current[1] +
                (((newXAxisDomain[1] - newXAxisDomain[0]) * 2) / 10) * 3
              const x1 = x2 - (newXAxisDomain[1] - newXAxisDomain[0]) * 2
              fetchData([x1, x2])
            }
            return !(newXAxisDomain[1] === now)
          } else if (newXAxisDomain[0] < xAxisDomain[0]) {
            // Drag to right
            setXAxisDomain(newXAxisDomain)
            if (
              newXAxisDomain[0] - virtualXAxisDomain.current[0] <
                ((newXAxisDomain[1] - newXAxisDomain[0]) * 2) / 10 &&
              !loading.current
            ) {
              const x1 =
                virtualXAxisDomain.current[0] -
                (((newXAxisDomain[1] - newXAxisDomain[0]) * 2) / 10) * 3
              const x2 = x1 + (newXAxisDomain[1] - newXAxisDomain[0]) * 2
              fetchData([x1, x2])
            }
          }
          return true
        },
        [autoRefresh, delay, dispatch, fetchData, xAxisDomain]
      )

      const handleZoom = useCallback(
        (domain: [number, number], delta: number) => {
          const now = subMilliseconds(
            set(new Date(), { milliseconds: 0 }),
            delay
          ).getTime()
          let x1 = domain[0]
          let x2 = domain[1]
          if (x2 >= now) {
            x1 = x1 - delta * ((domain[1] - domain[0]) / 5000)
            x2 = now
          } else if (
            data.timeSeries !== undefined &&
            getMinDate(data.timeSeries) >= x1
          ) {
            x1 = x1 - delta * ((domain[1] - domain[0]) / 5000)
          } else {
            x1 = x1 - delta * ((domain[1] - domain[0]) / 2500)
            x2 = x2 + delta * ((domain[1] - domain[0]) / 2500)
          }
          x1 -= x1 % 1000
          x2 -= x2 % 1000

          if (x2 - x1 >= 60000) {
            setXAxisDomain([x1, x2])
            if (
              (x1 - virtualXAxisDomain.current[0] <
                (virtualXAxisDomain.current[1] -
                  virtualXAxisDomain.current[0]) /
                  10 ||
                x1 - virtualXAxisDomain.current[0] >
                  (virtualXAxisDomain.current[1] -
                    virtualXAxisDomain.current[0]) /
                    10) &&
              !loading.current
            ) {
              fetchData([x1 - (x2 - x1) / 2, x2 + (x2 - x1) / 2])
            }
          }
        },
        [data.timeSeries, delay, fetchData]
      )

      const handleYAxisDrag = useCallback(
        (axis: Axis, newYAxisDomain: [number, number]) => {
          const lowerLimit = axis.minLowerBound
          const upperLimit = axis.maxUpperBound
          if (
            newYAxisDomain[0] >= lowerLimit &&
            newYAxisDomain[1] <= upperLimit
          ) {
            dispatch({
              type: 'updateYAxis',
              axisType: axis.type,
              minValue: newYAxisDomain[0],
              maxValue: newYAxisDomain[1],
            })
            return true
          }
          return false
        },
        [dispatch]
      )

      const handleYAxisZoom = useCallback(
        (axis: Axis, currentDomain: [number, number], delta: number) => {
          const lowerLimit = axis.minLowerBound
          const upperLimit = axis.maxUpperBound
          const abs = currentDomain[1] - currentDomain[0]
          let minValue = currentDomain[0] - delta * (abs / 2500)
          if (minValue < lowerLimit) {
            minValue = lowerLimit
          }
          let maxValue = currentDomain[1] + delta * (abs / 2500)
          if (maxValue > upperLimit) {
            maxValue = upperLimit
          }
          dispatch({
            type: 'updateYAxis',
            axisType: axis.type,
            minValue,
            maxValue,
          })
        },
        [dispatch]
      )

      const dataVisible = useCallback(() => {
        return (
          data.timeSeries !== undefined &&
          getMaxDate(data.timeSeries) >= xAxisDomain[0] &&
          getMinDate(data.timeSeries) <= xAxisDomain[1]
        )
      }, [data.timeSeries, xAxisDomain])

      return (
        <div
          className={css({
            ...theme.typography.ParagraphSmall,
            width: '100%',
          })}
        >
          <ResponsiveContainer
            width={'100%'}
            height={440}
            id={'container'}
            debounce={100}
          >
            <LineChart
              ref={chart}
              className={css({
                userSelect: 'none',
                cursor: dragging && data ? 'grabbing !important' : 'auto',
              })}
              margin={{ top: 16, right: 16, bottom: 10, left: 16 }}
            >
              <Customized
                key={'x-axis-ticks-clip-path'}
                component={({ xAxisMap, offset }) => {
                  return (
                    <>
                      <defs>
                        <clipPath id={'clipPath-recharts-xAxisTicks'}>
                          <rect
                            x={xAxisMap[0].x}
                            y={xAxisMap[0].y}
                            height={xAxisMap[0].height + 5}
                            width={xAxisMap[0].width}
                          />
                        </clipPath>
                      </defs>
                      {chart.current !== null && (
                        <ChartControls>
                          <div
                            style={{
                              position: 'absolute',
                              top: offset.top,
                              right: offset.right,
                              marginTop: theme.sizing.scale300,
                              marginRight:
                                offset.right > 16
                                  ? theme.sizing.scale800
                                  : theme.sizing.scale300,
                            }}
                          >
                            <Controls
                              domain={xAxisDomain}
                              element={
                                (chart.current as any).container.firstChild
                              }
                              onDateSelect={useCallback((range) => {
                                // Stop auto refersh
                                dispatch({
                                  type: 'setAutoRefresh',
                                  autoRefresh: false,
                                })
                                const newXAxisDomain = [
                                  range[0].getTime(),
                                  range[1].getTime(),
                                ]
                                setXAxisDomain(
                                  newXAxisDomain as [number, number]
                                )
                                fetchData([
                                  newXAxisDomain[0] -
                                    (newXAxisDomain[1] - newXAxisDomain[0]) / 2,
                                  newXAxisDomain[1] +
                                    (newXAxisDomain[1] - newXAxisDomain[0]) / 2,
                                ])
                              }, [])}
                            />
                          </div>
                        </ChartControls>
                      )}
                    </>
                  )
                }}
              />
              <XAxis
                hide={parameters.length === 0}
                dataKey={'timestamp'}
                type={'number'}
                scale={'time'}
                domain={[() => xAxisDomain[0], () => xAxisDomain[1]]}
                tick={{
                  fontSize: '12px',
                  fontWeight: 400,
                  fill: theme.colors.contentPrimary,
                }}
                ticks={generateXAxisTicks(xAxisDomain[0], xAxisDomain[1], 21)}
                tickFormatter={(value) => format(value, 'pp', { locale })}
                tickLine={{
                  stroke: theme.colors.contentPrimary,
                }}
                axisLine={{
                  stroke: theme.colors.contentPrimary,
                }}
                interval={'preserveStart'}
                height={55}
                angle={-45}
                textAnchor={'end'}
                allowDuplicatedCategory={false}
              />
              {useMemo(
                () =>
                  chartAxes.map((axis) => {
                    return (
                      <YAxis
                        key={axis.id}
                        hide={axis.type === AxisType.NONE}
                        yAxisId={axis.type}
                        width={getYAxisWidth(axis)}
                        domain={[axis.lowerBound, axis.upperBound]}
                        allowDataOverflow={true}
                        orientation={axis.orientation}
                        tick={{
                          fontSize: '12px',
                          fontWeight: 400,
                          fill: theme.colors.contentPrimary,
                        }}
                        ticks={axis.type === AxisType.NONE ? [] : axis.ticks}
                        tickFormatter={(value: number) =>
                          value.toLocaleString(i18n.language, {
                            minimumFractionDigits: axis.scale,
                            maximumFractionDigits: axis.scale,
                            useGrouping: false,
                          })
                        }
                        tickLine={{
                          stroke: theme.colors.contentPrimary,
                        }}
                        axisLine={{
                          stroke: theme.colors.contentPrimary,
                        }}
                      >
                        <Label
                          angle={90}
                          position={'insideTopLeft'}
                          fill={theme.colors.contentPrimary}
                          fontSize={'12px'}
                          fontFamily={'Open Sans'}
                          fontWeight={400}
                          dx={
                            axis.orientation === 'left'
                              ? getYAxisWidth(axis) + 12
                              : -12
                          }
                        >
                          {axis.name +
                            (axis.unit ? ' [' + axis.unit + ']' : '')}
                        </Label>
                      </YAxis>
                    )
                  }),
                [chartAxes, i18n.language, theme.colors.contentPrimary]
              )}
              <YAxis hide={true} yAxisId={'markers'} />
              {chartChannels
                .filter(({ axis }) => axis === AxisType.NONE)
                .map(({ id, parameter }) => (
                  <YAxis
                    hide={true}
                    yAxisId={`hidden-${id}`}
                    allowDataOverflow={true}
                    domain={[parameter.lowerLimit, parameter.upperLimit]}
                  />
                ))}

              {useMemo(() => {
                return data.orders.map((order) => {
                  if (showSpools && order.spools && order.spools.length > 0) {
                    return [
                      <ReferenceLine
                        key={order.orderNumber}
                        yAxisId={'markers'}
                        x={order.start}
                        stroke={theme.colors.primary}
                        strokeWidth={1}
                        strokeDasharray={'8 8'}
                      >
                        {showOrders && (
                          <Label
                            angle={-90}
                            position={'insideBottomLeft'}
                            fill={theme.colors.primary}
                            fontSize={'12px'}
                            fontFamily={'Open Sans'}
                            fontWeight={400}
                            dx={8}
                          >
                            {order.orderNumber}
                          </Label>
                        )}
                        {order.spools[0].start === order.start && (
                          <Label
                            position={'insideTopLeft'}
                            fill={theme.colors.primary}
                            fontSize={'12px'}
                            fontFamily={'Open Sans'}
                            fontWeight={400}
                          >
                            {`Spule ${order.spools[0].spoolNumber}`}
                          </Label>
                        )}
                      </ReferenceLine>,
                      ...order.spools
                        .filter(({ start }) => start > order.start)
                        .map((spool, index) => {
                          return (
                            <ReferenceLine
                              key={`${spool.orderNumber}-${spool.spoolNumber}-${index}`}
                              yAxisId={'markers'}
                              x={spool.start}
                              stroke={theme.colors.primary}
                              strokeWidth={1}
                              strokeDasharray={'8 8'}
                            >
                              <Label
                                position={'insideTopLeft'}
                                fill={theme.colors.primary}
                                fontSize={'12px'}
                                fontFamily={'Open Sans'}
                                fontWeight={400}
                              >
                                {`Spule ${spool.spoolNumber}`}
                              </Label>
                            </ReferenceLine>
                          )
                        }),
                    ]
                  } else {
                    if (showOrders) {
                      return (
                        <ReferenceLine
                          key={order.orderNumber}
                          yAxisId={'markers'}
                          x={order.start}
                          stroke={theme.colors.primary}
                          strokeWidth={1}
                          strokeDasharray={'8 8'}
                        >
                          <Label
                            angle={-90}
                            position={'insideBottomLeft'}
                            fill={theme.colors.primary}
                            fontSize={'12px'}
                            fontFamily={'Open Sans'}
                            fontWeight={400}
                            dx={8}
                          >
                            {order.orderNumber}
                          </Label>
                        </ReferenceLine>
                      )
                    }
                    return null
                  }
                })
              }, [data.orders, showOrders, showSpools, theme.colors.primary])}
              {useMemo(
                () =>
                  chartChannels.map((channel) => {
                    return (
                      <Line
                        key={channel.id}
                        type={'linear'}
                        data={channel.getSeries(data.timeSeries)}
                        dataKey={'value'}
                        stroke={channel.color}
                        dot={false}
                        activeDot={false}
                        isAnimationActive={false}
                        strokeWidth={1.5}
                        yAxisId={
                          channel.axis === AxisType.NONE
                            ? `hidden-${channel.id}`
                            : channel.axis
                        }
                        unit={channel.parameter.unit}
                        name={channel.parameter.name}
                      />
                    )
                  }),
                [chartChannels, data.timeSeries]
              )}
              {!dragging && dataVisible() && (
                <Tooltip
                  isAnimationActive={false}
                  offset={20}
                  content={<CustomTooltip />}
                  labelFormatter={(label) =>
                    format(label as number, 'PPpp', { locale })
                  }
                />
              )}
              {dataVisible() && (
                <CartesianGrid
                  strokeDasharray={'5 5'}
                  strokeWidth={0.5}
                  stroke={theme.colors.contentInverseTertiary}
                />
              )}
              {!dataVisible() && (
                <Customized
                  key={'no-data-text'}
                  component={({ offset }) => {
                    return (
                      <text
                        x={offset.left + offset.width / 2}
                        y={offset.top + offset.height / 2}
                        textAnchor={'middle'}
                        alignmentBaseline={'middle'}
                        fontSize={'16px'}
                        fill={theme.colors.contentTertiary}
                      >
                        {parameters.length > 0
                          ? loading.current === true
                            ? t('chart.loading')
                            : t('chart.no_data')
                          : t('chart.no_channels')}
                      </text>
                    )
                  }}
                />
              )}
              <Customized
                key={'pan-zoom-handler'}
                component={(props) => {
                  const xAxis = props.xAxisMap[0]
                  return (
                    <>
                      <PanAndZoomHelper
                        onDrag={useCallback(
                          (state) => {
                            if (state.axis === 'x') {
                              state.event.preventDefault()
                            }

                            if (state.dragging && state.first) {
                              setDragging(true)
                            }

                            if (!state.dragging && state.last) {
                              setDragging(false)
                              handleDrag(xAxis.domain)
                            }

                            if (state.pinching) {
                              return
                            }

                            if (state.dragging && !state.first && state.memo) {
                              const diff =
                                state.memo.scale(state.delta[0]) * -1000
                              if (
                                handleDrag([
                                  state.memo.domain[0] + diff,
                                  state.memo.domain[1] + diff,
                                ])
                              ) {
                                return {
                                  ...state.memo,
                                  domain: [
                                    state.memo.domain[0] + diff,
                                    state.memo.domain[1] + diff,
                                  ],
                                }
                              }
                            }

                            if (state.memo === undefined && xAxis.domain) {
                              // Return initial domain and scale
                              return {
                                domain: xAxis.domain,
                                scale: scaleLinear()
                                  .domain([0, props.offset.width])
                                  .rangeRound([
                                    0,
                                    (xAxis.domain[1] - xAxis.domain[0]) / 1000,
                                  ]),
                              }
                            }
                          },
                          [props.offset.width, xAxis.domain]
                        )}
                        onWheel={useCallback(
                          (state) => {
                            state.event.preventDefault()
                            handleZoom(xAxis.domain, state.delta[1])
                          },
                          [xAxis.domain]
                        )}
                        onPinch={useCallback(
                          (state) => {
                            handleZoom(xAxis.domain, state.vdva[0] * -150)
                          },
                          [xAxis.domain]
                        )}
                        getRect={useCallback(
                          (ref) => (
                            <rect
                              ref={ref}
                              x={props.offset.left}
                              y={props.offset.top}
                              width={props.offset.width}
                              height={props.offset.height}
                              className={css({
                                fill: 'none',
                                pointerEvents: 'visible',
                                touchAction: 'pan-y',
                              })}
                            />
                          ),
                          [
                            props.offset.height,
                            props.offset.left,
                            props.offset.top,
                            props.offset.width,
                          ]
                        )}
                      />
                      <Cursor
                        domain={xAxisDomain}
                        offset={useMemo(() => {
                          return {
                            width: props.offset.width,
                            height: props.offset.height,
                            left: props.offset.left,
                            right: props.offset.right,
                            top: props.offset.top,
                            bottom: props.offset.bottom,
                          }
                        }, [
                          props.offset.bottom,
                          props.offset.height,
                          props.offset.left,
                          props.offset.right,
                          props.offset.top,
                          props.offset.width,
                        ])}
                        left
                        right
                        onChange={onCursorChange}
                      />
                      {props.yAxisMap !== undefined &&
                        useMemo(
                          () =>
                            Object.keys(props.yAxisMap).map((id) => {
                              const yAxis = props.yAxisMap[id]
                              const axis = chartAxes.find(
                                ({ type }) => type === id
                              )
                              if (!yAxis.hide) {
                                return (
                                  <PanAndZoomHelper
                                    key={id}
                                    onDrag={(state) => {
                                      if (state.axis === 'y') {
                                        state.event.preventDefault()
                                      }

                                      if (state.pinching) {
                                        return
                                      }

                                      if (
                                        !state.dragging &&
                                        state.last &&
                                        axis
                                      ) {
                                        api.axesApi
                                          .updateAxis(axis.id!, {
                                            ...axis,
                                            lowerBound: yAxis.domain[0],
                                            upperBound: yAxis.domain[1],
                                          })
                                          .then((respone) => {
                                            dispatch({
                                              type: 'updateYAxis',
                                              axisType: respone.data.type,
                                              minValue: respone.data.lowerBound,
                                              maxValue: respone.data.upperBound,
                                            })
                                          })
                                      }

                                      if (
                                        state.dragging &&
                                        !state.first &&
                                        state.memo
                                      ) {
                                        const diff = state.memo.scale(
                                          state.delta[1]
                                        )
                                        if (
                                          handleYAxisDrag(axis!, [
                                            state.memo.domain[0] + diff,
                                            state.memo.domain[1] + diff,
                                          ])
                                        ) {
                                          return {
                                            ...state.memo,
                                            domain: [
                                              state.memo.domain[0] + diff,
                                              state.memo.domain[1] + diff,
                                            ],
                                          }
                                        }
                                      }

                                      if (
                                        state.memo === undefined &&
                                        yAxis.domain
                                      ) {
                                        // Return initial domain and scale
                                        return {
                                          domain: yAxis.domain,
                                          scale: scaleLinear()
                                            .domain([0, props.offset.height])
                                            .range([
                                              0,
                                              yAxis.domain[1] - yAxis.domain[0],
                                            ]),
                                        }
                                      }
                                    }}
                                    onWheel={(state) => {
                                      state.event.preventDefault()
                                      if (
                                        !state.wheeling &&
                                        state.last &&
                                        axis
                                      ) {
                                        api.axesApi
                                          .updateAxis(axis.id!, {
                                            ...axis,
                                            lowerBound: yAxis.domain[0],
                                            upperBound: yAxis.domain[1],
                                          })
                                          .then((respone) => {
                                            dispatch({
                                              type: 'updateYAxis',
                                              axisType: respone.data.type,
                                              minValue: respone.data.lowerBound,
                                              maxValue: respone.data.upperBound,
                                            })
                                          })
                                      }

                                      handleYAxisZoom(
                                        axis!,
                                        yAxis.domain,
                                        state.delta[1]
                                      )
                                    }}
                                    onPinch={(state) => {
                                      handleYAxisZoom(
                                        axis!,
                                        yAxis.domain,
                                        state.vdva[0] * -150
                                      )
                                    }}
                                    getRect={(ref) => (
                                      <rect
                                        ref={ref}
                                        x={yAxis.x}
                                        y={yAxis.y}
                                        width={yAxis.width}
                                        height={yAxis.height}
                                        className={css({
                                          fill: 'none',
                                          pointerEvents: 'visible',
                                          touchAction: 'pan-x',
                                        })}
                                      />
                                    )}
                                  />
                                )
                              } else {
                                return null
                              }
                            }),
                          [props.offset.height, props.yAxisMap]
                        )}
                    </>
                  )
                }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      )
    }
  )
)

export { Chart }
