import { useStyletron } from 'baseui'
import { Block } from 'baseui/block'
import { Button } from 'baseui/button'
import { ChevronRight } from 'baseui/icon'
import { Cell, Grid } from 'baseui/layout-grid'
import { Machine } from 'client/dist/models'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StepWizardChildProps } from 'react-step-wizard'

import { useApi } from '../../ApiProvider'
import { connectorTypeDisplayName } from '../../utils'

const Step1: React.FC<
  Partial<StepWizardChildProps> & {
    selection: number | undefined
    onChange: (selection: number | undefined) => void
  }
> = ({ nextStep, onChange, selection }) => {
  const [css, theme] = useStyletron()
  const [machines, setMachines] = useState<Machine[]>([])
  const [t] = useTranslation()
  const [api] = useApi()

  useEffect(() => {
    api.machinesApi.listMachines().then((result) => {
      setMachines(result.data)
    })
  }, [api.machinesApi])

  return (
    <Block flex={'auto'} display={'flex'} flexDirection={'column'}>
      <Block
        flex={'auto'}
        paddingTop={'scale600'}
        paddingBottom={'scale600'}
        overrides={{
          Block: {
            style: ({ $theme }) => ({
              borderBottomStyle: $theme.borders.border400.borderStyle,
              borderBottomWidth: $theme.borders.border400.borderWidth,
              borderBottomColor: $theme.colors.borderOpaque,
            }),
          },
        }}
      >
        <Grid gridGaps={[16, 36, 36, 36]}>
          {machines
            .slice()
            .sort((a, b) => a.id! - b.id!)
            .map((machine) => (
              <Cell key={machine.id} span={[1, 2, 3]}>
                <div
                  className={css({
                    ...theme.typography.LabelLarge,
                    color: theme.colors.primary,
                    height: theme.sizing.scale2400,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    backgroundColor: theme.colors.mono300,
                    borderLeftStyle: 'solid',
                    borderLeftColor:
                      machine.id === selection
                        ? 'rgb(120,186,60)'
                        : 'transparent',
                    borderLeftWidth: theme.sizing.scale200,
                    paddingRight: theme.sizing.scale200,
                  })}
                  onClick={() => {
                    if (selection === machine.id) {
                      onChange(undefined)
                    } else {
                      onChange(machine.id)
                    }
                  }}
                >
                  <span>{machine.name}</span>
                  <span
                    className={css({
                      paddingLeft: theme.sizing.scale200,
                      paddingTop: theme.sizing.scale400,
                      ...theme.typography.LabelSmall,
                    })}
                  >
                    {connectorTypeDisplayName(machine.connector)}
                  </span>
                </div>
              </Cell>
            ))}
        </Grid>
      </Block>
      <Block
        display={'flex'}
        justifyContent={'flex-end'}
        padding={'scale600'}
        backgroundColor={'backgroundSecondary'}
      >
        <Button
          onClick={nextStep}
          endEnhancer={() => <ChevronRight size={24} />}
          disabled={selection === undefined}
          overrides={{
            BaseButton: {
              style: {
                paddingRight: '8px',
              },
            },
            EndEnhancer: {
              style: {
                marginLeft: 0,
              },
            },
          }}
        >
          {t('export.next')}
        </Button>
      </Block>
    </Block>
  )
}

export { Step1 }
