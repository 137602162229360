import useEventListener from '@use-it/event-listener'
import { useStyletron } from 'baseui'
import { Block } from 'baseui/block'
import { Button } from 'baseui/button'
import { ChevronLeft } from 'baseui/icon'
import { ProgressBar } from 'baseui/progress-bar'
import { Select, Value } from 'baseui/select'
import { toaster } from 'baseui/toast'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'react-oidc-context'
import { Prompt } from 'react-router-dom'
import { StepWizardChildProps } from 'react-step-wizard'

import { useApi } from '../../ApiProvider'
import { StompState, useStomp } from '../../StompProvider'

const Step4: React.FC<
  Partial<StepWizardChildProps> & {
    machineId: number
    dateRange: [Date, Date] | undefined
    parameters: number[]
    onFinished: () => void
  }
> = ({ previousStep, onFinished, machineId, dateRange, parameters }) => {
  const [css, theme] = useStyletron()
  const [format, setFormat] = useState<Value>([{ id: 'CSV' }])
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState<number>()
  const exportId = useRef<string>()
  const [supportedFormats, setSupportedFormats] = useState<string[]>([])
  const [t] = useTranslation()
  const [stomp] = useStomp()
  const [api] = useApi()
  const auth = useAuth()

  useEventListener('beforeunload', (e) => {
    if (loading) {
      e.preventDefault()
      e.returnValue = false
    } else {
      delete e['returnValue']
    }
  })

  useEventListener('unload', (e) => {
    if (loading && exportId.current) {
      api.exportsApi.cancelExport(exportId.current)
    }
  })

  useEventListener('popstate', (e) => {
    if (loading && exportId.current) {
      api.exportsApi.cancelExport(exportId.current)
    }
  })

  useEffect(() => {
    if (stomp.state === StompState.OPEN) {
      const subscription1 = stomp.client.subscribe(
        '/user/queue/exports',
        (message) => {
          const body = JSON.parse(message.body)
          if (body.completed === true) {
            setLoading(false)
            onFinished()
            exportId.current = undefined
            localStorage.removeItem('exportId')
            toaster.positive(<>{t('export.export_success')}</>, {})
            const link = document.createElement('a')
            link.setAttribute('download', '')
            if (auth.user !== undefined) {
              link.href = `${window.env.APP_BASE_PATH}/api/v1/export/${body.id}?access_token=${auth.user?.access_token}`
            } else {
              link.href = `${window.env.APP_BASE_PATH}/api/v1/export/${body.id}`
            }
            link.click()
            if (link.parentNode !== null) {
              link.parentNode.removeChild(link)
            }
            setProgress(undefined)
          } else {
            if (body.canceled === true) {
              setLoading(false)
              exportId.current = undefined
              localStorage.removeItem('exportId')
              toaster.warning(<>{t('export.export_canceled')}</>, {})
            } else {
              setProgress(Math.round(body.progress))
              exportId.current = body.id
              localStorage.setItem('exportId', body.id)
            }
          }
        }
      )
      const subscription2 = stomp.client.subscribe(
        '/user/queue/errors',
        (message) => {
          const body = JSON.parse(message.body)
          setLoading(false)
          exportId.current = undefined
          localStorage.removeItem('exportId')
          toaster.negative(<>{body.message}</>, {
            autoHideDuration: 0,
          })
        }
      )
      return () => {
        subscription1.unsubscribe()
        subscription2.unsubscribe()
      }
    }
  }, [auth.user, onFinished, stomp.client, stomp.state, t])

  useEffect(() => {
    api.exportsApi.getSupportedFormats().then((response) => {
      setSupportedFormats(response.data)
    })
  }, [api.exportsApi])

  return (
    <Block flex={'auto'} display={'flex'} flexDirection={'column'}>
      <Prompt when={loading} message={t('export.cancel_prompt')} />
      <Block
        flex={'auto'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        overrides={{
          Block: {
            style: ({ $theme }) => ({
              borderBottomStyle: $theme.borders.border400.borderStyle,
              borderBottomWidth: $theme.borders.border400.borderWidth,
              borderBottomColor: $theme.colors.borderOpaque,
            }),
          },
        }}
      >
        <Block
          flex={'auto'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          flexDirection={loading ? 'column' : 'row'}
        >
          {!loading && (
            <>
              <Select
                options={supportedFormats.map((format) => ({
                  id: format,
                  label: format,
                }))}
                onChange={({ value }) => {
                  setFormat(value)
                }}
                value={format}
                overrides={{
                  Root: {
                    style: ({ $theme }) => ({
                      width: '250px',
                      paddingRight: $theme.sizing.scale600,
                    }),
                  },
                }}
                clearable={false}
                deleteRemoves={false}
                backspaceRemoves={false}
              />
              <Button
                size={'large'}
                onClick={() => {
                  stomp.client.publish({
                    destination: '/app/export',
                    body: JSON.stringify({
                      machineId,
                      start: dateRange?.[0].toISOString(),
                      end: dateRange?.[1].toISOString(),
                      parameters,
                      format: format[0]?.id,
                    }),
                  })
                  setLoading(true)
                }}
                disabled={!format}
              >
                {t('export.start')}
              </Button>
            </>
          )}
          {loading && (
            <>
              <ProgressBar
                value={progress ?? 0}
                overrides={{
                  Root: {
                    style: {
                      width: '50%',
                    },
                  },
                }}
              />
              <div
                className={css({
                  ...theme.typography.ParagraphMedium,
                  color: theme.colors.primary,
                  paddingBottom: theme.sizing.scale600,
                })}
              >
                {t('export.running', {
                  progress: progress ?? 0,
                })}
              </div>
              <Button
                size={'default'}
                onClick={() => {
                  if (exportId.current) {
                    api.exportsApi.cancelExport(exportId.current)
                  }
                }}
              >
                {t('cancel')}
              </Button>
            </>
          )}
        </Block>
      </Block>
      <Block
        display={'flex'}
        justifyContent={'flex-start'}
        padding={'scale600'}
        backgroundColor={'backgroundSecondary'}
      >
        <Button
          onClick={previousStep}
          startEnhancer={() => <ChevronLeft size={24} />}
          disabled={loading}
          overrides={{
            BaseButton: {
              style: {
                paddingLeft: '8px',
              },
            },
            StartEnhancer: {
              style: {
                marginRight: 0,
              },
            },
          }}
        >
          {t('export.prev')}
        </Button>
      </Block>
    </Block>
  )
}

export { Step4 }
