import { Client, StompConfig } from '@stomp/stompjs'
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

export enum StompState {
  CONNECTING,
  OPEN,
  CLOSED,
}

export const StompContext = createContext<{
  client: Client
  state: StompState
}>({
  client: new Client(),
  state: StompState.CLOSED,
})

export const StompProvider: React.FC<{ configuration: StompConfig }> = ({
  configuration,
  children,
}) => {
  const [state, setState] = useState(StompState.CLOSED)
  const client = useMemo(() => {
    const client = new Client({
      ...configuration,
      beforeConnect: () => {
        setState(StompState.CONNECTING)
      },
      onConnect: () => {
        setState(StompState.OPEN)
      },
      onDisconnect: () => {
        setState(StompState.CLOSED)
      },
      onWebSocketClose: () => {
        setState(StompState.OPEN)
      },
    })
    client.activate()
    return client
  }, [configuration])

  useEffect(() => {
    return () => {
      client.deactivate()
    }
  }, [client])

  return (
    <StompContext.Provider
      value={{
        client,
        state,
      }}
    >
      {children}
    </StompContext.Provider>
  )
}

export const useStomp = (): [{ client: Client; state: StompState }] => {
  const stomp = useContext(StompContext)
  return [stomp]
}
