import {
  createThemedStyled,
  createThemedUseStyletron,
  createThemedWithStyle,
} from 'baseui'
import { Theme } from 'baseui/theme'

export type CustomThemeT = Theme & {
  avatarBackgroundColor: string
  avatarInitialsColor: string
  navItemColor: string
}

export const themedStyled = createThemedStyled<CustomThemeT>()
export const themedWithStyle = createThemedWithStyle<CustomThemeT>()
export const themedUseStyletron = createThemedUseStyletron<CustomThemeT>()
