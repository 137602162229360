"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * datAIndustry
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectorStatusEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var ConnectorStatusEnum;
(function (ConnectorStatusEnum) {
    ConnectorStatusEnum["STARTED"] = "STARTED";
    ConnectorStatusEnum["STOPPED"] = "STOPPED";
    ConnectorStatusEnum["ERROR"] = "ERROR";
})(ConnectorStatusEnum = exports.ConnectorStatusEnum || (exports.ConnectorStatusEnum = {}));
