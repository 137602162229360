import './App.css'

import {
  faCog,
  faFolderOpen,
  faQuestionCircle,
  faSave,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import { BaseProvider, styled } from 'baseui'
import { Block } from 'baseui/block'
import { Checkbox, STYLE_TYPE } from 'baseui/checkbox'
import {
  ALIGN,
  HeaderNavigation as Navigation,
  StyledNavigationItem as NavigationItem,
  StyledNavigationList as NavigationList,
} from 'baseui/header-navigation'
import { StyledLink } from 'baseui/link'
import { Select, Value } from 'baseui/select'
import { StyledSpinnerNext as Spinner } from 'baseui/spinner'
import { PLACEMENT, toaster, ToasterContainer } from 'baseui/toast'
import {
  Axis,
  AxisType,
  Channel,
  ChannelTemplate,
  Notification,
  NotificationTypeEnum as NotificationType,
  Parameter,
  Statistics,
  TimeInstant,
} from 'client/dist/models'
import * as QueryParser from 'query-string'
import React, {
  createContext,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react'
import { Helmet } from 'react-helmet'
import { Translation, useTranslation } from 'react-i18next'
import { hasAuthParams, useAuth } from 'react-oidc-context'
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'
import { Client as Styletron } from 'styletron-engine-atomic'
import { Provider as StyletronProvider } from 'styletron-react'
import { throttle } from 'throttle-debounce'

import { useApi } from './ApiProvider'
import { ReactComponent as Logo } from './assets/logo.svg'
import { ChannelList } from './components/ChannelList'
import { Chart, ChartRef } from './components/Chart'
import { Export } from './components/Export'
import { Header } from './components/Header'
import { UserMenu } from './components/navbar/UserMenu'
import { Search } from './components/Search'
import { Settings } from './components/Settings'
import { LoadDialog } from './components/templates/LoadDialog'
import { SaveDialog } from './components/templates/SaveDialog'
import { StompState, useStomp } from './StompProvider'
import { CustomThemeT, DarkTheme, LightTheme } from './themes'

const engine = new Styletron({ prefix: '_' })

const THEME = {
  light: 'light',
  dark: 'dark',
}

const LANGUAGES = ['de', 'en']

const StyledNavLink = styled(NavLink, ({ $theme }) => ({
  ...$theme.typography.LabelMedium,
  color: $theme.colors.linkText,
  paddingTop: '26px',
  paddingBottom: '26px',
  paddingLeft: $theme.sizing.scale800,
  paddingRight: $theme.sizing.scale800,
  textDecoration: 'none',
  ':visited': {
    color: $theme.colors.linkVisited,
  },
  ':hover': {
    color: $theme.colors.linkHover,
  },
  ':active': {
    color: $theme.colors.linkActive,
  },
}))

const LanguageChooser: React.FC = () => {
  const [t, i18n] = useTranslation()
  const [languages, setLanguages] = useState<string[]>(LANGUAGES)
  const [language, setLanguage] = useState<Value>([{ id: i18n.language }])

  useEffect(() => {
    axios
      .get(`${window.env.APP_BASE_PATH}/actuator/info`)
      .then((result) => {
        setLanguages(result.data.i18n?.locales ?? LANGUAGES)
      })
      .catch(() => {
        setLanguages(LANGUAGES)
      })
  }, [])

  return (
    <Select
      clearable={false}
      options={languages.map((language) => {
        return { label: t(`language.${language}`), id: language }
      })}
      value={language}
      searchable={false}
      deleteRemoves={false}
      backspaceRemoves={false}
      onChange={(params) => {
        setLanguage(params.value)
        i18n.changeLanguage(params.value[0].id as string)
      }}
      size={'compact'}
      overrides={{
        Root: {
          style: {
            width: '150px',
          },
        },
      }}
    />
  )
}

export type ParameterT = Parameter & { inUse: boolean }

export type CursorValuesT = {
  left: {
    active: boolean
    values: TimeInstant | undefined
  }
  right: TimeInstant | undefined
  statistics: Statistics | undefined
}

type ActionT =
  | { type: 'addChannel'; channel: Channel }
  | { type: 'addAxis'; axis: Axis }
  | { type: 'updateChannel'; channel: Channel }
  | { type: 'deleteChannel'; id?: number }
  | { type: 'deleteAllChannels' }
  | { type: 'setAutoRefresh'; autoRefresh: boolean }
  | { type: 'setShowOrders'; showOrders: boolean }
  | { type: 'setShowSpools'; showSpools: boolean }
  | { type: 'setActiveMachine'; activeMachine: number }
  | {
      type: 'updateYAxis'
      axisType: AxisType
      minValue: number
      maxValue: number
    }
  | {
      type: 'setParametersAndChannels'
      parameters: ParameterT[]
      channels: Channel[]
      axes: Axis[]
    }
  | { type: 'setTemplates'; templates: ChannelTemplate[] }
  | { type: 'setCursorValues'; values: CursorValuesT }

type StateT = {
  channels: Channel[]
  activeChannels: Channel[]
  parameters: ParameterT[]
  axes: Axis[]
  templates: ChannelTemplate[]
  autoRefresh: boolean
  showOrders: boolean
  showSpools: boolean
  activeMachine: number | undefined
  cursorValues: CursorValuesT
}

export const DispatchContext = createContext<React.Dispatch<ActionT>>(() => {})
export const CursorValuesContext = createContext<CursorValuesT>({
  left: {
    active: false,
    values: undefined,
  },
  right: undefined,
  statistics: undefined,
})

const updateUsedParameters = (state: StateT, channels: Channel[]) => {
  const usedParameterIds = channels.map(({ parameterId }) => parameterId)
  return state.parameters.map((parameter) => {
    return {
      ...parameter,
      inUse:
        parameter.id !== undefined && usedParameterIds.includes(parameter.id),
    }
  })
}

const reducer = (state: StateT, action: ActionT): StateT => {
  switch (action.type) {
    case 'deleteChannel': {
      const channels = state.channels.filter(
        (channel) => channel.id !== action.id
      )
      const activeChannels = state.activeChannels.filter(
        (channel) => channel.id !== action.id
      )
      return {
        channels,
        activeChannels,
        parameters: updateUsedParameters(state, channels),
        axes: state.axes,
        templates: state.templates,
        autoRefresh: state.autoRefresh,
        showOrders: state.showOrders,
        showSpools: state.showSpools,
        activeMachine: state.activeMachine,
        cursorValues: state.cursorValues,
      }
    }
    case 'deleteAllChannels':
      return {
        channels: [],
        activeChannels: [],
        parameters: updateUsedParameters(state, []),
        axes: state.axes,
        templates: state.templates,
        autoRefresh: state.autoRefresh,
        showOrders: state.showOrders,
        showSpools: state.showSpools,
        activeMachine: state.activeMachine,
        cursorValues: {
          left: { active: false, values: undefined },
          right: undefined,
          statistics: undefined,
        },
      }
    case 'addChannel': {
      const channels = [...state.channels, action.channel]
      const activeChannels = [...state.activeChannels]
      if (action.channel.active) {
        activeChannels.push(action.channel)
      }
      return {
        channels,
        activeChannels,
        parameters: updateUsedParameters(state, channels),
        axes: state.axes,
        templates: state.templates,
        autoRefresh: state.autoRefresh,
        showOrders: state.showOrders,
        showSpools: state.showSpools,
        activeMachine: state.activeMachine,
        cursorValues: state.cursorValues,
      }
    }
    case 'updateChannel': {
      const channels = state.channels.map((channel) => {
        if (channel.id === action.channel.id) {
          return action.channel
        } else {
          return channel
        }
      })
      const prevChannelState = state.channels.find(
        (channel) => channel.id === action.channel.id
      )
      let activeChannels = state.activeChannels.map((channel) => {
        if (channel.id === action.channel.id) {
          return action.channel
        } else {
          return channel
        }
      })
      if (
        prevChannelState !== undefined &&
        prevChannelState.active !== action.channel.active
      ) {
        if (!action.channel.active) {
          // Remove from active channels
          activeChannels = state.activeChannels.filter(
            (channel) => channel.id !== action.channel.id
          )
        } else {
          // Add to active channels
          activeChannels = [...state.activeChannels, action.channel]
        }
      }

      if (
        prevChannelState !== undefined &&
        prevChannelState.parameterId !== action.channel.id
      ) {
        return {
          channels,
          activeChannels,
          parameters: updateUsedParameters(state, channels),
          axes: state.axes,
          templates: state.templates,
          autoRefresh: state.autoRefresh,
          showOrders: state.showOrders,
          showSpools: state.showSpools,
          activeMachine: state.activeMachine,
          cursorValues: state.cursorValues,
        }
      } else {
        return {
          channels,
          activeChannels,
          parameters: state.parameters,
          axes: state.axes,
          templates: state.templates,
          autoRefresh: state.autoRefresh,
          showOrders: state.showOrders,
          showSpools: state.showSpools,
          activeMachine: state.activeMachine,
          cursorValues: state.cursorValues,
        }
      }
    }
    case 'addAxis': {
      return {
        channels: state.channels,
        activeChannels: state.activeChannels,
        parameters: state.parameters,
        axes: [...state.axes, action.axis],
        templates: state.templates,
        autoRefresh: state.autoRefresh,
        showOrders: state.showOrders,
        showSpools: state.showSpools,
        activeMachine: state.activeMachine,
        cursorValues: state.cursorValues,
      }
    }
    case 'setParametersAndChannels':
      const activeChannels = action.channels.filter(({ active }) => active)
      return {
        channels: action.channels,
        activeChannels,
        parameters: action.parameters,
        axes: action.axes,
        templates: state.templates,
        autoRefresh: state.autoRefresh,
        showOrders: state.showOrders,
        showSpools: state.showSpools,
        activeMachine: state.activeMachine,
        cursorValues: state.cursorValues,
      }
    case 'setTemplates':
      return {
        channels: state.channels,
        activeChannels: state.activeChannels,
        parameters: state.parameters,
        axes: state.axes,
        templates: action.templates,
        autoRefresh: state.autoRefresh,
        showOrders: state.showOrders,
        showSpools: state.showSpools,
        activeMachine: state.activeMachine,
        cursorValues: state.cursorValues,
      }
    case 'updateYAxis':
      return {
        channels: state.channels,
        activeChannels: state.activeChannels,
        parameters: state.parameters,
        axes: state.axes.map((axis) => {
          if (axis.type === action.axisType) {
            return {
              ...axis,
              lowerBound: action.minValue,
              upperBound: action.maxValue,
            }
          } else {
            return axis
          }
        }),
        templates: state.templates,
        autoRefresh: state.autoRefresh,
        showOrders: state.showOrders,
        showSpools: state.showSpools,
        activeMachine: state.activeMachine,
        cursorValues: state.cursorValues,
      }
    case 'setAutoRefresh':
      return {
        channels: state.channels,
        activeChannels: state.activeChannels,
        parameters: state.parameters,
        axes: state.axes,
        templates: state.templates,
        autoRefresh: action.autoRefresh,
        showOrders: state.showOrders,
        showSpools: state.showSpools,
        activeMachine: state.activeMachine,
        cursorValues: state.cursorValues,
      }
    case 'setShowOrders':
      return {
        channels: state.channels,
        activeChannels: state.activeChannels,
        parameters: state.parameters,
        axes: state.axes,
        templates: state.templates,
        autoRefresh: state.autoRefresh,
        showOrders: action.showOrders,
        showSpools: state.showSpools,
        activeMachine: state.activeMachine,
        cursorValues: state.cursorValues,
      }
    case 'setShowSpools':
      return {
        channels: state.channels,
        activeChannels: state.activeChannels,
        parameters: state.parameters,
        axes: state.axes,
        templates: state.templates,
        autoRefresh: state.autoRefresh,
        showOrders: state.showOrders,
        showSpools: action.showSpools,
        activeMachine: state.activeMachine,
        cursorValues: state.cursorValues,
      }
    case 'setActiveMachine':
      return {
        channels: [],
        activeChannels: [],
        parameters: [],
        axes: [],
        templates: state.templates,
        autoRefresh: state.autoRefresh,
        showOrders: state.showOrders,
        showSpools: state.showSpools,
        activeMachine: action.activeMachine,
        cursorValues: state.cursorValues,
      }
    case 'setCursorValues':
      return {
        channels: state.channels,
        activeChannels: state.activeChannels,
        parameters: state.parameters,
        axes: state.axes,
        templates: state.templates,
        autoRefresh: state.autoRefresh,
        showOrders: state.showOrders,
        showSpools: state.showSpools,
        activeMachine: state.activeMachine,
        cursorValues: action.values,
      }
    default:
      throw new Error()
  }
}

const App: React.FC = () => {
  const [state, dispatch] = useReducer(
    reducer,
    {
      channels: [],
      activeChannels: [],
      parameters: [],
      axes: [],
      templates: [],
      autoRefresh: false,
      showOrders: false,
      showSpools: false,
      activeMachine: undefined,
      cursorValues: {
        left: { active: false, values: undefined },
        right: undefined,
        statistics: undefined,
      },
    },
    (initialState) => {
      const machine = localStorage.getItem('machine')
      if (machine !== null) {
        return { ...initialState, activeMachine: parseInt(machine) }
      } else {
        return { ...initialState }
      }
    }
  )
  const [theme, setTheme] = useState(
    localStorage.getItem('theme') || THEME.light
  )
  const [isOpen, setOpen] = useState<{ load: boolean; save: boolean }>({
    load: false,
    save: false,
  })
  const [deltaT, setDeltaT] = useState<number>()
  const [api] = useApi()
  const [stomp] = useStomp()
  const history = useHistory()
  const location = useLocation()
  const routeMatch = useRouteMatch({
    path: '/dashboard/:machineId(\\d+)',
    exact: true,
    strict: true,
    sensitive: true,
  })
  const chartRef = useRef<ChartRef>(null)
  const selectedTheme: CustomThemeT =
    theme === THEME.light ? LightTheme : DarkTheme
  const auth = useAuth()

  const activeParameters = useMemo(
    () =>
      state.parameters.filter(({ id }) => {
        return state.activeChannels
          .map(({ parameterId }) => parameterId)
          .includes(id as number)
      }),
    [state.activeChannels, state.parameters]
  )

  useEffect(() => {
    localStorage.setItem('theme', theme)
    if (routeMatch != null) {
      const machineId = parseInt((routeMatch.params as any).machineId)
      if (state.activeMachine !== machineId) {
        localStorage.setItem('machine', machineId.toString())
        dispatch({
          type: 'setActiveMachine',
          activeMachine: machineId,
        })
      }
    }
  }, [routeMatch, state.activeMachine, theme])

  useEffect(() => {
    if (routeMatch != null) {
      const machineId = parseInt((routeMatch.params as any).machineId)
      if (state.activeMachine !== machineId) {
        localStorage.setItem('machine', machineId.toString())
        dispatch({
          type: 'setActiveMachine',
          activeMachine: machineId,
        })
      }
    }
  }, [routeMatch, state.activeMachine])

  useEffect(() => {
    if (stomp.state === StompState.OPEN) {
      const subscription = stomp.client.subscribe(
        '/topic/notifications',
        (message) => {
          const notification: Notification = JSON.parse(message.body)
          switch (notification.type) {
            case NotificationType.ERROR:
              toaster.negative(<>{notification.message}</>, {
                autoHideDuration: 0,
              })
              break
            case NotificationType.WARNING:
              toaster.warning(<>{notification.message}</>, {})
              break
            case NotificationType.SUCCESS:
              toaster.positive(<>{notification.message}</>, {})
              break
            case NotificationType.INFO:
            default:
              toaster.info(<>{notification.message}</>, {})
              break
          }
        }
      )
      return () => {
        subscription.unsubscribe()
      }
    }
  }, [stomp.client, stomp.state])

  const updateCurrentValues = useCallback(
    throttle(250, (values: { left: Date | undefined; right: Date }) => {
      let t0 = undefined
      if (chartRef.current) {
        const xAxisDomain = chartRef.current.getXAxisDomain()
        t0 = xAxisDomain?.[0]
        if (values.left !== undefined) {
          setDeltaT(values.right.getTime() - values.left.getTime())
        } else if (xAxisDomain !== undefined) {
          setDeltaT(values.right.getTime() - xAxisDomain[0])
        } else {
          setDeltaT(undefined)
        }
      } else {
        setDeltaT(undefined)
      }

      if (state.activeMachine !== undefined && activeParameters.length > 0) {
        const { left, right } = values
        if (left !== undefined) {
          left.setMilliseconds(0)
        }
        right.setMilliseconds(0)
        Promise.all([
          left !== undefined
            ? api.timeSeriesApi.retrieveInstant(
                state.activeMachine,
                left.toISOString(),
                activeParameters.map(({ id }) => id!)
              )
            : undefined,
          api.timeSeriesApi.retrieveInstant(
            state.activeMachine,
            right.toISOString(),
            activeParameters.map(({ id }) => id!)
          ),
          api.statisticsApi.retrieveStatistics(
            state.activeMachine,
            left?.toISOString() ||
              (t0 ? new Date(t0).toISOString() : right.toISOString()),
            right.toISOString(),
            activeParameters.map(({ id }) => id!)
          ),
        ])
          .then(([response1, response2, statistics]) => {
            dispatch({
              type: 'setCursorValues',
              values: {
                left: {
                  active: values.left !== undefined,
                  values: response1 !== undefined ? response1.data : undefined,
                },
                right: response2.data,
                statistics: statistics.data,
              },
            })
          })
          .catch(() => {
            dispatch({
              type: 'setCursorValues',
              values: {
                left: {
                  active: values.left !== undefined,
                  values: undefined,
                },
                right: undefined,
                statistics: undefined,
              },
            })
          })
      }
    }),
    [
      api.timeSeriesApi,
      state.autoRefresh,
      activeParameters,
      state.activeMachine,
    ]
  )

  const query = QueryParser.parse(location.search, {
    parseNumbers: true,
  })

  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading
    ) {
      auth.signinRedirect()
    }
  }, [
    auth.isAuthenticated,
    auth.activeNavigator,
    auth.isLoading,
    auth.signinRedirect,
    auth,
  ])

  if (auth.activeNavigator) {
    return <div>Redirecting to Keycloak ...</div>
  }

  if (!auth.isAuthenticated) {
    return null
  }

  return (
    <StyletronProvider value={engine}>
      <BaseProvider
        theme={theme === THEME.light ? LightTheme : DarkTheme}
        overrides={{
          AppContainer: {
            style: {
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            },
          },
        }}
      >
        <Helmet>
          <style>
            {'body { background-color: ' +
              selectedTheme.colors.backgroundPrimary +
              '; }'}
          </style>
        </Helmet>
        <Suspense
          fallback={
            <div
              style={{
                display: 'flex',
                flex: 'auto',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Spinner />
            </div>
          }
        >
          <Translation>
            {(t) => (
              <Navigation
                overrides={{
                  Root: {
                    style: ({ $theme }) => ({
                      backgroundColor: $theme.colors.backgroundSecondary,
                      paddingTop: $theme.sizing.scale600,
                      paddingRight: $theme.sizing.scale600,
                      paddingBottom: $theme.sizing.scale600,
                      paddingLeft: $theme.sizing.scale600,
                      borderBottomColor: $theme.colors.borderOpaque,
                      flex: '0 0 auto',
                    }),
                  },
                }}
              >
                <NavigationList $align={ALIGN.left}>
                  <div
                    style={{
                      height: '40px',
                      width: '158px',
                    }}
                  >
                    <Logo />
                  </div>
                  <NavigationItem
                    style={{
                      paddingLeft: '24px',
                    }}
                  >
                    <StyledNavLink
                      to={'/dashboard'}
                      activeStyle={{
                        backgroundColor: selectedTheme.navItemColor,
                      }}
                    >
                      {t('navigation.dashboard')}
                    </StyledNavLink>
                  </NavigationItem>
                  <NavigationItem
                    style={{
                      paddingLeft: 0,
                    }}
                  >
                    <StyledNavLink
                      to={'/export'}
                      activeStyle={{
                        backgroundColor: selectedTheme.navItemColor,
                      }}
                    >
                      {t('navigation.export')}
                    </StyledNavLink>
                  </NavigationItem>
                </NavigationList>
                <NavigationList $align={ALIGN.center} />
                <NavigationList $align={ALIGN.right}>
                  <Switch>
                    <Route path={'/dashboard'}>
                      <NavigationItem
                        style={{
                          paddingLeft: 0,
                        }}
                      >
                        <StyledNavLink
                          style={{
                            paddingRight: '8px',
                          }}
                          to={''}
                          onClick={(e) => {
                            e.preventDefault()
                            setOpen({ load: true, save: false })
                          }}
                        >
                          <FontAwesomeIcon icon={faFolderOpen} size={'lg'} />
                        </StyledNavLink>
                      </NavigationItem>
                      <NavigationItem
                        style={{
                          paddingLeft: 0,
                        }}
                      >
                        <StyledNavLink
                          style={{
                            paddingRight: '8px',
                          }}
                          to={''}
                          onClick={(e) => {
                            e.preventDefault()
                            setOpen({ load: false, save: true })
                          }}
                        >
                          <FontAwesomeIcon icon={faSave} size={'lg'} />
                        </StyledNavLink>
                      </NavigationItem>
                    </Route>
                  </Switch>
                  <NavigationItem
                    style={{
                      paddingLeft: 0,
                    }}
                  >
                    <StyledNavLink
                      style={{
                        paddingRight: '8px',
                      }}
                      to={'/settings'}
                    >
                      <FontAwesomeIcon icon={faCog} size={'lg'} />
                    </StyledNavLink>
                  </NavigationItem>
                  <NavigationItem>
                    <Route path={'/dashboard'}>
                      <StyledLink
                        href={`${window.env.APP_BASE_PATH}/docs/dashboard.html`}
                        target={'docs'}
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} size={'lg'} />
                      </StyledLink>
                    </Route>
                    <Route path={'/search'}>
                      <StyledLink
                        href={`${window.env.APP_BASE_PATH}/docs/orders.html`}
                        target={'docs'}
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} size={'lg'} />
                      </StyledLink>
                    </Route>
                    <Route path={'/export'}>
                      <StyledLink
                        href={`${window.env.APP_BASE_PATH}/docs/export.html`}
                        target={'docs'}
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} size={'lg'} />
                      </StyledLink>
                    </Route>
                    <Route path={'/settings/connnectors'} exact>
                      <StyledLink
                        href={`${window.env.APP_BASE_PATH}/docs/settings.html#kopfstation`}
                        target={'docs'}
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} size={'lg'} />
                      </StyledLink>
                    </Route>
                    <Route path={'/settings/machines'} exact>
                      <StyledLink
                        href={`${window.env.APP_BASE_PATH}/docs/settings.html#anlagen`}
                        target={'docs'}
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} size={'lg'} />
                      </StyledLink>
                    </Route>
                    <Route path={'/settings/machines/(\\d+)'} exact>
                      <StyledLink
                        href={`${window.env.APP_BASE_PATH}/docs/settings.html#parameter`}
                        target={'docs'}
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} size={'lg'} />
                      </StyledLink>
                    </Route>
                    <Route path={'/settings/templates'}>
                      <StyledLink
                        href={`${window.env.APP_BASE_PATH}/docs/settings.html#vorlagen`}
                        target={'docs'}
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} size={'lg'} />
                      </StyledLink>
                    </Route>
                  </NavigationItem>
                  <NavigationItem>
                    <Checkbox
                      checked={theme === THEME.dark}
                      onChange={(e) => {
                        setTheme(
                          e.currentTarget.checked ? THEME.dark : THEME.light
                        )
                      }}
                      checkmarkType={STYLE_TYPE.toggle_round}
                      overrides={{
                        Label: () => null,
                        ToggleTrack: {
                          style: ({ $checked }) => {
                            return {
                              height: '20px',
                              borderTopLeftRadius: '10px',
                              borderTopRightRadius: '10px',
                              borderBottomRightRadius: '10px',
                              borderBottomLeftRadius: '10px',
                              '::after': {
                                content: $checked ? '""' : '"🌞"',
                                display: $checked ? 'none' : 'inline',
                                height: '20px',
                                width: '20px',
                                fontSize: '14px',
                                lineHeight: '14px',
                                marginTop: '7px',
                              },
                              '::before': {
                                content: $checked ? '"🌜"' : '""',
                                display: $checked ? 'inline' : 'none',
                                height: '20px',
                                width: '20px',
                                fontSize: '14px',
                                lineHeight: '14px',
                                marginTop: '7px',
                                marginRight: '-20px',
                              },
                            }
                          },
                        },
                      }}
                    />
                  </NavigationItem>
                  <NavigationItem>
                    <LanguageChooser />
                  </NavigationItem>
                  {auth.user !== undefined && (
                    <NavigationItem>
                      <UserMenu />
                    </NavigationItem>
                  )}
                </NavigationList>
              </Navigation>
            )}
          </Translation>
          <Block
            display={'flex'}
            flexDirection={'column'}
            flex={'1 1 auto'}
            overflow={'hidden'}
          >
            <DispatchContext.Provider value={dispatch}>
              <Switch>
                <Redirect exact from={'/'} to={'/dashboard'} />
                <Route path={'/dashboard/:machineId(\\d+)?'}>
                  <Block
                    display={'flex'}
                    flexDirection={'column'}
                    paddingTop={'scale600'}
                    overflow={'auto'}
                  >
                    <Block paddingLeft={'scale600'} paddingRight={'scale600'}>
                      <CursorValuesContext.Provider value={state.cursorValues}>
                        <Header
                          machine={state.activeMachine}
                          autoRefresh={state.autoRefresh}
                          showOrders={state.showOrders}
                          showSpools={state.showSpools}
                          onChange={(machineId) => {
                            if (machineId !== state.activeMachine) {
                              history.push({
                                pathname: `/dashboard/${machineId}`,
                              })
                            }
                          }}
                        />
                      </CursorValuesContext.Provider>
                    </Block>
                    <Block paddingBottom={'scale800'}>
                      {deltaT !== undefined && (
                        <Block
                          width={'100%'}
                          display={['flex', 'flex', 'flex', 'flex']}
                          justifyContent={'center'}
                          color={'contentPrimary'}
                          font={'LabelSmall'}
                          paddingTop={[
                            'scale600',
                            'scale600',
                            'scale600',
                            'scale0',
                          ]}
                        >
                          <span>
                            Δt = {Math.floor(deltaT / 3600000)} h{' '}
                            {Math.floor((deltaT % 3600000) / 60000)} m{' '}
                            {Math.floor(((deltaT % 3600000) % 60000) / 1000)}.
                            {((deltaT % 3600000) % 60000) % 1000} s
                          </span>
                        </Block>
                      )}
                    </Block>
                    <Block flex={'auto'}>
                      <Chart
                        channels={state.channels.filter(({ active }) => active)}
                        parameters={activeParameters}
                        axes={state.axes}
                        machine={state.activeMachine}
                        autoRefresh={state.autoRefresh}
                        showOrders={state.showOrders}
                        showSpools={state.showSpools}
                        domain={
                          query['s'] !== undefined && query['e'] !== undefined
                            ? [
                                (query['s'] as number) * 1000,
                                (query['e'] as number) * 1000,
                              ]
                            : undefined
                        }
                        delay={120000}
                        onCursorChange={updateCurrentValues}
                        ref={chartRef}
                      />
                    </Block>
                    <Block>
                      <CursorValuesContext.Provider value={state.cursorValues}>
                        <ChannelList
                          channels={state.channels}
                          parameters={state.parameters}
                          axes={state.axes}
                          machine={state.activeMachine}
                        />
                      </CursorValuesContext.Provider>
                    </Block>
                  </Block>
                  <LoadDialog
                    templates={state.templates}
                    isOpen={isOpen.load}
                    onClose={(template, channels, axes) => {
                      setOpen({ load: false, save: false })
                      if (template !== undefined) {
                        if (template.machineId !== state.activeMachine) {
                          history.push({
                            pathname: `/dashboard/${template.machineId}`,
                          })
                        } else {
                          if (channels !== undefined && axes !== undefined) {
                            dispatch({
                              type: 'setParametersAndChannels',
                              parameters: state.parameters,
                              channels,
                              axes,
                            })
                          }
                        }
                      }
                    }}
                  />
                  <SaveDialog
                    isOpen={isOpen.save}
                    machine={state.activeMachine}
                    channels={state.channels}
                    axes={state.axes}
                    onClose={(template) => {
                      setOpen({ load: false, save: false })
                      if (template !== undefined) {
                        dispatch({
                          type: 'setTemplates',
                          templates: [...state.templates, template],
                        })
                      }
                    }}
                  />
                </Route>
                <Route path={'/search/:machineId(\\d)?'}>
                  <Search />
                </Route>
                <Route path={'/export'}>
                  <Export />
                </Route>
                <Route path={'/settings'}>
                  <Settings />
                </Route>
              </Switch>
              <ToasterContainer
                placement={PLACEMENT.top}
                autoHideDuration={5000}
                overrides={{
                  Root: {
                    style: {
                      zIndex: 1,
                      marginTop: '4px',
                    },
                  },
                }}
              />
            </DispatchContext.Provider>
          </Block>
        </Suspense>
      </BaseProvider>
    </StyletronProvider>
  )
}

export default App
