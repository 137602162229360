import { createDarkTheme } from 'baseui'
import { colors } from 'baseui/tokens'

const DarkTheme = createDarkTheme(
  {
    primaryB: '#353535',
  },
  {
    colors: {
      calendarHeaderBackground: colors.white,
      calendarHeaderForeground: colors.black,
    },
    avatarBackgroundColor: 'rgb(120,186,60)',
    avatarInitialsColor: colors.white,
    navItemColor: 'rgb(120,186,60)',
  }
)
export default DarkTheme
