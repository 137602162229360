import { faCog, faPlay, faStop } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStyletron } from 'baseui'
import { Block } from 'baseui/block'
import { Breadcrumbs } from 'baseui/breadcrumbs'
import { Button, KIND } from 'baseui/button'
import { ButtonGroup } from 'baseui/button-group'
import { ProgressBar } from 'baseui/progress-bar'
import { Connector, ConnectorStatusEnum, PropertyDescriptor } from 'client'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AutoSizer, Column, SortDirectionType, Table } from 'react-virtualized'

import { useApi } from '../../ApiProvider'
import { ConnectorSettings } from './ConnectorSettings'

export const Connectors: React.FC = () => {
  const [css, theme] = useStyletron()
  const [t] = useTranslation()
  const [sort, setSort] = useState<{
    column: string
    direction: SortDirectionType
  }>({
    column: 'name',
    direction: 'ASC',
  })
  const [connectors, setConnectors] = useState<Connector[]>([])
  const [loading, setLoading] = useState(false)
  const [showSettings, setShowSettings] = useState(false)
  const [supportedProperties, setSupportedProperties] = useState<
    PropertyDescriptor[]
  >([])
  const [propertyValues, setPropertyValues] = useState<Record<string, string>>(
    {}
  )
  const [connectorIdentifier, setConnectorIdentifier] = useState<string>()
  const [api] = useApi()

  useEffect(() => {
    setLoading(true)
    api.connectorsApi
      .listConnectors()
      .then((result) => {
        setConnectors(result.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [api.connectorsApi, api.machinesApi])

  const bodyCellClass = useMemo(
    () =>
      css({
        ...theme.typography.ParagraphSmall,
        ...theme.borders.border300,
        borderTop: 'none',
        borderBottom: 'none',
        [theme.direction === 'rtl' ? 'borderRight' : 'borderLeft']: 'none',
        borderColor: 'transparent',
        color: theme.colors.contentPrimary,
        paddingTop: theme.sizing.scale300,
        paddingRight: theme.sizing.scale600,
        paddingBottom: theme.sizing.scale300,
        paddingLeft: theme.sizing.scale600,
        ':last-of-type': {
          [theme.direction === 'rtl' ? 'borderLeft' : 'borderRight']: 'none',
        },
      }),
    [css, theme]
  )

  return (
    <Block flex={'auto'} display={'flex'} flexDirection={'column'}>
      <Block
        paddingTop={'scale600'}
        paddingRight={'scale600'}
        paddingLeft={'scale600'}
      >
        <Breadcrumbs
          overrides={{
            Root: {
              style: ({ $theme }) => ({
                paddingBottom: $theme.sizing.scale600,
              }),
            },
          }}
        >
          <span>{t('settings.settings')}</span>
          <span>{t('settings.adapters')}</span>
        </Breadcrumbs>
      </Block>
      <Block flex={'auto'}>
        <AutoSizer>
          {({ width, height }) => (
            <Table
              height={height}
              width={width}
              headerHeight={44}
              rowHeight={36}
              rowCount={connectors.length}
              rowGetter={({ index }) => {
                return connectors[index]
              }}
              rowClassName={({ index }) => {
                if (index === -1) {
                  return css({
                    backgroundColor: theme.colors.tableHeadBackgroundColor,
                    boxShadow: theme.lighting.shadow400,
                    display: 'flex',
                    alignItems: 'center',
                  })
                } else {
                  return css({
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'inherit',
                  })
                }
              }}
              headerRowRenderer={({ className, columns, style }) => {
                return (
                  <>
                    <div className={className} role='row' style={style}>
                      {columns}
                    </div>
                    {loading && (
                      <ProgressBar
                        infinite
                        overrides={{
                          Bar: {
                            style: {
                              position: 'absolute',
                              width: `${style.width}px`,
                              marginBottom: 0,
                              marginLeft: 0,
                              marginRight: 0,
                              marginTop: 0,
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                            },
                          },
                          BarProgress: {
                            style: {
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                            },
                          },
                        }}
                      />
                    )}
                  </>
                )
              }}
              headerClassName={css({
                ...theme.typography.LabelMedium,
                ...theme.borders.border300,
                borderTop: 'none',
                borderBottom: 'none',
                [theme.direction === 'rtl' ? 'borderRight' : 'borderLeft']:
                  'none',
                color: theme.colors.contentPrimary,
                paddingTop: theme.sizing.scale500,
                paddingRight: theme.sizing.scale600,
                paddingBottom: theme.sizing.scale500,
                paddingLeft: theme.sizing.scale600,
                ':focus': {
                  outline: 'none',
                },
                ':nth-last-of-type(2)': {
                  [theme.direction === 'rtl' ? 'borderLeft' : 'borderRight']:
                    'none',
                },
                ':last-of-type': {
                  [theme.direction === 'rtl' ? 'borderLeft' : 'borderRight']:
                    'none',
                },
              })}
              gridClassName={css({
                ':focus': {
                  outline: 'none',
                },
              })}
              className={css({
                backgroundColor: theme.colors.tableBackground,
                display: 'flex',
                flexDirection: 'column',
              })}
              sortBy={sort.column}
              sortDirection={sort.direction}
              sort={({ sortBy, sortDirection }) => {
                setSort({
                  column: sortBy,
                  direction: sortDirection,
                })
                setConnectors(
                  connectors.slice().sort((a, b) => {
                    const valueA = (a as any)[sortBy]
                    const valueB = (b as any)[sortBy]
                    if (valueA < valueB) {
                      return sortDirection === 'ASC' ? -1 : 1
                    }
                    if (valueA > valueB) {
                      return sortDirection === 'ASC' ? 1 : -1
                    }
                    return 0
                  })
                )
              }}
              noRowsRenderer={() => {
                return (
                  <div
                    className={css({
                      ...theme.typography.ParagraphMedium,
                      color: theme.colors.contentPrimary,
                      textAlign: 'center',
                      paddingTop: theme.sizing.scale800,
                    })}
                  >
                    {t('settings.no_connectors')}
                  </div>
                )
              }}
            >
              <Column
                label={t('settings.name')}
                dataKey={'name'}
                width={250}
                flexGrow={1}
                className={bodyCellClass}
                cellRenderer={({ cellData, rowIndex }) => {
                  return cellData
                }}
              />
              <Column
                label={t('settings.connector_status')}
                dataKey={'status'}
                width={250}
                flexGrow={1}
                className={bodyCellClass}
                cellRenderer={({ cellData, rowIndex }) => {
                  return t(`settings.connector_status_values.${cellData}`)
                }}
              />
              <Column
                disableSort
                dataKey={'action'}
                width={60}
                className={bodyCellClass}
                style={{
                  textAlign: 'center',
                }}
                cellRenderer={({ rowIndex, rowData }) => {
                  return (
                    <ButtonGroup
                      size={'mini'}
                      kind={KIND.tertiary}
                      overrides={{
                        Root: {
                          style: {
                            justifyContent: 'end',
                          },
                        },
                      }}
                    >
                      <Button
                        overrides={{
                          BaseButton: {
                            style: ({ $theme }) => ({
                              ':hover': {
                                backgroundColor: 'transparent',
                                color: $theme.colors.linkHover,
                              },
                              ':focus': {
                                backgroundColor: 'transparent',
                              },
                              ':active': {
                                backgroundColor: 'transparent',
                                color: $theme.colors.linkActive,
                              },
                              ':disabled': {
                                backgroundColor: 'transparent',
                              },
                            }),
                          },
                        }}
                        onClick={() => {
                          if (rowData.status === ConnectorStatusEnum.STARTED) {
                            api.connectorsApi
                              .stop(rowData.identifier)
                              .then(() => {
                                setConnectors((prev) => {
                                  return prev.map(({ identifier }, index) => {
                                    if (identifier === rowData.identifier) {
                                      return {
                                        ...prev[index],
                                        status: ConnectorStatusEnum.STOPPED,
                                      }
                                    } else {
                                      return prev[index]
                                    }
                                  })
                                })
                              })
                          } else if (
                            rowData.status === ConnectorStatusEnum.STOPPED
                          ) {
                            api.connectorsApi
                              .start(rowData.identifier)
                              .then(() => {
                                setConnectors((prev) => {
                                  return prev.map(({ identifier }, index) => {
                                    if (identifier === rowData.identifier) {
                                      return {
                                        ...prev[index],
                                        status: ConnectorStatusEnum.STARTED,
                                      }
                                    } else {
                                      return prev[index]
                                    }
                                  })
                                })
                              })
                          }
                        }}
                      >
                        <FontAwesomeIcon
                          size={'lg'}
                          icon={
                            rowData.status === ConnectorStatusEnum.STOPPED
                              ? faPlay
                              : faStop
                          }
                        />
                      </Button>
                      <Button
                        overrides={{
                          BaseButton: {
                            style: ({ $theme }) => ({
                              ':hover': {
                                backgroundColor: 'transparent',
                                color: $theme.colors.linkHover,
                              },
                              ':focus': {
                                backgroundColor: 'transparent',
                              },
                              ':active': {
                                backgroundColor: 'transparent',
                                color: $theme.colors.linkActive,
                              },
                              ':disabled': {
                                backgroundColor: 'transparent',
                              },
                            }),
                          },
                        }}
                        onClick={() => {
                          setSupportedProperties(
                            connectors[rowIndex].supportedProperties || []
                          )
                          api.connectorsApi
                            .getProperties(connectors[rowIndex].identifier)
                            .then((response) => {
                              setPropertyValues(response.data)
                              setConnectorIdentifier(
                                connectors[rowIndex].identifier
                              )
                              setShowSettings(true)
                            })
                        }}
                      >
                        <FontAwesomeIcon size={'lg'} icon={faCog} />
                      </Button>
                    </ButtonGroup>
                  )
                }}
              />
            </Table>
          )}
        </AutoSizer>
      </Block>
      <ConnectorSettings
        isOpen={showSettings}
        supportedProperties={supportedProperties}
        propertyValues={propertyValues}
        onCancel={() => {
          setShowSettings(false)
        }}
        onSave={(data) => {
          if (connectorIdentifier !== undefined) {
            api.connectorsApi
              .updateProperties(connectorIdentifier, data)
              .then(() => {
                setShowSettings(false)
              })
          }
        }}
      />
    </Block>
  )
}
